/** 主包 */
import React, { Component } from 'react';
import { Layout, Card, RangeSlider, Checkbox, Link, TextField, Icon, Tooltip } from "@shopify/polaris";
import { Form, Button } from "antd";
import MultipleDatePicker from "./multipleDatePicker";
import { QuestionMarkMinor } from "@shopify/polaris-icons";

/** 样式 */
import './delivery-time.less';


class DeliveryTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weekdays: [],
            range_value: props.deliveryTime,
            date_filer: props.dateFiler,
        }
    }

    // 内存泄漏标志
    _isMounted = true;

    UNSAFE_componentWillReceiveProps(nextProps) {
        let labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        let weekdayArr = [];
        nextProps.weekday.map((v, i) => {
            v = parseInt(v);
            weekdayArr[i] = { label: labels[i], checked: ((v === 1) ? true : false) }
            return '';
        });

        if (this._isMounted) {
            this.setState({
                weekdays: weekdayArr,
                range_value: nextProps.deliveryTime,
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    dateUpdateFilter = (clone) => {
        this.props.updateSaveOrDiscardComponent("date-filer", clone);
    }
    /**
     * 处理预投递区间
     * 
     * @param {*} v 
     * @param {*} i 
     */
    handleRangeSliderChange = (v, i) => {
        const mark = Object.assign({}, v);  // 深拷贝 v 值   
        var newMark = []
        for (var i1 in mark) {
            newMark.push(mark[i1]);
        }
        this.props.updateSaveOrDiscardComponent("range", v.join(',').split(','));
    }

    /**
     * 处理工作日勾选改变
     * 
     * @param {*} i 
     * @param {*} checked 
     * @param {*} mark 
     */
    handleWeekdaysChange = (i, checked, mark) => {
        let weekday = this.state.weekdays;
        weekday[i].checked = checked;
        if (this._isMounted) {
            this.setState({ weekday })
        }

        let weekdayArr = [];
        this.state.weekdays.map((v, i) => {
            weekdayArr[i] = (v.checked === true) ? "1" : "0";
            return '';
        });

        this.props.updateSaveOrDiscardComponent("weekday", weekdayArr);
    }




    render() {
        const weekday = this.state.weekdays.map((v, i) => {
            return <Checkbox
                label={v.label}
                checked={v.checked}
                key={i}
                onChange={this.handleWeekdaysChange.bind(this, i)}
            />
        })

            
        const rangeValue = this.state.range_value;
        const min = 0;
        const max = 100;
        const step = 1;
      
        return (
            <div className="et-dash-delivery-time">
                <Layout>
                    <Layout.Section>
                        <Card title={<div className='edt-delivery-tooltip'>Estimated delivery time <Tooltip   preferredPosition="above"  content={<div>For one product with different ETA, the display priority is:
<br/><b>Products >Delivery zones > Dashboard</b><br/>
For example, product A set 1 day in "Products", 2 days in "Delivery zones" for UK, 3 days in "Dashboard", the shop end will show ETA of product A as 1 day.
</div>}>
                    <Icon
                    source={QuestionMarkMinor}
                    />
                    </Tooltip></div>} sectioned>
                            <div className="range">
                                <RangeSlider
                                    output
                                    // label="Money spent is between"
                                    value={rangeValue}
                                    min={min}
                                    max={max}
                                    step={step}
                                    onChange={this.handleRangeSliderChange}
                                />
                            </div>
                            <div className="weekend">
                                <div>Configure working days</div>
                                {weekday}
                            </div>
                            <div className="holidays">
                                <div className="holidays-title">
                                    <div>
                                    Holidays
                                    </div>
                                </div>
                                <div className="App">
                                    <Form
                                        onFinish={(values) => {
                                            console.log("Success:", values);
                                        }}
                                    >
                                        <Form.Item name="mulitiple">
                                            <MultipleDatePicker selectProps={{ style: { width: "300px" } }} dateData={this.state.date_filer} dateUpdateFilter={this.dateUpdateFilter}/>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                            <div className="jump">
                                <p>You can set up different ETA for different countries in <Link url="delivery_zone">Delivery zones</Link>.<br />
                                    You can set up different ETA for different products in <Link url="product">Products</Link>.</p>
                            </div>
                        </Card>
                    </Layout.Section>
                </Layout>
            </div>
        )
    }
}


export default DeliveryTime;