/** 主包 */
import React, { Component } from 'react';
import { Card, Banner, Modal, TextContainer, TextField, Toast, Frame } from "@shopify/polaris";

/** 工具组件包 */
import storageUtils from '../../utils/storageUtils';
import { feedback } from '../../api/index';

/** 样式 */
import './index.less';

import { Rate } from 'antd';
import { CommentOutlined } from '@ant-design/icons';

class Faq extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: {
                token: '',
                domain: ''
            },
            star_v: 5,
            f_email_text: '',
            f_email: '',
            f_show: false,

            is_notify: false,
            notify_content: ''
        }
    }

    // 内存泄漏标志
    _isMounted = true;

    UNSAFE_componentWillMount() {
        // 监测本地是否存在用户核心参数，否则跳转 404
        var userData = storageUtils.get('users');

        if (userData === null) {
            this.props.history.push('/notfound');
            return '';
        }

        let userInfo = this.state.users;
        userInfo.domain = userData.shop_domain;
        userInfo.token = userData.user_token;
        if (this._isMounted) {
            this.setState({ userInfo });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    closeFeedbackChange = () => {
        if (this._isMounted) {
            this.setState({ f_show: false });
        }
    }

    activeFeedbackChange = () => {
        if (this._isMounted) {
            this.setState({ f_show: true });
        }
    }

    handleEmailTextChange = (v) => {
        if (this._isMounted) {
            this.setState({ f_email_text: v });
        }
    }

    handleEmailChange = (v) => {
        if (this._isMounted) {
            this.setState({ f_email: v });
        }
    }

    sendFeedbackEmail = async () => {
        if ((this.state.f_email.length === 0) || (this.state.f_email_text === 0)) {
            this.setState({
                is_notify: true,
                notify_content: 'Incorrect message format'
            });
            return;
        }

        if ((this.state.f_email.indexOf("@") === -1) || (this.state.f_email.indexOf(".") === -1)) {
            this.setState({
                is_notify: true,
                notify_content: 'Incorrect message format'
            });
            return;
        }
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const param = {
            'from_email': this.state.f_email,
            'content': this.state.f_email_text,
            'star_num': 0
        }

        // feedback 结果
        let feedbackRes = await feedback(headers, param);
        if (feedbackRes.code === 200) {
            this.setState({
                is_notify: true,
                notify_content: 'Mail sent successfully.',
                f_show: false
            });
        }
        else if (feedbackRes.status === 6101) {
            this.setState({
                is_notify: true,
                notify_content: feedbackRes.msg
            });
        }
        else {
            this.setState({
                is_notify: true,
                notify_content: 'Failed to send mail.'
            });
        }
    }

    jumpFQAHandle = () => {
        const w = window.open('about:blank');
        w.location.href = "https://etranslate.helpscoutdocs.com/category/55-general"
    }

    /**
     * 弹窗是否活跃
     */
    toggleActive = () => {
        if (this._isMounted) {
            this.setState({ is_notify: !this.state.is_notify });
        }
    }

    /**
        * 星星评价
        * @param {*} v 
        */
    changeStar = (v) => {
        if (v === 0) v = this.state.star_v;
        if (this._isMounted) {
            this.setState({ star_v: v });
        }
        if (v < 5) {
            if (this._isMounted) {
                this.setState({ f_show: true });
            }
        } else {
            window.open("https://apps.shopify.com/estimated-delivery-time-pro?#modal-show=ReviewListingModal", "_blank")
        }
    }

    
    render() {
        const toastMarkup = this.state.is_notify ? (
            <Toast content={this.state.notify_content} onDismiss={this.toggleActive} />
        ) : null;

        let copyDivHtml = 'Copy the tag (<div id="edt-p"></div>)';
        let endformHtml = 'Paste the <div> in the file, below the {% endform %} line.';
        return (
            <div className='et-faq'>
                <div className="et-dash-banner">
                    <Banner
                        status="info"
                        onDismiss={this.deleteBannerChange}
                    >
                        <div className='banner-title'>
                            <span className='icon'><CommentOutlined /></span>
                            <span className='title-text'>Would you mind let us know what you think about this app?</span>
                            <Rate value={this.state.star_v} onChange={this.changeStar} />
                        </div>

                        <p>Each <b>positive review</b> helps us to focus on <b>app development</b> and keep <b>low price</b> for you.</p>
                    </Banner>
                </div>
                <Card sectioned title="I only added a few products, why does it say I exceed the product limit and how does the limit work?">
                    <p style={{ fontSize: "15px" }}><b style={{ fontWeight: "500" }}>Why is ETA display on all my products?</b><br /><br />
                    The operating logic of ETA is to pull the data of all your products in the store once installed, so that it can be easily set up within one click. Thus, no matter how many items you added into “Products” or whether you just want a few of products show ETA, we will count all your products and display ETA on each product page by default.<br /><br />
                    If you have more than 100 products while using FREE plan, the yellow notification bar will show and we will pull your first-created 100 products into our database（The rest will not be able to show ETA）. You can go “Products------Add products” to see what we have in database, which includes all available-to-show-ETA products for then.
                    </p>
                </Card>
                <Card sectioned title="How to use Estimated Delivery Date?">
                    <p style={{ fontSize: "15px" }}>Our apps are built to work in 1 click. So after installing, just click the enable button, the delivery message will show on your store.<br /><br />
                        If you want to set up different estimated delivery dates for certain countries, just head to Delivery zones section, search and add countries which you need to set additional dates.<br /><br />
                        If you want to set up different estimated delivery dates for certain products just head to Product section, search for product which you need to set additional dates.<br /><br />
                        Display priority: Products >Delivery zones > Dashboard  &nbsp;&nbsp;&nbsp;<a href='https://helpcenterapi.sealapps.com/help/app/15' target='_blank'>See more FAQ</a></p>
                </Card>
                <Card sectioned title="Estimated Delivery Date is installed but not showing?">
                    <p style={{ fontSize: "15px" }}>If you have installed our app but can't get it show on your store, there are 4 reasons in this case:</p>
                    <ul style={{ listStyle: "inside", fontSize: "15px" }}>
                        <li>The app setting is not enabled</li>
                        <li>You are using a custom or heavily modified theme/page builder</li>
                        <li>It is conflicting with another app (very rare)</li>
                        <li>The ETA message won't be shown if the product inventory is 0.</li>
                    </ul><br />
                    <p style={{ fontSize: "15px" }}>
                        We suggest you make sure the app is enabled, after saving the settings, please wait 10 seconds before refreshing the page to check if it is displayed. If not, this may be a theme related issue. You can just add a tag to your theme code manually. Following this guide:
                    </p>
                    <ul style={{ listStyle: "inside", fontSize: "15px" }}>
                        <li>{copyDivHtml}</li>
                        <li>Navigate to the theme coding and look for the product page. In this example, the page is called "product-template.liquid" located under "sections". If you can't find "product-template.liquid" file, look for "product-form.liquid" file instead.</li>
                        <li>{endformHtml}</li>
                        <li>Click "Save".</li>
                        <li>Still having trouble? Please get in touch with us and we will fix this for you.</li>
                    </ul>
                </Card>
                <Card sectioned title="On which pages will the delivery message be displayed?">
                    <p style={{ fontSize: "15px" }}>The estimated delivery message will be display on the following page:</p>
                    <ul style={{ paddingLeft: "2px", listStyle: "inside", fontSize: "15px" }}>
                        <li>Product page</li>
                        <li>Cart page</li>
                        <li>Check out page</li>
                        <li>Order status page</li>
                        <li>Order history page</li>
                    </ul>
                </Card>
                <Card sectioned title="More ideas of messages that increase conversion rate">
                    <ul style={{ paddingLeft: "2px", listStyle: "inside", fontSize: "15px" }}>
                        <li>Free Shipping for orders over $XX</li>
                        <li>Order today to get it by May 4th</li>
                        <li>You will receive order between 8 July - 15 July</li>
                        <li>Ships in 24 hours, estimated delivery time: Aug 17 - Aug 24</li>
                        <li>30-day free return policy</li>
                        <li>100% money-back guarantee</li>
                        <li>Sale ends today</li>
                    </ul>
                </Card>

                <Modal
                    // activator={activator}
                    open={this.state.f_show}
                    onClose={this.closeFeedbackChange}
                    title="Send your feedback"
                    primaryAction={{
                        content: 'Send',
                        onAction: this.sendFeedbackEmail,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: this.closeFeedbackChange,
                        },
                    ]}
                >
                    <Modal.Section>
                        <TextContainer>
                            <div>
                                <div>Please tell us more, we will try the best to get better</div>
                                <TextField value={this.state.f_email_text} onChange={this.handleEmailTextChange} multiline={3} placeholder="Edit your message here..." />
                            </div>
                            <div>
                                <div>Your contact email</div>
                                <TextField value={this.state.f_email} onChange={this.handleEmailChange} placeholder="Edit your message here..." />
                            </div>
                        </TextContainer>
                    </Modal.Section>
                </Modal>

                <Frame>{toastMarkup}</Frame>
            </div>
        )
    }
}


export default Faq;