/** 主包 */
import React, { Component } from 'react';
import { Layout, Card, Link, Icon, Select, TextField, Tooltip, Button, Frame, Toast, Modal, TextContainer, Checkbox } from "@shopify/polaris";
import { CopyToClipboard } from 'react-copy-to-clipboard';

/** 工具组件包 */
import storageUtils from '../../../../utils/storageUtils';
import { editCartText } from '../../../../api/index';
import { editCollectionText } from '../../../../api/index';
import { dateConversion } from '../../../../utils/fun';
import {
  InviteMinor
} from '@shopify/polaris-icons';
/** 样式 */
import { QuestionMarkMinor } from "@shopify/polaris-icons";

import './placement.less';
import { ifElse } from 'ramda';

class Placement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: {
                token: '',
                domain: ''
            },
            identity_country: storageUtils.get('identity_country') | {},
            mode: props.mode,
            is_show_cart: props.isShowCart,
            is_show_collection: props.isShowCollection,
            is_active: false,
            is_active_l: false,
            is_free_plan_active: false,
            text_v: props.cartL,
            text_c: props.collectionL,
            is_notify: false,
            notify_content: '',
            dateLang: props.dateLang || 'en',
            calendar: props.calendar || {},
            cartDateT: props.cartDateT,
            plans: props.plans || 0
        }
    }

    // 内存泄漏标志
    _isMounted = true;

    UNSAFE_componentWillMount() {
        // 监测本地是否存在用户核心参数，否则跳转 404
        var userData = storageUtils.get('users');

        if (userData === null) {
            this.props.history.push('/notfound');
            return '';
        }

        let userInfo = this.state.users;
        userInfo.domain = userData.shop_domain;
        userInfo.token = userData.user_token;
        if (this._isMounted) {
            this.setState({ userInfo });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this._isMounted) {
            this.setState({ mode: nextProps.mode, is_show_cart: nextProps.isShowCart, dateLang: nextProps.dateLang, calendar: nextProps.calendar });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * 复制代码
     */
    copyCodeSection = () => {
        if (this._isMounted) {
            this.setState({ is_notify: !this.state.is_notify, notify_content: 'Copied to clipboard' });
        }
    }

    handleTextChange = (v) => {
        if (this._isMounted) {
            this.setState({ text_v: v });
        }
    }

    handleCollecitonTextChange = (v) => {
        if (this._isMounted) {
            this.setState({ text_c: v });
        }
    }
    /**
     * 编辑购物车默认文本
     */
    editCartTextChange = async () => {
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const param = {
            'cart_l': this.state.text_v,
            'cart_date_t': this.state.cartDateT
        }
        // 预投递产品列表
        let editCartTextD = await editCartText(headers, param);
        if (editCartTextD.code === 200) {
            if (this._isMounted) {
                this.setState({ is_active: !this.state.is_active });
            }
        }
        else {
            if (this._isMounted) {
                this.setState({ is_notify: !this.state.is_notify, notify_content: 'Failed edit.' });
            }
        }
    }

    /**
    * 编辑产品列表页默认文本
    */
    editCollectionTextChange = async () => {
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const param = {
            'collection_l': this.state.text_c
        }
        // 预投递产品列表
        let editCollectionTextD = await editCollectionText(headers, param);
        if (editCollectionTextD.code === 200) {
            if (this._isMounted) {
                this.setState({ is_active_l: !this.state.is_active_l });
            }
        }
        else {
            if (this._isMounted) {
                this.setState({ is_notify: !this.state.is_notify, notify_content: 'Failed edit.' });
            }
        }
    }


    /**
     * cart/checkout 页面是否显示
     */
    checkboxCartCheckout = (v) => {
        if(this.state.plans == 0){
            if (this._isMounted) {
                this.setState({ is_free_plan_active: true });
            }
        }else{
            if (this._isMounted) {
                this.setState({ is_show_cart: v });
            }
            this.props.updateSaveOrDiscardComponent("is_show_cart", [v]);
        }
    }

    /**
    * collection/checkout 页面是否显示
    */
    checkboxCollectionCheckout = (v) => {
        if (this._isMounted) {
            this.setState({ is_show_collection: v });
        }

        this.props.updateSaveOrDiscardComponent("is_show_collection", [v]);
    }

    /**
     * 激活编辑弹窗
     */
    activeEditModal = () => {
        if(this.state.plans == 0){
            if (this._isMounted) {
                this.setState({ is_free_plan_active: true });
            }
        }else{
            if (this._isMounted) {
                this.setState({ is_active: !this.state.is_active });
            }
        }
    }

    /**
     * 激活编辑弹窗
     */
    activeCollectionEditModal = () => {
        if (this._isMounted) {
            this.setState({ is_active_l: !this.state.is_active_l });
        }
    }


    /**
     * 关闭编辑弹窗
     */
    closeModel = () => {
        if (this._isMounted) {
            this.setState({ is_active: !this.state.is_active });
        }
    }

    /**
     * 关闭编辑弹窗
     */
     closeFreePlanModel = () => {
        if (this._isMounted) {
            this.setState({ is_free_plan_active: !this.state.is_free_plan_active });
        }
    }

    /**
         * 关闭编辑弹窗
         */
    collectionCloseModel = () => {
        if (this._isMounted) {
            this.setState({ is_active_l: !this.state.is_active_l });
        }
    }

    /**
     * 弹窗是否活跃
     */
    toggleActive = () => {
        if (this._isMounted) {
            this.setState({ is_notify: !this.state.is_notify });
        }
    }

    handleDateSelectChange = (v) => {
        if (this._isMounted) {
            this.setState({ cartDateT: v });
        }
    }

    render() {
        const toastMarkup = this.state.is_notify ? (
            <Toast content={this.state.notify_content} onDismiss={this.toggleActive} />
        ) : null;
        const options = [
            {label: 'Delivery date range', value: 0},
            {label: 'Delivery date min', value: 1},
            {label: 'Delivery date max', value: 2},
        ];        
        const placementCode = '<div id="edt-p"></div>';
        const emailETACode = '{% for p in line.properties %}{% unless p.last == blank %} <br>{{ p.first }}: {{ p.last }}{% endunless %}{% endfor %}';
        const templateUrl = "https://" + this.state.users.domain + "/admin/themes/current/?key=templates";
        const emailFaqUrl = "https://helpcenterapi.sealapps.com/help/app/article/15/35";
        let dateLang = this.state.dateLang;
        let calendar = this.state.calendar;
        let cartDateT = parseInt(this.state.cartDateT);
        // let deliveryTimeMin = (storageUtils.get('identity_country')) ? storageUtils.get('identity_country').delivery_date_min : this.state.identity_country.delivery_date_min;
        // let deliveryTimeMax = (storageUtils.get('identity_country')) ? storageUtils.get('identity_country').delivery_date_max : this.state.identity_country.delivery_date_max;
        let deliveryDateMin = (storageUtils.get('identity_country')) ? storageUtils.get('identity_country').delivery_date_l : this.state.identity_country.delivery_date_l;
        let deliveryDateMax = (storageUtils.get('identity_country')) ? storageUtils.get('identity_country').delivery_date_r : this.state.identity_country.delivery_date_r;
        let dateRes = dateConversion(deliveryDateMin, deliveryDateMax, String(this.state.mode), dateLang, calendar);
        let cartInfo = <div className="preview"><span>{this.state.text_v}</span><span>: </span><strong>{dateRes.d_min}</strong> - <strong>{dateRes.d_max}</strong></div>;
        if(cartDateT == 1){
            cartInfo = <div className="preview"><span>{this.state.text_v}</span><span>: </span><strong>{dateRes.d_min}</strong> </div>
        }else if(cartDateT == 2){
            cartInfo =  <div className="preview"><span>{this.state.text_v}</span><span>: </span><strong>{dateRes.d_max}</strong></div>
        }
        return (
            <div className="et-dash-placement">
                <Layout>
                    <Layout.Section>
                        <div className="cart-checkout">
                            <Card title="Display the bar on other pages"
                            actions={[
                                { content: <img src="/image/plan.png"></img>},
                            ]}
                             sectioned>
                                <div className="cart-checkout-one">
                                    <div className="cart-checkout-checkbox">
                                        <div className="cart-checkout-img">
                                            <Checkbox label="Show bar on Cart/Check out page" checked={this.state.is_show_cart} onChange={this.checkboxCartCheckout} />
                                            <Tooltip  preferredPosition="above"  content={<div>Please note that customers should <b>wait until ETA show on product page</b>, only then click "Add To Cart" can let ETA show on cart page. Also, ETA showing on cart page is the <b>prerequisite</b> of ETA showing on check out page and confirmation email.</div>}>
                      <Icon
                      source={QuestionMarkMinor}
                       />
                    </Tooltip>
                                        </div>
                                        <div className="edit-text">
                                            <a onClick={this.activeEditModal}>Edit</a>
                                        </div>
                                    </div>
                                    { cartInfo }
                                </div>
                                <div className="cart-checkout-one">
                                    <div className="cart-checkout-checkbox">
                                        <div className="cart-checkout-email">
                                           <Icon
                                            source={InviteMinor}
                                            color="base" />
                                            <label>On order confirmation email</label>
                                            <Link url={emailFaqUrl} external={true}>How</Link>
                                        </div>
                                        <div className="edit-text">
                                                <div className="code-btn"><CopyToClipboard text={emailETACode}><Button onClick={this.copyCodeSection}>Copy</Button></CopyToClipboard></div>
                                        </div>
                                    </div>
                                    <div className="code-email-input"><TextField disabled={true} value={emailETACode} /></div>
                                </div>
                            </Card>
                        </div>
                        <div className="placement">
                            <Card title="Placement" sectioned>
                                <div>To display the bar in a custom location place the following code inside the <Link url={templateUrl} external={true}>template file</Link></div>
                                <div className="code">
                                    <div className="code-input"><TextField disabled={true} value={placementCode} /></div>
                                    <div className="code-btn"><CopyToClipboard text={placementCode}><Button onClick={this.copyCodeSection}>Copy</Button></CopyToClipboard></div>
                                </div>
                            </Card>
                        </div>
                    </Layout.Section>
                </Layout>

                <Modal
                    open={this.state.is_free_plan_active}
                    onClose={this.closeFreePlanModel}
                    title="This feature is only enabled on the Standard plan or higher"
                >
                    <Modal.Section>
                        <div className="plans-free-div">
                            <div className="plans-free-title-bz"><img src="/image/plan.png"></img>Upgrade your plan to use this feature<img src="/image/plan.png"></img></div>
                            <div className="plans-free-button">
                            <Link url="plan">
                            <Button
                                primary
                                fullWidth
                                onClick={()=>{}}
                                >
                                $0.00 upgrade to paid version
                            </Button>
                            </Link>
                            </div>
                        </div>
                    </Modal.Section>
                </Modal>

                <Modal
                    open={this.state.is_active}
                    onClose={this.closeModel}
                    title="Edit text"
                    primaryAction={{
                        content: 'Save',
                        onAction: this.editCartTextChange,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: this.closeModel,
                        },
                    ]}
                >
                    <Modal.Section>
                        <TextContainer>
                            <TextField label="Cart text" value={this.state.text_v} onChange={this.handleTextChange} />
                        </TextContainer>
                        <Select
                            label="Date display"
                            options={options}
                            onChange={this.handleDateSelectChange}
                            value={cartDateT}
                        />
                    </Modal.Section>
                </Modal>

                <Modal
                    open={this.state.is_active_l}
                    onClose={this.collectionCloseModel}
                    title="Edit text"
                    primaryAction={{
                        content: 'Save',
                        onAction: this.editCollectionTextChange,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: this.collectionCloseModel,
                        },
                    ]}
                >
                    <Modal.Section>
                        <TextContainer>
                            <TextField label="Collection text" value={this.state.text_c} onChange={this.handleCollecitonTextChange} />
                        </TextContainer>
                    </Modal.Section>
                </Modal>

                <Frame>{toastMarkup}</Frame>
            </div>
        )
    }
}


export default Placement;