import ajax from './ajax';
import { API_DOMAIN } from '../utils/constants';
import jsonp from 'jsonp'


/* Dashborad 模块 */
// 开启/关闭 App
export const getUserSetting = (headers, params) => ajax(API_DOMAIN + '/get_setting', params, 'GET', headers);
export const isEnabled = (headers, params) => ajax(API_DOMAIN + '/is_enabled', params, 'POST', headers);
export const deleteBanner = (headers, params) => ajax(API_DOMAIN + '/del_banner', params, 'POST', headers);
export const deleteProductLimitBanner = (headers, params) => ajax(API_DOMAIN + '/del_product_limit_banner', params, 'POST', headers);
export const feedback = (headers, params) => ajax(API_DOMAIN + '/feedback', params, 'POST', headers);
export const selectPlanPraise = (headers, params) => ajax(API_DOMAIN + '/select_plan_praise', params, 'POST', headers);
export const addUserSetting = (headers, params) => ajax(API_DOMAIN + '/add_setting', params, 'POST', headers);
export const editCartText = (headers, params) => ajax(API_DOMAIN + '/edit_cart_l', params, 'POST', headers);
export const editCollectionText = (headers, params) => ajax(API_DOMAIN + '/edit_collection_l', params, 'POST', headers);

// export const identityCountryAction = (headers, params) => ajax('https://geolocation-db.com/json', params, 'GET', headers);

// export const identityCountryAction = () => {
//     return new Promise((resolve, reject) => {
//         // let promise;
//         const url = `https://geolocation-db.com/jsonp/`
//         // 发送 jsonp 请求
//         jsonp(url, {name: 'callback'}, (err, data) => {
//             resolve(data);
//             // 如果成功了
//             // if (!err && data.status==='success') {
//             //     resolve(data)
//             // } else {
//             //     reject(err);
//             // }
//         });;

//         // // 请求成功
//         // promise.then((response, reject) => {
//         //     resolve(response.data)
//         // }).catch(error => {
//         //     reject(error)
//         // })
//     })
// }
/*
json请求的接口请求函数
 */
export const identityCountryAction = (headers, params) => ajax(API_DOMAIN + '/get_address_info', params, 'GET', headers);

export const saveCountryInfoData = (headers, params) => ajax(API_DOMAIN + '/save_country_info_data', params, 'POST', headers);

//同步全部产品
export const syncProductALLData = (headers, params) => ajax(API_DOMAIN + '/sync_product_all_data', params, 'POST', headers);
export const syncCollectionALLData = (headers, params) => ajax(API_DOMAIN + '/sync_collection_all_data', params, 'POST', headers);

/* Delivery Zone 模块 */
// 国家列表
export const countryList = (headers, params) => ajax(API_DOMAIN + '/country_list', params, 'GET', headers);
// 国家预投递列表
export const countryDeliveryTimeList = (headers, params) => ajax(API_DOMAIN + '/country_delivery_time_list', params, 'POST', headers);
// 编辑预投递国家
export const editCountry = (headers, params) => ajax(API_DOMAIN + '/edit_country', params, 'POST', headers);
// 删除预投递国家
export const deleteCountry = (headers, params) => ajax(API_DOMAIN + '/delete_country', params, 'POST', headers);
// 创建预投递国家
export const createCountry = (headers, params) => ajax(API_DOMAIN + '/create_country', params, 'POST', headers);
// 获取国家信息
export const getSeletCountry = (headers, params) => ajax(API_DOMAIN + '/get_select_country', params, 'POST', headers);

// 删除产品预投递国家
export const deleteProductCountry = (headers, params) => ajax(API_DOMAIN + '/delete_product_country', params, 'POST', headers);
// 编辑产品预投递国家
export const editProductCountry = (headers, params) => ajax(API_DOMAIN + '/edit_product_country', params, 'POST', headers);
//创建产品国家数据
export const createProductCountry = (headers, params) => ajax(API_DOMAIN + '/create_product_country', params, 'POST', headers);
//产品添加全局国家数据
export const createOverallProductCountry = (headers, params) => ajax(API_DOMAIN + '/create_overall_product_country', params, 'POST', headers);

/* Product 模块 */
//指定产品国家预投递列表
export const getProductCountryDeliveryTimeList = (headers, params) => ajax(API_DOMAIN + '/get_product_country_delivery_time_list', params, 'POST', headers);
// 产品列表
export const productList = (headers, params) => ajax(API_DOMAIN + '/product_list', params, 'POST', headers);
// 搜索产品列表
export const sProductList = (headers, params) => ajax(API_DOMAIN + '/s_product_list', params, 'POST', headers);
// 预投递产品列表
export const addProductList = (headers, params) => ajax(API_DOMAIN + '/add_product_list', params, 'POST', headers);
// 添加预投递产品
export const addProduct = (headers, params) => ajax(API_DOMAIN + '/add_product', params, 'POST', headers);
// 编辑预投递产品时间
export const editProduct = (headers, params) => ajax(API_DOMAIN + '/edit_product', params, 'POST', headers);
// 编辑预投递产品信息
export const editProductInfo = (headers, params) => ajax(API_DOMAIN + '/edit_product_info', params, 'POST', headers);
// 批量编辑预投递产品
export const editAllProduct = (headers, params) => ajax(API_DOMAIN + '/edit_all_product', params, 'POST', headers);
// 删除预投递产品
export const removeProduct = (headers, params) => ajax(API_DOMAIN + '/remove_product', params, 'POST', headers);
// 批量删除预投递产品
export const removeAllProduct = (headers, params) => ajax(API_DOMAIN + '/remove_all_product', params, 'POST', headers);

/* Collection 模块 */
// Collection列表
export const collectionList = (headers, params) => ajax(API_DOMAIN + '/collection_list', params, 'GET', headers);
// 预投递Collection列表
export const addCollectionList = (headers, params) => ajax(API_DOMAIN + '/add_collection_list', params, 'POST', headers);
// 添加预投递Collection
export const addCollection= (headers, params) => ajax(API_DOMAIN + '/add_collection', params, 'POST', headers);
// 编辑预投递Collection
export const editCollection = (headers, params) => ajax(API_DOMAIN + '/edit_collection', params, 'POST', headers);
// 移除预投递Collection
export const removeCollection= (headers, params) => ajax(API_DOMAIN + '/remove_collection', params, 'POST', headers);

/* Plan 模块 */
// plan 试用期
export const getPlan = (headers, params) => ajax(API_DOMAIN + '/get_plan', params, 'GET', headers);