/** 主包 */
import React, { Component } from "react";
import {
  AppProvider,
  Frame,
  ContextualSaveBar,
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  SettingToggle,
  TextStyle,
  Loading,
  Link,
  Toast,
  Banner,
  Modal,
  TextField,
  Checkbox,
  MediaCard,
  Button,
  VideoThumbnail,
  Badge,
} from "@shopify/polaris";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { Rate, Switch } from "antd";
import { CommentOutlined } from "@ant-design/icons";

/** 组件包 */
import EditSettings from "./components/edit-setting/edit-setting";
import Preview from "./components/preview/preview";
import DeliveryTime from "./components/delivery-time/delivery-time";
import Placement from "./components/placement/placement";
import RecommendApp from "./components/recommend-app/index";

/** 工具组件包 */
import storageUtils from "../../utils/storageUtils";
import Fun from "../../utils/fun";
import { getLocalTime } from "../../utils/fun";
import { getUserSetting } from "../../api/index";
import { addUserSetting } from "../../api/index";
import { isEnabled } from "../../api/index";
import { identityCountryAction } from "../../api/index";
import { deleteBanner } from "../../api/index";
import { deleteProductLimitBanner } from "../../api/index";
import { feedback } from "../../api/index";
import createApp from "@shopify/app-bridge";

/** 样式 */
import "antd/dist/antd.css";
import "./index.less";

class Dash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: {
        token: "",
        domain: "",
      },
      star_v: 5,
      f_email_text: "",
      f_email: "",
      f_show: false,
      f_shiping: false,
      is_show_product_limit_bannber: 0,
      is_show_faq: 0,
      is_load: true,
      is_enabled: false,
      p_url: "",
      p_image_url: "",
      plans: 0,
      isShow: false,
      save_is_load: false,
      content: "",
      content_default: "",
      content_default_a: "",
      date_lang_list: [],
      calendar: [],
      timeformats: [],
      dash_data: {
        content: "",
        positions: [],
        range: [0, 1],
        weekdays: [],
        mode: "1",
        initTimeH: "0",
        initTimeM: "0",
        initTimeF: "0",
        is_show_cart: true,
        is_show_collection: false,
        initOutOfStockMode: "0",
        initOutOfStockContent: "",
        initOutOfStockCustomContent: "",
        OutOfStockStatus: 1,
        date_filer: [],
        date_lang: "en",
        determine_template_id: 1,
        is_show_p_c: false,
      },
      dash_data_default: {
        content: "",
        positions: [],
        range: [0, 1],
        weekdays: [],
        mode: "1",
        initTimeH: "0",
        initTimeM: "0",
        initTimeF: "0",
        is_show_cart: true,
        is_show_collection: false,
        initOutOfStockMode: "0",
        initOutOfStockContent: "",
        initOutOfStockCustomContent: "",
        OutOfStockStatus: 1,
        date_filer: [],
        date_lang: "en",
        determine_template_id: 1,
        is_show_p_c: false,
      },
      identity_country: {},
      cart_l: "",
      cart_date_t: 0,
      collection_l: "",
      timezone: "+08",
      is_notify: false,
      notify_content: "",
    };
    // //判断是否禁用cookies
    // let isSuportLocalstorage = this.supportLocalStorage();
    // if (isSuportLocalstorage != true) {
    //   this.props.history.push("/cookiesnotfound");
    //   return;
    // }

    // 接收用户授权安装传递的核心参数
    if (this.props.location.search) {
      var paramsUrl = this.props.location.search;
      if (paramsUrl.indexOf("user_token")) {
        var userData = Fun.parseQueryString(paramsUrl);
        storageUtils.set("users", userData);
      }
    }
  }
  supportLocalStorage() {
    /*alert(navigator.userAgent);*/
    if (navigator.userAgent.indexOf("UCBrowser") > -1) {
      return false;
    }
    var uaFlag = 0;
    var uaArr = ["Chrome",
      "MQQBrowser",
      "QQ",
      "TBS",
      "wxwork",
      "MicroMessenger",
      "T7",
      "baiduboxapp",
      "baidubrowser",
      "MiuiBrowser",
      "NetType",
      "OPR"];
    for (var i = 0; i < uaArr.length; i++) {
      if (navigator.userAgent.indexOf(uaArr[i]) > -1) {
        uaFlag = 1;
      }
    }
    if (uaFlag !== 1) {
      if (
        navigator.userAgent.indexOf("HUAWEIEVA") > -1 ||
        navigator.userAgent.indexOf("HUAWEIVTR") > -1
      ) {
        return false;
      }
    }
    return true;
    // var testKey = "test";
    // try {
    //   window.localStorage.setItem(testKey, "testValue");
    //   window.localStorage.removeItem(testKey);
    //   return true;
    // } catch (e) {
    //   return false;
    // }
  }

  // 内存泄漏标志
  _isMounted = true;

  UNSAFE_componentWillMount() {
    let isSuportLocalstorage = this.supportLocalStorage();
    if (isSuportLocalstorage === true) {
      // 监测本地是否存在用户核心参数，否则跳转 404
      var userData = storageUtils.get("users");

      if (userData === null) {
        this.props.history.push("/notfound");
        return "";
      }

      let userInfo = this.state.users;
      userInfo.domain = userData.shop_domain;
      userInfo.token = userData.user_token;
      if (this._isMounted) {
        this.setState({ userInfo });
      }
      // console.log(this.calcDate(new Date(), 5));
      this.initIdentifyCountry();

      // 初始化数据
      this.initData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initIdentifyCountry = async () => {
    let identityCountryRes = await identityCountryAction();
    identityCountryRes = identityCountryRes.data ?? {};
    let countryData = {
      country_code: identityCountryRes.country_code.toLowerCase(),
      country_name: identityCountryRes.country_name,
      state: identityCountryRes.state,
    };
    storageUtils.set("identity_country", countryData);
  };

  /**
   * 初始化数据
   */
  initData = async () => {
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };

    // 获取用户配置
    let getUserSettingD = await getUserSetting(headers);
    if (getUserSettingD.code === 200) {
      const settings = {
        user_name: getUserSettingD.data.name || 'no name',
        // name: "User1" || 'no name',
        user_email: getUserSettingD.data.email || 'no email',
        // email: "email@sealapps.net" || 'no email',
        shopUrl: getUserSettingD.data.shop_domain || 'no shopName',
        // shop_name: "yepptest" || 'no shopName',
        user_id: 'ETA - ' + getUserSettingD.data.user_id || 'no userId',
        // user_id: 684896123681 || 'no userId',
        fromApp: 'ETA', // 改成你们 app名字
        // shopify套餐
        shopifyPlan: getUserSettingD.data.plan_display_name,
        // 评论者xingming
        reviewName: getUserSettingD.data.review_name != '' ? getUserSettingD.data.review_name : 'No review',
        // 评论星级
        review: getUserSettingD.data.rating,
        appPlan: getUserSettingD.data.plan_lavael,

      };

      if (typeof window.Willdesk == 'object') {
        window.Willdesk.boot(settings);
      } else {
        let load = window.WilldeskLoad = false
        Object.defineProperty(window, 'WilldeskLoad', {
          get() { return load },
          set(newVal) {
            load = newVal
            window.Willdesk.boot(settings);
            return load
          }
        })
      }



      // window.Intercom('boot', {
      //   user_id: 'edt_' + getUserSettingD.data.user_id,
      //   shop_name: getUserSettingD.data.shop_domain,
      //   app_id: "t6tndyrj",
      //   name: getUserSettingD.data.name, // Full name
      //   email: getUserSettingD.data.email,
      //   created_at: getUserSettingD.data.created_at,
      //   review: getUserSettingD.data.rating,
      //   review_name: getUserSettingD.data.review_name,
      //   plan: getUserSettingD.data.plan_lavael,
      //   user_name: getUserSettingD.data.name,
      //   shopify_store_version: getUserSettingD.data.plan_display_name,
      //   from_app: 'ETA',
      // })
      if (this._isMounted) {
        const blocksFromHtml = htmlToDraft(getUserSettingD.data.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        const defaultContent = draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        );
        const date_filer_arr = [];
        if (getUserSettingD.data.date_filer !== "") {
          const date_filer_str = getUserSettingD.data.date_filer.split(",");
          date_filer_str.forEach(function (item) {
            //调用split，以空字符串为分隔符切割字符串并返回数组，在数组上调用forEach方法
            if (item !== "NaN") {
              date_filer_arr.push(parseInt(item)); //对传入的每个字符用pasreInt转换为数字并压入arr数组
            }
          });
        }

        if (this._isMounted) {
          this.setState({
            is_load: false,
            isShow: false,
            is_enabled: getUserSettingD.data.is_enabled === 0 ? 0 : 1,
            is_show_faq: getUserSettingD.data.is_show_faq === 0 ? 0 : 1,
            is_show_product_limit_bannber:
              getUserSettingD.data.is_show_product_limit_banner === 0 ? 0 : 1,
            p_url: getUserSettingD.data.p_url,
            p_image_url: getUserSettingD.data.p_image_url,
            plans: getUserSettingD.data.plan,
            content: defaultContent,
            content_default: defaultContent,
            content_default_a: "",
            date_lang_list: getUserSettingD.data.date_lang_list,
            calendar: getUserSettingD.data.calendar,
            timeformats: getUserSettingD.data.timeformats,
            dash_data: {
              positions: getUserSettingD.data.position.split(","),
              range: getUserSettingD.data.delivery_time.split(","),
              weekdays: getUserSettingD.data.weekday.split(","),
              mode: String(getUserSettingD.data.mode),
              initTimeH: String(getUserSettingD.data.time_h),
              initTimeM: String(getUserSettingD.data.time_m),
              initTimeF: String(getUserSettingD.data.time_f),
              is_show_cart:
                getUserSettingD.data.is_show_cart === 1,
              is_show_collection:
                getUserSettingD.data.is_show_collection === 1,
              initOutOfStockMode: String(
                getUserSettingD.data.out_of_stock_mode
              ),
              initOutOfStockContent: getUserSettingD.data.out_of_stock_content,
              initOutOfStockCustomContent:
                getUserSettingD.data.out_of_stock_custom_content,
              OutOfStockStatus:
                getUserSettingD.data.out_of_stock_status,
              date_filer: date_filer_arr,
              date_lang: getUserSettingD.data.date_lang,
              determine_template_id: getUserSettingD.data.determine_template_id,
              is_show_p_c:
                getUserSettingD.data.is_show_p_c === 1,
            },
            dash_data_default: {
              positions: getUserSettingD.data.position.split(","),
              range: getUserSettingD.data.delivery_time.split(","),
              weekdays: getUserSettingD.data.weekday.split(","),
              mode: String(getUserSettingD.data.mode),
              initTimeH: String(getUserSettingD.data.time_h),
              initTimeM: String(getUserSettingD.data.time_m),
              initTimeF: String(getUserSettingD.data.time_f),
              is_show_cart:
                getUserSettingD.data.is_show_cart === 1,
              is_show_collection:
                getUserSettingD.data.is_show_collection === 1,
              initOutOfStockMode: String(
                getUserSettingD.data.out_of_stock_mode
              ),
              initOutOfStockContent: getUserSettingD.data.out_of_stock_content,
              initOutOfStockCustomContent:
                getUserSettingD.data.out_of_stock_custom_content,
              OutOfStockStatus:
                getUserSettingD.data.out_of_stock_status,
              date_filer: date_filer_arr,
              date_lang: getUserSettingD.data.date_lang,
              determine_template_id: getUserSettingD.data.determine_template_id,
              is_show_p_c:
                getUserSettingD.data.is_show_p_c === 1,
            },
            cart_l: getUserSettingD.data.cart_l,
            cart_date_t: getUserSettingD.data.cart_date_t,
            collection_l: getUserSettingD.data.collection_l,
            timezone: getUserSettingD.data.timezone,
          });
        }

        /* 预计时间计算 */
        let item = [];
        getUserSettingD.data.weekday.split(",").map((v, i) => {
          if (v === "0") {
            let week = i + 1;
            if (week === 7) {
              week = 0;
            }
            item.push(week);
          }

          return "";
        });
        let deliveryTimeMin = parseInt(
          getUserSettingD.data.delivery_time.split(",")[0]
        );
        let deliveryTimeMax = parseInt(
          getUserSettingD.data.delivery_time.split(",")[1]
        );
        // let deliveryTimeInterval = deliveryTimeMax - deliveryTimeMin;
        let identityCountryRes = await identityCountryAction();
        // let deliveryTimeLeft = this.dateConversion(this.calcDate(new Date(), this.calcTotalDays(deliveryTimeMin, item)));
        // let deliveryTimeRight = this.dateConversion(this.calcDate(deliveryTimeLeft, deliveryTimeInterval));
        let addDeliveryDay = this.calcDeliveryDays(this.state.timezone, this.state.dash_data.weekdays, this.state.dash_data.date_filer);
        let deliveryDateLeft = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(deliveryTimeMin) + parseInt(addDeliveryDay),
            item,
            this.state.dash_data.date_filer
          )
        );
        // let deliveryDateRight = this.calcDate(deliveryDateLeft, deliveryTimeInterval);
        let deliveryDateRight = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(deliveryTimeMax) + parseInt(addDeliveryDay),
            item,
            this.state.dash_data.date_filer
          )
        );

        let countryData = {
          country_code:
            typeof identityCountryRes.country_code != "undefined"
              ? identityCountryRes.country_code.toLowerCase()
              : "",
          country_name:
            typeof identityCountryRes.country_name != "undefined"
              ? identityCountryRes.country_name
              : "",
          state:
            typeof identityCountryRes.state != "undefined"
              ? identityCountryRes.state
              : "",

          // 'delivery_date_min': deliveryTimeLeft,
          // 'delivery_date_max': deliveryTimeRight,
          delivery_date_l: deliveryDateLeft,
          delivery_date_r: deliveryDateRight,
        };

        // storageUtils.set('identity_country', countryData);
        // if (this._isMounted) {
        //     this.setState({ identity_country: countryData });
        // }
      }
    } else {
      // console.log(456);
    }
  };

  /**
   * 星星评价
   * @param {*} v
   */
  changeStar = (v) => {
    if (v === 0) v = this.state.star_v;
    if (this._isMounted) {
      this.setState({ star_v: v });
    }
    if (v < 5) {
      if (this._isMounted) {
        this.setState({ f_show: true });
      }
    } else {
      window.open(
        "https://apps.shopify.com/estimated-delivery-time-pro?#modal-show=ReviewListingModal",
        "_blank"
      );
    }
  };

  handleEmailTextChange = (v) => {
    if (this._isMounted) {
      this.setState({ f_email_text: v });
    }
  };

  handleEmailChange = (v) => {
    if (this._isMounted) {
      this.setState({ f_email: v });
    }
  };

  closeFeedbackChange = () => {
    if (this._isMounted) {
      this.setState({ f_show: false });
    }
  };

  closeShiPinghange = () => {
    if (this._isMounted) {
      this.setState({ f_shiping: false });
    }
  };

  deleteProductLimitBannerChange = async () => {
    if (this._isMounted) {
      this.setState({ is_show_product_limit_bannber: 0 });
    }

    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const param = {
      is_show_product_limit_bannber: 0,
    };
    // Banner 关闭结果
    let deleteProductLimitBannerRes = await deleteProductLimitBanner(
      headers,
      param
    );
    if (deleteProductLimitBannerRes.code === 200) {
    }
  };

  deleteBannerChange = async () => {
    if (this._isMounted) {
      this.setState({ is_show_faq: 0 });
    }

    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const param = {
      is_show_faq: 0,
    };
    // Banner 关闭结果
    let deleteBannerRes = await deleteBanner(headers, param);
    if (deleteBannerRes.code === 200) {
    }
  };

  sendFeedbackEmail = async () => {
    if (this.state.f_email.length === 0 || this.state.f_email_text === 0) {
      this.setState({
        is_notify: true,
        notify_content: "Incorrect message format",
      });
      return;
    }

    if (
      this.state.f_email.indexOf("@") === -1 ||
      this.state.f_email.indexOf(".") === -1
    ) {
      this.setState({
        is_notify: true,
        notify_content: "Incorrect message format",
      });
      return;
    }
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const param = {
      from_email: this.state.f_email,
      content: this.state.f_email_text,
      star_num: this.state.star_v,
    };
    // feedback 结果
    let feedbackRes = await feedback(headers, param);
    if (feedbackRes.code === 200) {
      this.setState({
        is_notify: true,
        notify_content: "Mail sent successfully.",
        f_show: false,
      });
    } else if (feedbackRes.status === 6101) {
      this.setState({
        is_notify: true,
        notify_content: feedbackRes.msg,
      });
    } else {
      this.setState({
        is_notify: true,
        notify_content: "Failed to send mail.",
      });
    }
  };

  /**
   * 开启/关闭 App
   *
   * @param {*} isActive
   */
  handleToggle = async (isEnable, isEnable_status) => {
    console.info(isEnable_status)
    if (this._isMounted) {
      this.setState({
        is_notify: true,
        notify_content: !isEnable_status
          ? "Enabled successfully"
          : "Disabled successfully",
      });
    }

    if (this._isMounted) {
      this.setState({ is_enabled: !isEnable_status });
    }

    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const param = {
      is_enabled: isEnable_status ? 0 : 1,
    };
    // 产品列表
    let enabledRes = await isEnabled(headers, param);
    if (enabledRes.code === 200) {
      if (this._isMounted) {
        this.setState({
          p_url: enabledRes.data.p_url,
          p_image_url: enabledRes.data.p_image_url,
        });
      }
    }
  };

  updateSaveOrDiscardComponent = (type = "", v = []) => {
    let dashData = this.state.dash_data;
    switch (type) {
      case "date-filer":
        dashData.date_filer = v;
        if (this._isMounted) {
          this.setState({ dashData });
        }
        let item3 = [];
        this.state.dash_data.weekdays.map((v1, i) => {
          if (v1 === "0") {
            let week = i + 1;
            if (week === 7) {
              week = 0;
            }
            item3.push(week);
          }

          return "";
        });

        // let deliveryTimeMin1 = parseInt(v[0]);
        // let deliveryTimeMax1 = parseInt(v[1]);
        // let deliveryTimeInterval1 = deliveryTimeMax1 - deliveryTimeMin1;

        // let deliveryTimeLeft1 = this.dateConversion(this.calcDate(new Date(), this.calcTotalDays(parseInt(v[0]), item1)));
        // let deliveryTimeRight1 = this.dateConversion(this.calcDate(deliveryTimeLeft1, deliveryTimeInterval1));
        let addDeliveryDay3 = this.calcDeliveryDays(this.state.timezone, this.state.dash_data.weekdays, this.state.dash_data.date_filer);
        let deliveryDateLeft3 = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(this.state.dash_data.range[0]) + parseInt(addDeliveryDay3),
            item3,
            this.state.dash_data.date_filer
          )
        );
        // let deliveryDateRight1 = this.calcDate(deliveryDateLeft1, deliveryTimeInterval1);
        let deliveryDateRight3 = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(this.state.dash_data.range[1]) + parseInt(addDeliveryDay3),
            item3,
            this.state.dash_data.date_filer
          )
        );
        let countryData3 = {
          country_code: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").country_code
            : "",
          country_name: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").country_name
            : "",
          state: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").state
            : "",
          // 'delivery_date_min': deliveryTimeLeft1,
          // 'delivery_date_max': deliveryTimeRight1,
          delivery_date_l: deliveryDateLeft3,
          delivery_date_r: deliveryDateRight3,
        };
        storageUtils.set("identity_country", countryData3);
        break;
      case "postion":
        dashData.positions = v;
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      case "range":
        dashData.range = v;
        if (this._isMounted) {
          this.setState({ dashData });
        }

        let item1 = [];
        this.state.dash_data.weekdays.map((v1, i) => {
          if (v1 === "0") {
            let week = i + 1;
            if (week === 7) {
              week = 0;
            }
            item1.push(week);
          }

          return "";
        });

        // let deliveryTimeMin1 = parseInt(v[0]);
        // let deliveryTimeMax1 = parseInt(v[1]);
        // let deliveryTimeInterval1 = deliveryTimeMax1 - deliveryTimeMin1;

        // let deliveryTimeLeft1 = this.dateConversion(this.calcDate(new Date(), this.calcTotalDays(parseInt(v[0]), item1)));
        // let deliveryTimeRight1 = this.dateConversion(this.calcDate(deliveryTimeLeft1, deliveryTimeInterval1));
        let addDeliveryDay1 = this.calcDeliveryDays(this.state.timezone, this.state.dash_data.weekdays, this.state.dash_data.date_filer);
        let deliveryDateLeft1 = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(v[0]) + parseInt(addDeliveryDay1),
            item1,
            this.state.dash_data.date_filer
          )
        );
        // let deliveryDateRight1 = this.calcDate(deliveryDateLeft1, deliveryTimeInterval1);
        let deliveryDateRight1 = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(v[1]) + parseInt(addDeliveryDay1),
            item1,
            this.state.dash_data.date_filer
          )
        );
        let countryData1 = {
          country_code: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").country_code
            : "",
          country_name: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").country_name
            : "",
          state: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").state
            : "",
          // 'delivery_date_min': deliveryTimeLeft1,
          // 'delivery_date_max': deliveryTimeRight1,
          delivery_date_l: deliveryDateLeft1,
          delivery_date_r: deliveryDateRight1,
        };
        storageUtils.set("identity_country", countryData1);
        break;
      case "weekday":
        dashData.weekdays = v;
        if (this._isMounted) {
          this.setState({ dashData });
        }

        let item2 = [];
        v.map((v1, i) => {
          if (v1 === "0") {
            let week = i + 1;
            if (week === 7) {
              week = 0;
            }
            item2.push(week);
          }

          return "";
        });
        // let deliveryTimeMin2 = parseInt(this.state.dash_data_default.range[0]);
        // let deliveryTimeMax2 = parseInt(this.state.dash_data_default.range[1]);
        // let deliveryTimeMin2 = parseInt(this.state.dash_data.range[0]);
        // let deliveryTimeMax2 = parseInt(this.state.dash_data.range[1]);
        // let deliveryTimeInterval2 = deliveryTimeMax2 - deliveryTimeMin2;

        // let deliveryTimeLeft2 = this.dateConversion(this.calcDate(new Date(), this.calcTotalDays(parseInt(this.state.dash_data.range[0]), item2)));
        // let deliveryTimeRight2 = this.dateConversion(this.calcDate(deliveryTimeLeft2, deliveryTimeInterval2));
        let addDeliveryDay2 = this.calcDeliveryDays(this.state.timezone, this.state.dash_data.weekdays, this.state.dash_data.date_filer);

        let deliveryDateLeft2 = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(this.state.dash_data.range[0]) + parseInt(addDeliveryDay2),
            item2,
            this.state.dash_data.date_filer
          )
        );
        let deliveryDateRight2 = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(this.state.dash_data.range[1]) + parseInt(addDeliveryDay2),
            item2,
            this.state.dash_data.date_filer
          )
        );
        let countryData2 = {
          country_code: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").country_code
            : "",
          country_name: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").country_name
            : "",
          state: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").state
            : "",
          // 'delivery_date_min': deliveryTimeLeft2,
          // 'delivery_date_max': deliveryTimeRight2,
          delivery_date_l: deliveryDateLeft2,
          delivery_date_r: deliveryDateRight2,
        };
        storageUtils.set("identity_country", countryData2);
        break;
      case "edit-setting":
        if (this._isMounted) {
          this.setState({ content: v });
        }
        break;
      case "mode":
        dashData.mode = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      case "initTimeH":
        dashData.initTimeH = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        let item6 = [];
        this.state.dash_data.weekdays.map((v1, i) => {
          if (v1 === "0") {
            let week = i + 1;
            if (week === 7) {
              week = 0;
            }
            item6.push(week);
          }

          return "";
        });
        let addDeliveryDay6 = this.calcDeliveryDays(this.state.timezone, this.state.dash_data.weekdays, this.state.dash_data.date_filer);

        let deliveryDateLeft6 = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(this.state.dash_data.range[0]) + parseInt(addDeliveryDay6),
            item6,
            this.state.dash_data.date_filer
          )
        );
        let deliveryDateRight6 = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(this.state.dash_data.range[1]) + parseInt(addDeliveryDay6),
            item6,
            this.state.dash_data.date_filer
          )
        );
        let countryData6 = {
          country_code: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").country_code
            : "",
          country_name: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").country_name
            : "",
          state: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").state
            : "",
          // 'delivery_date_min': deliveryTimeLeft2,
          // 'delivery_date_max': deliveryTimeRight2,
          delivery_date_l: deliveryDateLeft6,
          delivery_date_r: deliveryDateRight6,
        };
        storageUtils.set("identity_country", countryData6);
        break;
      case "initTimeM":

        dashData.initTimeM = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        let item7 = [];
        this.state.dash_data.weekdays.map((v1, i) => {
          if (v1 === "0") {
            let week = i + 1;
            if (week === 7) {
              week = 0;
            }
            item7.push(week);
          }

          return "";
        });
        let addDeliveryDay7 = this.calcDeliveryDays(this.state.timezone, this.state.dash_data.weekdays, this.state.dash_data.date_filer);

        let deliveryDateLeft7 = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(this.state.dash_data.range[0]) + parseInt(addDeliveryDay7),
            item7,
            this.state.dash_data.date_filer
          )
        );
        let deliveryDateRight7 = this.calcDate(
          getLocalTime(this.state.timezone),
          this.calcTotalDays(
            parseInt(this.state.dash_data.range[1]) + parseInt(addDeliveryDay7),
            item7,
            this.state.dash_data.date_filer
          )
        );
        let countryData7 = {
          country_code: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").country_code
            : "",
          country_name: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").country_name
            : "",
          state: storageUtils.get("identity_country")
            ? storageUtils.get("identity_country").state
            : "",
          // 'delivery_date_min': deliveryTimeLeft2,
          // 'delivery_date_max': deliveryTimeRight2,
          delivery_date_l: deliveryDateLeft7,
          delivery_date_r: deliveryDateRight7,
        };
        storageUtils.set("identity_country", countryData7);
        break;
      case "initTimeF":

        dashData.initTimeF = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      case "is_show_cart":
        dashData.is_show_cart = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      case "is_show_collection":
        dashData.is_show_collection = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      case "countdown":
        this.initData();
        // if(this._isMounted) { this.setState({dashData}) };
        break;
      case "initOutOfStockMode":
        dashData.initOutOfStockMode = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      case "initOutOfStockCustomContent":
        dashData.initOutOfStockCustomContent = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      case "OutOfStockStatus":
        dashData.OutOfStockStatus = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      case "dateLang":
        dashData.date_lang = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      case "determineTemplateId":
        dashData.determine_template_id = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      case "isShowPC":
        dashData.is_show_p_c = v[0];
        if (this._isMounted) {
          this.setState({ dashData });
        }
        break;
      default:
        break;
    }

    // console.log(11111111);
    // console.log((JSON.stringify(this.state.content_default)));
    // console.log((JSON.stringify(v)));
    // console.log(typeof v);
    // console.log(22222222);
    if (
      JSON.stringify(this.state.dash_data) !==
      JSON.stringify(this.state.dash_data_default)
    ) {
      if (this._isMounted) {
        this.setState({ isShow: true });
      }
    } else {
      if (this._isMounted) {
        this.setState({ isShow: false });
      }
    }

    if (typeof v === "string") {
      if (JSON.stringify(v) === JSON.stringify(this.state.content_default)) {
        if (this._isMounted) {
          this.setState({ isShow: false });
        }
        if (
          JSON.stringify(this.state.dash_data) !==
          JSON.stringify(this.state.dash_data_default)
        ) {
          if (this._isMounted) {
            this.setState({ isShow: true });
          }
        } else {
          if (this._isMounted) {
            this.setState({ isShow: false });
          }
        }
      } else {
        if (this._isMounted) {
          this.setState({ isShow: true });
        }
      }
    } else {
      if (this._isMounted) {
        this.setState({ isShow: true });
      }
      if (
        JSON.stringify(this.state.dash_data) !==
        JSON.stringify(this.state.dash_data_default)
      ) {
        if (this._isMounted) {
          this.setState({ isShow: true });
        }
      } else {
        if (this._isMounted) {
          this.setState({ isShow: false });
        }
      }
    }
  };

  updateSettingContent = (v) => {
    if (this._isMounted) {
      this.setState({
        content_default_a: v,
      });
    }
  };

  saveDashSetting = async () => {
    if (this._isMounted) {
      this.setState({ save_is_load: true });
    }
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };

    const outOfStockformats = [
      "",
      "Order now, and we will ship your order as soon as it's in stock!",
      "Expected back into stock within 48 hours",
    ];
    const param = {
      content: this.state.content,
      postion: this.state.dash_data.positions,
      range: this.state.dash_data.range,
      weekday: this.state.dash_data.weekdays,
      mode: this.state.dash_data.mode,
      time_h: this.state.dash_data.initTimeH,
      time_m: this.state.dash_data.initTimeM,
      time_f: this.state.dash_data.initTimeF,
      is_show_cart: this.state.dash_data.is_show_cart === true ? 1 : 0,
      is_show_collection:
        this.state.dash_data.is_show_collection === true ? 1 : 0,
      out_of_stock_mode: this.state.dash_data.initOutOfStockMode,
      out_of_stock_content:
        outOfStockformats[this.state.dash_data.initOutOfStockMode] || "",
      out_of_stock_custom_content:
        this.state.dash_data.initOutOfStockCustomContent || "",
      out_of_stock_status:
        this.state.dash_data.OutOfStockStatus || 1,
      date_filer: this.state.dash_data.date_filer,
      date_lang: this.state.dash_data.date_lang,
      determine_template_id: this.state.dash_data.determine_template_id,
      is_show_p_c: this.state.dash_data.is_show_p_c === true ? 1 : 0,
    };
    // console.log(param);return;
    // 获取用户配置
    let addUserSettingD = await addUserSetting(headers, param);
    if (addUserSettingD.code === 200) {
      if (this._isMounted) {
        const date_filer_arr = [];
        if (addUserSettingD.data.date_filer != "") {
          const date_filer_str = addUserSettingD.data.date_filer.split(",");
          date_filer_str.forEach(function (item) {
            //调用split，以空字符串为分隔符切割字符串并返回数组，在数组上调用forEach方法
            date_filer_arr.push(parseInt(item)); //对传入的每个字符用pasreInt转换为数字并压入arr数组
          });
        }
        this.setState({
          dash_data: {
            content: addUserSettingD.data.content,
            positions: addUserSettingD.data.position.split(","),
            range: addUserSettingD.data.delivery_time.split(","),
            weekdays: addUserSettingD.data.weekday.split(","),
            date_filer: date_filer_arr,
          },
          dash_data_default: {
            content: addUserSettingD.data.content,
            positions: addUserSettingD.data.position.split(","),
            range: addUserSettingD.data.delivery_time.split(","),
            weekdays: addUserSettingD.data.weekday.split(","),
            date_filer: date_filer_arr,
          },
          isShow: false,
          save_is_load: false,
        });
      }
    }

    // this.initData();
    window.location.reload();
  };

  cancelDashSettingChange = () => {
    if (this._isMounted) {
      this.setState({ isShow: false });
    }

    // this.initData();
    window.location.reload();
  };

  dateFormat(date, format, en) {
    let self = new Date(date);
    let fmt = format || 'YYYY-MM-DD hh:mm:ss';
    let o = {
      'M+': self.getMonth() + 1,
      'D+': self.getDate(),
      'h+': self.getHours(),
      'H+': self.getHours() % 12 === 0 ? 12 : self.getHours() % 12,
      'N': self.getHours() >= 12 ? (en ? 'PM' : '\u4e0b') : (en ? 'AM' : '\u4e0a'),
      'm+': self.getMinutes(),
      's+': self.getSeconds(),
      'q+': Math.floor((self.getMonth() + 3) / 3),
      'S': self.getMilliseconds()
    };
    if (/(Y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (self.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
      }
    }
    let week = ['\u65e5', '\u4e00', '\u4e8c', '\u4e09', '\u56db', '\u4e94', '\u516d'];
    let weekEn = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    if (/(d+)/.test(fmt)) {
      if (en) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length > 1) ? weekEn[self.getDay()].substr(0, 3) : weekEn[self.getDay()]);
      } else {
        fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') + week[self.getDay()]);
      }
    }
    return fmt;
  };

  calcDate(date, days, seperator = "-") {
    let oDate = new Date().valueOf();
    let nDate = oDate + days * 24 * 3600 * 1000;
    nDate = new Date(nDate);
    let y = nDate.getFullYear().toString().padStart(2, 0);
    let m = (nDate.getMonth() + 1).toString().padStart(2, 0);
    let d = nDate.getDate().toString().padStart(2, 0);
    return `${y}${seperator}${m}${seperator}${d}`;
  }

  dateConversion(d) {
    let date = new Date(d.replace(/-/g, "/")); //Wed Jan 02 2019 00:00:00 GMT+0800 (China Standard Time)
    let chinaDate = date.toDateString(); //"Tue, 01 Jan 2019 16:00:00 GMT"
    let chinaDateArray = chinaDate.split(" "); //["Wed", "Jan", "02", "2019"]
    let displayDate = `${chinaDateArray[1]} ${chinaDateArray[2]}, ${chinaDateArray[3]}`;

    return displayDate;
  }

  calcTotalDays(deliverDay, workdays, date_filer = []) {
    let current_deliverDay = deliverDay;
    if (deliverDay == 0) {
      deliverDay = 1;
    }
    let deliverDays = 0;
    for (var i = 1; i <= deliverDay; i++) {
      let date_filer_status = false;
      let oDate = new Date().valueOf();
      let nDate = oDate + i * 24 * 3600 * 1000;
      let dDate = new Date(nDate).toLocaleDateString();
      let week = new Date(nDate).getDay();
      date_filer.forEach((element) => {
        let bDate = new Date(element).toLocaleDateString();
        if (dDate === bDate) {
          date_filer_status = true;
        }
      });

      if (current_deliverDay == 0) {
        week = week - 1;
      }

      let res = Fun.in_array(week, workdays);

      if (res === true || date_filer_status == true) {
        deliverDay += 1;
      } else {
        deliverDays += 1;
      }
      if (deliverDays === deliverDay) break;
    }

    if (current_deliverDay == 0) {
      deliverDay = deliverDay - 1;
    }

    return deliverDay;
  }

  calcDeliveryDays(i, weekdays, date_filer) {
    var date = new Array();
    let day = 0;
    let currentTimestamp = getLocalTime(i).getTime();
    let countDownDate =
      this.calcDate(getLocalTime(i), 0) +
      " " +
      this.state.dash_data.initTimeH +
      ":" +
      this.state.dash_data.initTimeM +
      ":00";

    let countDownTimestamp = new Date(countDownDate).getTime();
    let week = new Date().getDay();

    if (currentTimestamp > countDownTimestamp) {
      day = 1;
    }
    if (week == 0) {
      week = 7;
    }
    if (typeof weekdays[week - 1] != "undefined") {
      if (weekdays[week - 1] == "0") {
        day = 1;
      }
    }
    for (var s = 0; s < date_filer.length; s++) {
      let sw = this.dateFormat(parseInt(date_filer[s]), 'YYYY-MM-DD');
      date.push(sw);
    }

    let sow = Date.parse(new Date());
    let ow = this.dateFormat(parseInt(sow), 'YYYY-MM-DD');
    let res = Fun.in_array(ow, date);
    if (res == true) {
      day = 1;
    }

    return day;
  }

  activeFeedbackChange = () => {
    if (this._isMounted) {
      this.setState({ f_show: true });
    }
  };

  activeShipingChange = () => {
    console.info(12312)
    if (this._isMounted) {
      this.setState({ f_shiping: true });
    }
  };
  /**
   * 弹窗是否活跃
   */
  toggleActive = () => {
    if (this._isMounted) {
      this.setState({ is_notify: !this.state.is_notify });
    }
  };

  jumpFQAHandle = () => {
    const w = window.open("about:blank");
    w.location.href =
      "https://etranslate.helpscoutdocs.com/category/55-general";
  };

  render() {
    const toastMarkup = this.state.is_notify ? (
      <Toast
        content={this.state.notify_content}
        onDismiss={this.toggleActive}
      />
    ) : null;
    const dateLang = this.state.dash_data.date_lang;
    const contentStatus = this.state.is_enabled ? "Disable" : "Enable";
    const textStatus = this.state.is_enabled ? (
      <span className="enabled-status">enabled</span>
    ) : (
      "disabled"
    );

    return (
      <div className="et-dash">
        {this.state.isShow ? (
          <div className="saveBar">
            <AppProvider
              i18n={{
                Polaris: {
                  ContextualSaveBar: {
                    save: "Save",
                    discard: "Discard",
                  },
                },
              }}
            >
              <Frame>
                <ContextualSaveBar
                  message="Unsaved changes"
                  saveAction={{
                    onAction: this.saveDashSetting,
                    loading: this.state.save_is_load,
                    disabled: false,
                  }}
                  discardAction={{
                    onAction: this.cancelDashSettingChange,
                  }}
                />
              </Frame>
            </AppProvider>
          </div>
        ) : null}

        {this.state.is_load === false ? (
          <div>
            {this.state.is_show_product_limit_bannber === 1 ?
              (
                <div className="et-dash-product-limit-banner">
                  {
                    this.state.plans == 1 || this.state.plans == 11
                      ?
                      <Banner
                        title={<h1>You have reached the Premium version <Link url="faq">product limit</Link>, please upgrade to the Pro version to avoid new products not showing ETA message.</h1>}
                        status="warning"
                      // onDismiss={this.deleteProductLimitBannerChange}
                      ></Banner>
                      :
                      <Banner
                        title={<h1>You have reached the FREE version <Link url="faq">product limit</Link>, please upgrade your plan to avoid new products not showing ETA message.</h1>}
                        status="warning"
                      // onDismiss={this.deleteProductLimitBannerChange}
                      ></Banner>
                  }

                </div>
              ) : null}

            {
              // this.state.is_show_faq === 1
              // ?

              <div className="et-faq-contact-us-banner">
                <div className="et-dash-banner">
                  <Banner
                    status="info"
                  >
                    <div className='banner-title'>
                      <span className='icon'><CommentOutlined /></span>
                      <span className='title-text'>Would you mind let us know what you think about this app?</span>
                      <Rate value={this.state.star_v} onChange={this.changeStar} />
                    </div>

                    <p>Each <b>positive review</b> helps us to focus on <b>app development</b> and keep <b>low price</b> for you.</p>
                  </Banner>
                </div>

                {/* <Banner
                  title="Contact us if you can't find the answers in the FAQ section, we're here to help."
                  status="info"
                  // onDismiss={this.deleteBannerChange}
                  action={{
                    content: "Contact support",
                    onAction: this.activeFeedbackChange,
                  }}
                  secondaryAction={{
                    content: "Check FAQ doc",
                    onAction: this.jumpFQAHandle,
                    external: "true",
                  }}
                /> */}
              </div>
              // :
              // null
            }
            <div className="et-dash-enabled">
              <MediaCard
                size="small"
                title={<div className="et-dash-enabled-center" ><div className="et-dash-enabled-center-b"><b>Show widget</b> </div><div className={'badge'}>
                  {
                    this.state.is_enabled
                      ?
                      <Badge status="success">{'Published'}</Badge>
                      :
                      <Badge>{'Unpublished'}</Badge>
                  }
                </div>
                  <div className="et-dash-enabled-center-switch">
                    <Switch checkedChildren={""} unCheckedChildren={""}
                      checked={this.state.is_enabled}
                      onChange={() => { this.handleToggle(this, this.state.is_enabled) }} /></div></div>}
                // primaryAction={{
                //   content: 'Learn how to do it',
                //   onAction: () => { },
                // }}
                description={<span className="et-dash-enabled-center-description"><span className="et-dash-enabled-center-description-v1">Show estimated delivery date widget</span><span className="et-dash-enabled-center-description-v3"><Checkbox label='Only apply ETA to those added into "Products"' checked={this.state.dash_data.is_show_p_c} onChange={() => { this.updateSaveOrDiscardComponent("isShowPC", [!this.state.dash_data.is_show_p_c]) }} /></span>
                  <span className="et-dash-enabled-center-description-v2"><Button onClick={this.activeShipingChange}>Learn how to do it</Button> &nbsp;&nbsp;&nbsp; <Link url={this.state.p_url} external={true}>
                    Preview
                  </Link></span>
                </span>}
              >
                <VideoThumbnail
                  videoLength={80}
                  thumbnailUrl="/image/business.png?width=1850"
                  onClick={this.activeShipingChange}
                />
              </MediaCard>
              {/* <SettingToggle
                action={{
                  content: contentStatus,
                  onAction: this.handleToggle.bind(this, this.state.is_enabled),
                }}
                enabled={this.state.is_enabled}
              >
                {this.state.is_enabled ? (
                  <div className="dash-is-enabled">
                    <span>
                      This setting is{" "}
                      <TextStyle variation="strong">{textStatus}</TextStyle>. You
                      can check your{" "}
                      <Link url={this.state.p_url} external={true}>
                        product page
                      </Link>{" "}
                      to see whether this works in your store.
                    </span>
                    <Checkbox label='Only apply ETA to those added into "Products"' checked={this.state.dash_data.is_show_p_c} onChange={() => { this.updateSaveOrDiscardComponent("isShowPC", [!this.state.dash_data.is_show_p_c]) }} />
                  </div>
                ) : (
                  <span>
                    This setting is{" "}
                    <TextStyle variation="strong">{textStatus}</TextStyle>.
                  </span>
                )}
              </SettingToggle> */}
            </div>

            <div className="et-dash-column">
              <EditSettings
                updateSaveOrDiscardComponent={this.updateSaveOrDiscardComponent}
                timeFormAts={this.state.timeformats}
                dateLangList={this.state.date_lang_list}
                dateLang={dateLang}
                content={this.state.content}
                positions={this.state.dash_data.positions}
                mode={this.state.dash_data.mode}
                initTimeH={this.state.dash_data.initTimeH}
                initTimeM={this.state.dash_data.initTimeM}
                initTimeF={this.state.dash_data.initTimeF}
                initOutOfStockMode={this.state.dash_data.initOutOfStockMode}
                initOutOfStockContent={
                  this.state.dash_data.initOutOfStockContent
                }
                initOutOfStockCustomContent={
                  this.state.dash_data.initOutOfStockCustomContent
                }
                OutOfStockStatus={
                  this.state.dash_data.OutOfStockStatus
                }
                determineTemplateId={this.state.dash_data.determine_template_id}
              />
              <Preview
                updateSaveOrDiscardComponent={this.updateSaveOrDiscardComponent}
                timeFormAts={this.state.timeformats}
                calendar={this.state.calendar}
                dateLang={dateLang}
                determineTemplateId={this.state.dash_data.determine_template_id}
                content={this.state.content}
                countryInfo={this.state.identity_country}
                positions={this.state.dash_data.positions}
                mode={this.state.dash_data.mode}
                pImageUrl={this.state.p_image_url}
                initTimeH={this.state.dash_data.initTimeH}
                initTimeM={this.state.dash_data.initTimeM}
                initTimeF={this.state.dash_data.initTimeF}
                timezone={this.state.timezone}
              />
            </div>
            <DeliveryTime
              updateSaveOrDiscardComponent={this.updateSaveOrDiscardComponent}
              dateFiler={this.state.dash_data.date_filer}
              deliveryTime={this.state.dash_data.range}
              weekday={this.state.dash_data.weekdays}
            />
            <Placement
              updateSaveOrDiscardComponent={this.updateSaveOrDiscardComponent}
              calendar={this.state.calendar}
              dateLang={dateLang}
              plans={this.state.plans}
              collectionL={this.state.collection_l}
              cartL={this.state.cart_l}
              cartDateT={this.state.cart_date_t}
              mode={this.state.dash_data.mode}
              isShowCart={this.state.dash_data.is_show_cart}
              isShowCollection={this.state.dash_data.is_show_collection}
            />
            <RecommendApp></RecommendApp>
            <Frame>{toastMarkup}</Frame>
          </div>
        ) : (
          <div className="et-dash-load">
            <SkeletonPage>
              <Layout>
                <Layout.Section>
                  <Card sectioned>
                    <SkeletonBodyText lines={2} />
                  </Card>
                  <div className="edit-setting">
                    <Card sectioned>
                      <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={19} />
                      </TextContainer>
                    </Card>
                    <Card sectioned>
                      <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={5} />
                      </TextContainer>
                    </Card>
                  </div>
                </Layout.Section>
                <Layout.Section secondary>
                  <Card>
                    <Card.Section>
                      <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={2} />
                      </TextContainer>
                    </Card.Section>
                    <Card.Section>
                      <SkeletonBodyText lines={9} />
                    </Card.Section>
                  </Card>
                </Layout.Section>
                <Layout.Section secondary>
                  <Card sectioned>
                    <SkeletonBodyText lines={6} />
                  </Card>
                </Layout.Section>
              </Layout>
            </SkeletonPage>
            <Frame>
              <Loading />
            </Frame>
          </div>
        )}

        <Modal
          // activator={activator}
          open={this.state.f_show}
          onClose={this.closeFeedbackChange}
          title="Send your feedback"
          primaryAction={{
            content: "Send",
            onAction: this.sendFeedbackEmail,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: this.closeFeedbackChange,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <div>
                <div>
                  Please tell us more, we will try the best to get better
                </div>
                <TextField
                  value={this.state.f_email_text}
                  onChange={this.handleEmailTextChange}
                  multiline={3}
                  placeholder="Edit your message here..."
                />
              </div>
              <div>
                <div>Your contact email</div>
                <TextField
                  value={this.state.f_email}
                  onChange={this.handleEmailChange}
                  placeholder="Edit your message here..."
                />
              </div>
            </TextContainer>
          </Modal.Section>
        </Modal>

        <Modal
          open={this.state.f_shiping}
          onClose={this.closeShiPinghange}
          title="How to how estimated delivery date widget on product page"
        >
          <div className="et-dash-product-shiping">
            <iframe width={'100%'}
              src="https://www.youtube.com/embed/f-n56_MsuBw"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen></iframe>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Dash;
