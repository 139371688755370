import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Admin from './pages/admin/admin';
import Fun from "./utils/fun";
import createApp from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";

/*
应用的根组件
 */
class App extends Component {
  // constructor(props) {
  //   super(props);
  //   createApp({
  //     apiKey: '92b99ce245731caa0a4c2ce3ca7055d5',
  //     host: 'ZmFsbHVzaW4ubXlzaG9waWZ5LmNvbS9hZG1pbg',
  //     forceRedirect: false,
  //   });
  // }
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route path='/' component={Admin}></Route>
          </Switch>
        </BrowserRouter></div>
    );
  }
}

export default App;
