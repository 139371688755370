/**主包*/
import React, { Component } from 'react';
import { Card, Stack, Button } from "@shopify/polaris";

/**样式*/
import './index.less';


class RecommendApp extends Component {

    render() {
        return (
            <div className='tracking-pro'>
                <Card sectioned title={<div className='title'>Try our other apps to help your business</div>}>
                    <div className='content'>
                        <div className='small-title'><span>Download it for Free today!</span></div>
                        <div className='content-action'>
                            <Stack alignment="center" spacing="extraLoose">
                                <Stack.Item fill>
                                    <Stack alignment="center">
                                        <a target="_blank" rel="noopener noreferrer" href='https://apps.shopify.com/self-faq?surface_detail=ETA'>
                                            <img
                                                className='img'
                                                src="https://cdn.shopify.com/s/files/applications/673d790b75b35b386731af3858a46f6c_200x200.png?height=84&width=84"
                                                alt="Self FAQ"
                                            />
                                        </a>
                                        <div className='text'>
                                            <div><a target="_blank" rel="noopener noreferrer" href='https://apps.shopify.com/self-faq?surface_detail=ETA' >Self FAQ</a></div>
                                            <div className='tip'>Provide FAQ, order tracking widget, Contact Form and WhatsApp live chat</div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Button onClick={() => {
                                        window.open("https://apps.shopify.com/self-faq?surface_detail=ETA", "blank");
                                    }}>View</Button>
                                </Stack.Item>
                            </Stack>
                        </div>
                        <div className='content-action'>
                            <Stack alignment="center" spacing="extraLoose">
                                <Stack.Item fill>
                                    <Stack alignment="center">
                                        <a target="_blank" rel="noopener noreferrer" href='https://apps.shopify.com/parcelpanel?surface_detail=ETA'>
                                            <img
                                                className='img'
                                                src="https://cdn.shopify.com/s/files/applications/49a7da2f5f1af407d002b15b55189273_200x200.png?height=84&quality=90&width=84"
                                                alt="ParcelPanel Order Tracking Pro"
                                            />
                                        </a>
                                        <div className='text'>
                                            <div><a target="_blank" rel="noopener noreferrer" href='https://apps.shopify.com/parcelpanel?surface_detail=ETA' >ParcelPanel Order Tracking Pro</a></div>
                                            <div className='tip'>Real-time order tracking, custom tracking page</div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Button onClick={() => {
                                        window.open("https://apps.shopify.com/parcelpanel?surface_detail=ETA", "blank");
                                    }}>View</Button>
                                </Stack.Item>
                            </Stack>
                        </div>
                        <div className='content-action'>
                            <Stack alignment="center" spacing="extraLoose">
                                <Stack.Item fill>
                                    <Stack alignment="center">
                                        <a target="_blank" rel="noopener noreferrer" href='https://apps.shopify.com/localiser?surface_detail=ETA'>
                                            <img
                                                className='img'
                                                src="https://cdn.shopify.com/s/files/applications/3c87b0179bad81cd5b2a0b6f0c595df1_200x200.png?height=84&width=84"
                                                alt="ETranslate - Language Translate"
                                            />
                                        </a>
                                        <div className='text'>
                                            <div><a target="_blank" rel="noopener noreferrer" href='https://apps.shopify.com/localiser?surface_detail=ETA' >Language Translate ‑ETranslate</a></div>
                                            <div className='tip'>Translate your store into multi languages</div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Button onClick={() => {
                                        window.open("https://apps.shopify.com/localiser?surface_detail=ETA", "blank");
                                    }}>View</Button>
                                </Stack.Item>
                            </Stack>
                        </div>
                    </div>
                </Card>
            </div>

        )
    }
}

export default RecommendApp;
