/** 主包 */
import React, { Component } from 'react';
import { Card, Link, FooterHelp } from "@shopify/polaris";
import './cookies-not-found.less';

class CookiesNotFound extends Component {
    render() {
        return (
            <div className="edt-cookies-not-found">
                <Card title="Enable cookies">
                    <Card.Section>
                        <p>You must <a href="https://www.google.com/search?q=enable+browser+cookies&oq=enable+browser+cookies&aqs=chrome..69i57j0l9.5983j0j15&sourceid=chrome" target="_blank">enable browser cookies</a> in order to access the app within Shopify.</p>
                    </Card.Section>

                    <Card.Section>
                        <p>
                            Cookies let the app authenticate you by temporarily storing your perferences and personal information. They expire after 30 days.
                        </p>
                    </Card.Section>
                </Card>
                <FooterHelp>
                    Need help? Contact us at{' '}
                    <a  onClick={()=>{window.open("mailto:estimated-delivery@uppercommerce.com", "_blank")}}>
                        estimated-delivery@uppercommerce.com.
                    </a>
                </FooterHelp>
            </div>
        )
    }
}

export default CookiesNotFound;