/** 主包 */
import React, { Component } from "react";
import {
  Card,
  TextStyle,
  Tooltip,
  Layout,
  SkeletonBodyText,
  Icon,
  EmptyState,
  DataTable,
  Thumbnail,
  Page,
  ButtonGroup,
  Button,
  Pagination,
  Frame,
  Loading,
  Toast,
  TextField,
  Modal,
  TextContainer,
  Spinner,
  Checkbox,
} from "@shopify/polaris";
import {
  SearchMinor,
  ChevronDownMinor,
  ChevronUpMinor,
  CancelSmallMinor,
  DeleteMinor,
  HorizontalDotsMinor,
  CircleCancelMinor,
} from "@shopify/polaris-icons";

/** 工具组件包 */
import storageUtils from "../../../../utils/storageUtils";
import { getProductCountryDeliveryTimeList } from "../../../../api/index";
import { deleteProductCountry } from "../../../../api/index";
import { createProductCountry } from "../../../../api/index";
import { getSeletCountry } from "../../../../api/index";
import { createOverallProductCountry } from "../../../../api/index";

/** 样式 */
import "./products-delivery-zones.less";
import { Stack } from "immutable";
import { Alert } from "antd";

class PorductsDeliveryZones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: {
        token: "",
        domain: "",
      },
      pId: props.pId,
      country: [],
      country_lists: [],
      country_delivery_time_lists: [],
      s_country_delivery_time_lists: [],
      country_delivery_time_lists_default: [],
      add_country_list_search_value: "",
      del_add_country_list_search_value_active: false,
      del_country_list_search_value_active: false,
      provinces_show_list: [],
      provinces_list: [],
      select_country: [],
      select_country_provinces: [],
      select_country_provinces_count: [],
      select_country_code_status: [],
      pagination: [],
      load_ing: false,
      zones_loading: false,
      modal: {
        isActive: false,
        cId: 0,
        cDeliveryTimeMin: 0,
        cDeliveryTimeMax: 0,
        type: "country",
      },
      country_modal: {
        isActive: false,
        value: "",
        checked: [],
      },
      value: "",
      bbb: "",
      _isRequestFinal: false,

      is_notify: false,
      notify_content: "",
    };
  }

  // 内存泄漏标志
  _isMounted = true;

  UNSAFE_componentWillMount() {
    // 监测本地是否存在用户核心参数，否则跳转 404
    var userData = storageUtils.get("users");
    if (userData === null) {
      // this.props.history.push('/notfound');
      window.location.href = "/notfound";
      return "";
    }

    let userInfo = this.state.users;
    userInfo.domain = userData.shop_domain;
    userInfo.token = userData.user_token;
    if (this._isMounted) {
      this.setState({ userInfo });
    }

    // 初始化数据
    this.initData(1);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isFlushData === true) {
      this.initData(1);
    }
    if (this._isMounted) {
      this.setState({
        country: nextProps.country,
        country_lists: nextProps.country,
        pId: nextProps.pId,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * 初始化数据
   */
  initData = async (currentPage) => {
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const param = {
      page: currentPage,
      pId: this.state.pId,
    };
    this.setState({
      load_ing: true,
    });
    // 国家预投递列表
    let countryListD = await getProductCountryDeliveryTimeList(headers, param);
    if (countryListD.code === 200) {
      if (this._isMounted) {
        this.setState({
          country_delivery_time_lists: countryListD.data.country_data,
          country_delivery_time_lists_default: countryListD.data.country_data,
          s_country_delivery_time_lists: countryListD.data.s_country_data,
          pagination: countryListD.data.page_data,
          load_ing: false,
        });
      }
    } else {
      // console.log(456);
    }

    if (this._isMounted) {
      this.setState({ _isRequestFinal: true });
    }
  };

  /**
   * 编辑国家预投递时间
   *
   * @param {*} cId  国家ID
   * @param {*} cDeliveryTimeMin  小值
   * @param {*} cDeliveryTimeMax  大值
   */
  editDateChange = (cId, cDeliveryTimeMin, cDeliveryTimeMax) => {
    let isModalData = this.state.modal;
    isModalData.isActive = true;
    isModalData.cId = cId;
    isModalData.cDeliveryTimeMin = cDeliveryTimeMin;
    isModalData.cDeliveryTimeMax = cDeliveryTimeMax;
    if (this._isMounted) {
      this.setState({ isModalData });
    }

    this.props.updateComponentByDeliveryZones(this.state.modal);
  };

  /**
   * 删除国家
   *
   * @param {*} cId
   */
  deleteCountryChange = async (cId) => {
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const params = {
      cId: cId,
      pId: this.state.pId,
    };
    let deleteData = await deleteProductCountry(headers, params);
    if (deleteData.code === 200) {
      this.setState({
        is_notify: true,
        notify_content: "Successfully deleted.",
      });
      let page = 1;
      if (this.state.pagination.next == false) {
        page = this.state.pagination.next_id;
      } else {
        page = this.state.pagination.next_id - 1;
      }
      this.initData(page);
    } else {
      this.setState({ is_notify: false, notify_content: "Failed deleted." });
      // console.log(456);
    }
  };
  /**
   * 点击展开省份
   * @param {*} value
   */
  blockProvinces = (value) => {
    // let a = this.state.provinces_show_list.splice(value);
    if (this.state.provinces_show_list.indexOf(value) >= 0) {
      let key = this.state.provinces_show_list.indexOf(value);
      this.state.provinces_show_list.splice(key, 1);
    } else {
      this.state.provinces_show_list.push(value);
    }
    this.setState({
      provinces_show_list: this.state.provinces_show_list,
    });
  };
  /**
   * 省份勾选选择
   * @param {*} country_code
   * @param {*} value
   */
  checkboxProvincesChange = (provinces, i, country_code, value) => {
    let countryModalData = this.state.country_modal;
    let provincesList = this.state.provinces_list;
    let selectCountryProvincesCount = this.state.select_country_provinces_count;

    if (typeof selectCountryProvincesCount[country_code] == "undefined") {
      selectCountryProvincesCount[country_code] = 0;
    }
    if (this.state.provinces_list.indexOf(value) >= 0) {
      let key = provincesList.indexOf(value);
      provincesList.splice(key, 1);
      let status = false;
      provinces.map((v, i) => {
        if (provincesList.indexOf(v.provinces_code) >= 0) {
          status = true;
        }
      });
      if (status == false) {
        countryModalData.checked[i] = { checked: false, code: country_code };
      }
      selectCountryProvincesCount[country_code] =
        parseInt(selectCountryProvincesCount[country_code]) - 1;
    } else {
      provincesList.push(value);
      countryModalData.checked[i] = { checked: true, code: country_code };
      selectCountryProvincesCount[country_code] =
        parseInt(selectCountryProvincesCount[country_code]) + 1;
    }
    this.setState({
      provincesList,
    });
  };
  /**
   * 国家勾选选择
   *
   * @param {*} cId
   * @param {*} i
   * @param {*} checked
   * @param {*} mark
   */
  checkboxCountryChange = (provinces, code, i, checked, mark) => {
    let countryModalData = this.state.country_modal;
    let provincesList = this.state.provinces_list;
    let selectCountryProvinces = this.state.select_country_provinces;
    let selectCountryProvincesCount = this.state.select_country_provinces_count;

    let count = 0;
    if (checked == true) {
      provinces.map((v, i) => {
        if (provincesList.indexOf(v.provinces_code) < 0) {
          if (selectCountryProvinces.indexOf(v.provinces_name) < 0) {
            provincesList.push(v.provinces_code);
            count = count + 1;
          }
        }
      });
      selectCountryProvincesCount[code] = count;
    } else {
      provinces.map((v, i) => {
        if (provincesList.indexOf(v.provinces_code) >= 0) {
          let key = provincesList.indexOf(v.provinces_code);
          provincesList.splice(key, 1);
        }
      });
      selectCountryProvincesCount[code] = count;
    }
    countryModalData.checked[i] = {
      checked: checked,
      code: checked === true ? code : 0,
    };
    if (this._isMounted) {
      this.setState({ countryModalData, provincesList });
    }
  };

  /**
   * 添加预投递Country
   */
  addCountry = async () => {
    let cCodes = [];
    let provincesList = this.state.provinces_list;

    this.state.country_modal.checked.map((v, i) => {
      let code = v.code;
      if (code !== 0) {
        cCodes[i] = code;
      }
      return "";
    });

    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const params = {
      country_code: cCodes,
      provinces_list: provincesList,
      pId: this.state.pId,
    };

    //预投递Country列表
    let addDataRes = await createProductCountry(headers, params);
    if (addDataRes.code === 200) {
      let countryModalData = this.state.country_modal;
      let pId = this.state.pId;
      countryModalData.isActive = false;
      if (this._isMounted) {
        this.setState({
          countryModalData,
          is_notify: true,
          notify_content: "Successfully added",
        });
      }
      this.initData(1);
    } else {
      this.setState({ is_notify: true, notify_content: "Failed added" });
      // console.log(456);
    }
  };

  /**
   * 批量添加全局预投递Country
   */
  addOverallCountry = async () => {
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const params = {
      pId: this.state.pId,
    };
    this.setState({
      load_ing: true,
    });
    //预投递Country列表
    let addDataRes = await createOverallProductCountry(headers, params);
    if (addDataRes.code === 200) {
      if (this._isMounted) {
        this.setState({
          is_notify: true,
          notify_content: "Successfully added",
        });
      }
      this.initData(1);
    } else {
      this.setState({ is_notify: true, notify_content: "Failed added" });
      // console.log(456);
    }
  };

  /**
   * 搜索列表country
   *
   * @param {*} newValue
   */
  searchListCountryChange = (newValue) => {
    if (this._isMounted) {
      this.setState({ add_country_list_search_value: newValue });
    }

    const filterRegex = new RegExp(newValue, "i");
    const resultOptions = this.state.country_delivery_time_lists.filter(
      (option) => option.country_name.match(filterRegex)
    );

    if (this._isMounted) {
      this.setState({
        country_delivery_time_lists: resultOptions,
      });
    }

    if (newValue.length === 0) {
      // this.initData(1);
      this.setState({
        country_delivery_time_lists:
          this.state.country_delivery_time_lists_default,
        del_add_country_list_search_value_active: false,
      });
    }

    if (newValue.length !== 0) {
      const filterRegex = new RegExp(newValue, "i");
      const resultOptions = this.state.s_country_delivery_time_lists.filter(
        (option) => option.country_name.match(filterRegex)
      );
      if (this._isMounted) {
        this.setState({
          country_delivery_time_lists: resultOptions,
          del_add_country_list_search_value_active: true,
        });
      }
    }
  };

  /**
   * 删除搜索 已添加Country输入框文本
   */
  deleteSearchAddCountryListText = () => {
    if (this._isMounted) {
      this.setState({
        add_country_list_search_value: "",
        country_delivery_time_lists:
          this.state.country_delivery_time_lists_default,
        del_add_country_list_search_value_active: false,
      });
    }
  };

  /**
   * 搜索country
   *
   * @param {*} newValue
   */
  searchCountryChange = (newValue) => {
    let countryModelData = this.state.country_modal;
    countryModelData.value = newValue;
    if (this._isMounted) {
      this.setState({ countryModelData });
    }

    const filterRegex = new RegExp(newValue, "i");
    const resultOptions = this.state.country.filter((option) =>
      option.title.match(filterRegex)
    );
    if (this._isMounted) {
      this.setState({
        country: resultOptions,
      });
    }

    if (newValue.length === 0) {
      // this.initData(1);
      this.setState({
        country: this.state.country_lists,
        del_country_list_search_value_active: false,
      });
    }

    if (newValue.length !== 0) {
      const filterRegex = new RegExp(newValue, "i");
      const resultOptions = this.state.country_lists.filter((option) =>
        option.title.match(filterRegex)
      );

      if (this._isMounted) {
        this.setState({
          country: resultOptions,
          del_country_list_search_value_active: true,
        });
      }
    }
  };

  /**
   * 删除搜索Country输入框文本
   */
  deleteSearchCountryListText = () => {
    let countryModelData = this.state.country_modal;
    countryModelData.value = "";
    if (this._isMounted) {
      this.setState({
        countryModelData,
        country: this.state.country_lists,
        del_country_list_search_value_active: false,
      });
    }
  };

  updateCountryDelvieryList = async (code) => {
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const params = {
      country_code: code[0],
      pId: this.state.pId,
    };
    let addDataRes = await createProductCountry(headers, params);
    if (addDataRes.code === 200) {
      this.setState({ is_notify: true, notify_content: "Successfully added" });
      this.initData(1);
    } else {
      this.setState({ is_notify: true, notify_content: "Failed added" });
      // console.log(456);
    }
  };

  /**
   * 开启country列表弹窗
   */
  activeCountryModal = async () => {
    let countryModalData = this.state.country_modal;

    countryModalData.isActive = true;
    countryModalData.checked = [];
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    this.setState({
      countryModalData,
      zones_loading:false,
    });
    const params = {};
    let getSelectCounrtyRes = await getSeletCountry(headers, params);
    if (getSelectCounrtyRes.code === 200) {
      this.setState({
        select_country_provinces_count: [],
        provinces_list: [],
        select_country_code_status:
          getSelectCounrtyRes.data.country_code_status,
        select_country: getSelectCounrtyRes.data.country,
        select_country_provinces: getSelectCounrtyRes.data.provinces,
        zones_loading:true,
      });
    } else {
      this.setState({ is_notify: true, notify_content: "Failed added" });
    }
  };

  /**
   * 关闭或取消country列表弹窗
   */
  closeOrCancelCountryModal = () => {
    let countryModalData = this.state.country_modal;
    countryModalData.isActive = false;
    if (this._isMounted) {
      this.setState({ countryModalData });
    }

    this.initData(1);
  };

  /**
   * 弹窗是否活跃
   */
  toggleActive = () => {
    if (this._isMounted) {
      this.setState({ is_notify: !this.state.is_notify });
    }
  };

  render() {
    const toastMarkup = this.state.is_notify ? (
      <Toast
        content={this.state.notify_content}
        onDismiss={this.toggleActive}
      />
    ) : null;
    let cdData = []; // 国家预投递时间数据
    let item = [];
    this.state.country_delivery_time_lists.map((v, i) => {
      item[0] = <Thumbnail source={v.cImgSrc} size="small" alt="" />;
      item[1] = (
        <div className="country-provinces">
          <span>{v.country_code}</span>
          {v.country_provinces_count != 0 ? (
            v.country_provinces_count == 1 ? (
              <Tooltip active={false} content={v.country_provinces}>
                <TextStyle variation="strong">
                  ({v.country_provinces})
                </TextStyle>
              </Tooltip>
            ) : (
              <Tooltip active={false} content={v.country_provinces}>
                <TextStyle variation="strong">
                  ({v.country_provinces_count} of {v.country_provinces_sum}{" "}
                  regions)
                </TextStyle>
              </Tooltip>
            )
          ) : (
            ""
          )}
        </div>
      );
      item[2] = v.cDeliveryTimeMin + "-" + v.cDeliveryTimeMax + " days";
      item[3] = (
        <ButtonGroup segmented>
          <Button
            onClick={this.editDateChange.bind(
              this,
              v.cId,
              v.cDeliveryTimeMin,
              v.cDeliveryTimeMax
            )}
          >
            Edit
          </Button>
          <Button onClick={this.deleteCountryChange.bind(this, v.cId)}>
            <Icon color="subdued" source={DeleteMinor} color="base" />
          </Button>
        </ButtonGroup>
      );

      cdData[i] = item;
      item = [];
      return "";
    });
    const countryListComponent = this.state.country.map((v, i) => {
      return (
        <div className="cuntry-group" key={i}>
          <Modal.Section key={i}>
            <TextContainer>
              <div className="delivery-cuntry">
                <div className="cuntry-name-group">
                  <Checkbox
                    label={
                      <div className="show-product-icon-description">
                        <Thumbnail source={v.cImgSrc} size="small" />
                        <span>{v.title}</span>
                      </div>
                    }
                    disabled={
                      typeof this.state.select_country_code_status[v.value] ==
                      "undefined"
                        ? false
                        : true
                    }
                    // checked={(this.state.product_modal.checked.length !== 0) ? this.state.product_modal.checked[i].checked : false}
                    checked={
                      typeof this.state.country_modal.checked[i] == "undefined"
                        ? false
                        : this.state.country_modal.checked[i].checked
                    }
                    onChange={this.checkboxCountryChange.bind(
                      this,
                      v.provinces,
                      v.value,
                      i
                    )}
                    key={v.value}
                  />
                </div>
                <div className="provinces-num-group">
                  {v.provinces.length > 0 ? (
                    <div
                      className="provinces-num"
                      onClick={this.blockProvinces.bind(this, v.value)}
                    >
                      <span>
                        {typeof this.state.select_country_provinces_count[
                          v.value
                        ] != "undefined"
                          ? this.state.select_country_provinces_count[v.value]
                          : 0}{" "}
                        of {v.provinces.length} regions
                      </span>
                      {this.state.provinces_show_list.indexOf(v.value) < 0 ? (
                        <Icon
                          color="subdued"
                          source={ChevronDownMinor}
                          color="base"
                        />
                      ) : (
                        <Icon
                          color="subdued"
                          source={ChevronUpMinor}
                          color="base"
                        />
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </TextContainer>
          </Modal.Section>
          {v.provinces.map((j, k) => {
            return (
              <div
                key={k}
                style={{
                  display:
                    this.state.provinces_show_list.indexOf(v.value) < 0
                      ? "none"
                      : "block",
                }}
              >
                <Modal.Section key={k}>
                  <div className="provinces-text">
                    <TextContainer>
                      <Checkbox
                        label={
                          this.state.select_country_provinces.indexOf(
                            j.provinces_name
                          ) < 0 ? (
                            <div className="show-provinces-name">
                              <span>{j.provinces_name}</span>
                            </div>
                          ) : (
                            <div className="show-provinces-name">
                              <span>{j.provinces_name}</span>
                              <span className="select-pro-text">
                                In another zone
                              </span>
                            </div>
                          )
                        }
                        disabled={
                          this.state.select_country_provinces.indexOf(
                            j.provinces_name
                          ) < 0
                            ? false
                            : true
                        }
                        // checked={(this.state.product_modal.checked.length !== 0) ? this.state.product_modal.checked[i].checked : false}
                        checked={
                          this.state.provinces_list.indexOf(j.provinces_code) <
                          0
                            ? false
                            : true
                        }
                        onChange={this.checkboxProvincesChange.bind(
                          this,
                          v.provinces,
                          i,
                          v.value,
                          j.provinces_code,
                          k
                        )}
                        key={v.value}
                      />
                    </TextContainer>
                  </div>
                </Modal.Section>
              </div>
            );
          })}
        </div>
      );
    });

    const deleteAddCountryListSearch = this.state
      .del_add_country_list_search_value_active ? (
      <Icon source={CircleCancelMinor} />
    ) : null;
    const deleteCountryListSearch = this.state
      .del_country_list_search_value_active ? (
      <Icon source={CircleCancelMinor} />
    ) : null;

    return (
      <div className="et-delivery-zones">
        <Layout>
          <Layout.Section>
            <Card
              title="Delivery zones"
              sectioned
              actions={[
                { content: "Add countries", onAction: this.activeCountryModal },
              ]}
            >
              {/* <div className="et-delivery-zones-search">
                                <AutocompleteExample country={this.state.country} updateCountryDelvieryList={this.updateCountryDelvieryList} />
                            </div> */}
              <div className="search-country">
                <TextField
                  value={this.state.add_country_list_search_value}
                  onChange={this.searchListCountryChange}
                  autoComplete={false}
                  prefix={<Icon source={SearchMinor} />}
                  suffix={
                    <div
                      className="delete-search-add-country-list-text"
                      onClick={this.deleteSearchAddCountryListText}
                    >
                      {deleteAddCountryListSearch}
                    </div>
                  }
                  placeholder="Search country"
                />
              </div>
              <div className="et-delivery-zones-list">
                {
                this.state.load_ing == true
                ?
                <Spinner accessibilityLabel="Spinner example" size="large" />
                :
                this.state._isRequestFinal === false ? (
                  <div>
                    <Card sectioned>
                      <SkeletonBodyText lines={15} />
                    </Card>
                    <Frame>
                      <Loading />
                    </Frame>
                  </div>
                ) : cdData.length !== 0 ? (
                  <div>
                    <DataTable
                      columnContentTypes={[
                        "text",
                        "numeric",
                        "numeric",
                        "numeric",
                        "numeric",
                      ]}
                      headings={[]}
                      rows={cdData}
                    />
                    {this.state
                      .del_add_country_list_search_value_active ? null : (
                      <div className="pagination">
                        <Pagination
                          onPrevious={this.initData.bind(
                            this,
                            this.state.pagination.prev_id
                          )}
                          onNext={this.initData.bind(
                            this,
                            this.state.pagination.next_id
                          )}
                          hasPrevious={this.state.pagination.prev}
                          hasNext={this.state.pagination.next}
                        />
                      </div>
                    )}
                  </div>
                ) : this.state.load_ing == false ? (
                  <EmptyState heading="No zones found">
                    <p>Set different date range for different countries</p>
                    <p>
                      <Button primary onClick={this.addOverallCountry}>
                        Add current countries
                      </Button>
                    </p>
                  </EmptyState>
                ) : null}
              </div>
            </Card>
          </Layout.Section>

          <Modal
            open={this.state.country_modal.isActive}
            onClose={this.closeOrCancelCountryModal}
            title="Add countries"
            primaryAction={{
              content: "Done",
              onAction: this.addCountry,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: this.closeOrCancelCountryModal,
              },
            ]}
          >
            <div className="search-products">
              <TextField
                value={this.state.country_modal.value}
                onChange={this.searchCountryChange}
                autoComplete={false}
                prefix={<Icon source={SearchMinor} />}
                suffix={
                  <div
                    className="delete-search-country-list-text"
                    onClick={this.deleteSearchCountryListText}
                  >
                    {deleteCountryListSearch}
                  </div>
                }
                placeholder="Search country"
              />
            </div>
            <div className="select-products">
              {this.state.zones_loading == true ?
               (
                <div className="select-products">{ countryListComponent }</div>
              ) : (
                <Spinner accessibilityLabel="Spinner example" size="large" />
              )}
            </div>
          </Modal>
        </Layout>
        <Frame>{toastMarkup}</Frame>
      </div>
    );
  }
}

export default PorductsDeliveryZones;
