/** 主包 */
import React, { Component } from "react";
import { Layout, Card } from "@shopify/polaris";

/** 工具包 */
import storageUtils from "../../../../utils/storageUtils";
import { dateConversion } from "../../../../utils/fun";
import { getLocalTime } from "../../../../utils/fun";
import { dateFormat } from "../../../../utils/fun";

import sampleProduct from "../../../../assets/img/sample_product.png";

/** 样式 */
import "./preview.less";

class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      postions: props.positions,
      content: "",
      identity_country: storageUtils.get("identity_country") | {},
      mode: props.mode,
      pImageUrl: props.pImageUrl,
      countdown_time: "",
      initTimeH: props.initTimeH || "0",
      initTimeM: props.initTimeM || "0",
      initTimeF: props.initTimeF || "0",
      timezone: props.timezone || "+08",
      dateLang: props.dateLang || "en",
      calendar: props.calendar || {},
      timeFormAts: props.timeFormAts || [],
      determineTemplateId: props.determineTemplateId || 1,
    };
  }

  // 内存泄漏标志
  _isMounted = true;

  UNSAFE_componentWillMount() {
    if (this._isMounted) {
      this.setState({
        countdown_time: this.countTimeHandle(
          this.state.timezone,
          this.state.initTimeH,
          this.state.initTimeM
        ),
      });
    }
  }

  componentDidMount() {
    // 时间格式
    this.timer = setInterval(() => {
      if (this._isMounted) {
        this.setState({
          countdown_time: this.countTimeHandle(
            this.state.timezone,
            this.state.initTimeH,
            this.state.initTimeM
          ),
        });
      }
    }, 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this._isMounted) {
      this.setState({
        postions: nextProps.positions,
        content: nextProps.content,
        dateLang: nextProps.dateLang,
        determineTemplateId: nextProps.determineTemplateId,
        calendar: nextProps.calendar,
        identity_country: nextProps.countryInfo,
        mode: nextProps.mode,
        pImageUrl: nextProps.pImageUrl,
        initTimeH: nextProps.initTimeH,
        initTimeM: nextProps.initTimeM,
        initTimeF: nextProps.initTimeF,
        timezone: nextProps.timezone,
      });
    }
  }

  componentWillUnmount() {
    this.timer && clearInterval(this.timer);
    this._isMounted = false;
  }

  calcDate(date, days, seperator = "-") {
    let oDate = new Date(date).valueOf();
    let nDate = oDate + days * 24 * 3600 * 1000;
    nDate = new Date(nDate);
    let y = nDate.getFullYear().toString().padStart(2, 0);
    let m = (nDate.getMonth() + 1).toString().padStart(2, 0);
    let d = nDate.getDate().toString().padStart(2, 0);
    return `${y}${seperator}${m}${seperator}${d}`;
  }

  /**
   * 将0-9的数字前面加上0，例1变为01
   *
   * @param {*} i
   */
  checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  countTimeHandle = (i, timeH, timeM) => {
    //获取当前时区时间
    let currentDate = getLocalTime(i);
    let currentTimestamp = currentDate.getTime();

    //设置截止时间
    let countDownDate =
      this.calcDate(currentDate, 0, "/") + " " + timeH + ":" + timeM + ":00";
    let countDownTimestamp = new Date(countDownDate).getTime();
    let timeFormAts = this.state.timeFormAts;
    //时间差
    let differenceTime = countDownTimestamp - currentTimestamp;
    //定义变量 d,h,m,s保存倒计时的时间
    let h = "00",
      m = "00",
      s = "00";
    const dateLang = this.state.dateLang;
    const timeFormAtsApi = this.state.timeFormAts;

    if (differenceTime >= 0) {
      // d = checkTime(Math.floor(differenceTime/1000/60/60/24));
      h = this.checkTime(Math.floor((differenceTime / 1000 / 60 / 60) % 24));
      m = this.checkTime(Math.floor((differenceTime / 1000 / 60) % 60));
      s = this.checkTime(Math.floor((differenceTime / 1000) % 60));
    } else {
      // d = checkTime(Math.floor((differenceTime+24*60*60*1000)/1000/60/60/24));
      h = this.checkTime(
        Math.floor(
          ((differenceTime + 24 * 60 * 60 * 1000) / 1000 / 60 / 60) % 24
        )
      );
      m = this.checkTime(
        Math.floor(((differenceTime + 24 * 60 * 60 * 1000) / 1000 / 60) % 60)
      );
      s = this.checkTime(
        Math.floor(((differenceTime + 24 * 60 * 60 * 1000) / 1000) % 60)
      );
    }
    let countTime = "";
    if (typeof timeFormAtsApi[dateLang] != "undefined") {
      switch (parseInt(this.state.initTimeF)) {
        case 0:
          countTime =
            h +
            " " +
            timeFormAtsApi[dateLang][0] +
            " " +
            m +
            " " +
            timeFormAtsApi[dateLang][1] +
            " " +
            s +
            " " +
            timeFormAtsApi[dateLang][2];
          break;
        case 1:
          countTime =
            h +
            " " +
            timeFormAtsApi[dateLang][0] +
            " " +
            m +
            " " +
            timeFormAtsApi[dateLang][1];
          break;
        case 2:
          countTime =
            h +
            " " +
            timeFormAtsApi[dateLang][3] +
            " " +
            m +
            " " +
            timeFormAtsApi[dateLang][4] +
            " " +
            s +
            " " +
            timeFormAtsApi[dateLang][5];
          break;
        case 3:
          countTime =
            h +
            " " +
            timeFormAtsApi[dateLang][3] +
            " " +
            m +
            " " +
            timeFormAtsApi[dateLang][4];
          break;
        case 4:
          countTime =
            h +
            " " +
            timeFormAtsApi[dateLang][6] +
            " " +
            m +
            " " +
            timeFormAtsApi[dateLang][7] +
            " " +
            s +
            " " +
            timeFormAtsApi[dateLang][8];
          break;
        case 5:
          countTime =
            h +
            " " +
            timeFormAtsApi[dateLang][9] +
            " " +
            m +
            " " +
            timeFormAtsApi[dateLang][10] +
            " " +
            s +
            " " +
            timeFormAtsApi[dateLang][11];
          break;
        default:
          countTime =
            h +
            " " +
            timeFormAtsApi[dateLang][0] +
            " " +
            m +
            " " +
            timeFormAtsApi[dateLang][1] +
            " " +
            s +
            " " +
            timeFormAtsApi[dateLang][2];
          break;
      }
    } else {
      switch (parseInt(this.state.initTimeF)) {
        case 0:
          countTime = h + " Hrs " + m + " Mins " + s + " Secs";
          break;
        case 1:
          countTime = h + " Hrs " + m + " Mins";
          break;
        case 2:
          countTime = h + " Hours " + m + " Minutes " + s + " Seconds";
          break;
        case 3:
          countTime = h + " Hours " + m + " Minutes";
          break;
        case 4:
          countTime = h + " hours " + m + " minutes " + s + " seconds";
          break;
        case 5:
          countTime = h + " H " + m + " M " + s + " S";
          break;
        default:
          countTime = h + " Hrs " + m + " Mins " + s + " Secs";
          break;
      }
    }

    if (h === "00" && m === "00" && (s === "00" || s === "59" || s === "58")) {
      if (this._isMounted) {
        this.props.updateSaveOrDiscardComponent("countdown");
      }
    }

    return countTime;
  };

  render() {
    const position_style = {
      paddingTop: this.state.postions[0] + "px",
      paddingRight: this.state.postions[3] + "px",
      paddingBottom: this.state.postions[1] + "px",
      paddingLeft: this.state.postions[2] + "px",
    };
    let countryName = storageUtils.get("identity_country")
      ? storageUtils.get("identity_country").country_name
      : this.state.identity_country.country_name;
    let countryCode = storageUtils.get("identity_country")
      ? storageUtils.get("identity_country").country_code
      : this.state.identity_country.country_code;
    let state = storageUtils.get("identity_country")
      ? storageUtils.get("identity_country").state != null
        ? storageUtils.get("identity_country").state
        : ""
      : this.state.identity_country.state;
    let dateLang = this.state.dateLang;
    let calendar = this.state.calendar;
    // let deliveryTimeMin = (storageUtils.get('identity_country')) ? storageUtils.get('identity_country').delivery_date_min : this.state.identity_country.delivery_date_min;
    // let deliveryTimeMax = (storageUtils.get('identity_country')) ? storageUtils.get('identity_country').delivery_date_max : this.state.identity_country.delivery_date_max;

    let deliveryDateMin = storageUtils.get("identity_country")
      ? storageUtils.get("identity_country").delivery_date_l
      : this.state.identity_country.delivery_date_l;
    let deliveryDateMax = storageUtils.get("identity_country")
      ? storageUtils.get("identity_country").delivery_date_r
      : this.state.identity_country.delivery_date_r;
    let htmlContent = this.state.content.replace(/{country}/gi, countryName);
    htmlContent = htmlContent.replace(/{region}/gi, state);

    htmlContent = htmlContent.replace(
      /{flag}/gi,
      '<img style="width: 14px;height: 11px;border-radius: 2px;" src="http://edtadmin.uppercommerce.com/assets/common/images/flags/' +
        countryCode +
        '.png" />'
    );
    // htmlContent = htmlContent.replace(/{delivery_date_min}/ig, deliveryTimeMin)
    // htmlContent = htmlContent.replace(/{delivery_date_max}/ig, deliveryTimeMax)
    if (
      typeof deliveryDateMin != "undefined" &&
      typeof deliveryDateMax != "undefined"
    ) {
      var num1 = this.state.countdown_time.replace(/[^\d]/g,' ');
      var p = num1.replace(/\s/g,"");
      if(p == '000000'){
        deliveryDateMin=new Date(deliveryDateMin);
        deliveryDateMin=deliveryDateMin.setDate(deliveryDateMin.getDate()+1);
        deliveryDateMin=new Date(deliveryDateMin);
        deliveryDateMin = dateFormat("YYYY-mm-dd", deliveryDateMin)
        
        deliveryDateMax=new Date(deliveryDateMax);
        deliveryDateMax=deliveryDateMax.setDate(deliveryDateMax.getDate()+1);
        deliveryDateMax=new Date(deliveryDateMax);
        deliveryDateMax = dateFormat("YYYY-mm-dd", deliveryDateMax);
        let countryData_D = {
          delivery_date_l: deliveryDateMin,
          delivery_date_r: deliveryDateMax,
        };
        storageUtils.set("identity_country", countryData_D);
      }
      let dateRes = dateConversion(
        deliveryDateMin,
        deliveryDateMax,
        String(this.state.mode),
        dateLang,
        calendar
      );
      htmlContent = htmlContent.replace(/{delivery_date_min}/gi, dateRes.d_min);
      htmlContent = htmlContent.replace(/{delivery_date_max}/gi, dateRes.d_max);
    }
    htmlContent = htmlContent.replace(/{timer}/gi, this.state.countdown_time);

    let replaceContent = "left;";
    if (RegExp(/text-align:*/).test(htmlContent)) {
      if (htmlContent.match(/text-align:(.*);/i) !== null) {
        if (
          htmlContent.match(/text-align:center;/i) ||
          htmlContent.match(/text-align: center;/i)
        ) {
          replaceContent = "center;";
        } else if (
          htmlContent.match(/text-align:right;/i) ||
          htmlContent.match(/text-align: right;/i)
        ) {
          replaceContent = "right;";
        } else if (
          htmlContent.match(/text-align:justify;/i) ||
          htmlContent.match(/text-align: justify;/i)
        ) {
          replaceContent = "justify;";
        } else {
          replaceContent = "left;";
        }
      }
    }

    var re = new RegExp('<p style="text-align:' + replaceContent + '">', "gi");
    htmlContent = htmlContent.replace(re, "");
    var re_space = new RegExp(
      '<p style="text-align: ' + replaceContent + '">',
      "gi"
    );
    htmlContent = htmlContent.replace(re_space, "");
    htmlContent = htmlContent.replace("<p>", "");
    htmlContent = htmlContent.replace(/<\/p>/g, "");
    htmlContent =
      "<div style='text-align:" +
      replaceContent +
      "white-space:break-spaces;'>" +
      htmlContent +
      "</div>";
    if (this.state.determineTemplateId == 2) {
      htmlContent =
        "<div id='template_2' style='margin-top: 10px;padding:10px; border: 2px solid #797979; '>" +
        htmlContent +
        "</div>";
    }
    
    if(this.state.determineTemplateId == 3){
        htmlContent =
        "<div id='template_3' style='margin-top: 10px;padding:10px; border: 2px solid #BCBCBC; '>" +
        htmlContent +
        "</div>";
    }

    if(this.state.determineTemplateId == 4){
        htmlContent = htmlContent.replaceAll('background-color: rgb(255,255,255);', "");
        htmlContent =
        "<div id='template_4' style='margin-top: 10px;padding:10px; background-color: #CCCC66; border-radius: 5px;'>" +
        htmlContent +
        "</div>";
    }

    if(this.state.determineTemplateId == 5){
        htmlContent = htmlContent.replaceAll('background-color: rgb(255,255,255);', "");
        htmlContent =
        "<div id='template_5' style='margin-top: 10px;padding:10px; background-color: #99CCCC; border-radius: 5px;'>" +
        htmlContent +
        "</div>";
    }
    return (
      <div className="et-dash-preview">
        <Layout>
          <Layout.Section>
            <Card title="Preview" sectioned>
              {/* <div dangerouslySetInnerHTML={{__html:this.state.content}}></div> */}
              <div className="preview-image">
                <img src={sampleProduct} alt="Demo"></img>
                <div className="product-name">
                  <span>
                    <strong>Product Demo</strong>
                  </span>
                </div>
                <div className="product-add-btn">
                  <span>Add to cart</span>
                </div>
              </div>

              <div
                className="preview"
                style={position_style}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              ></div>
            </Card>
          </Layout.Section>
        </Layout>
      </div>
    );
  }
}

export default Preview;
