/** 主包 */
import React, { Component } from "react";
import {
  Card,
  Layout,
  Heading,
  Badge,
  ButtonGroup,
  TextStyle,
  Page,
  Button,
  List,
  Stack,
  DisplayText,
  Caption,
  SkeletonBodyText,
  Frame,
  Loading,
  Banner,
  Modal,
  TextContainer,
  TextField,
  Toast
} from "@shopify/polaris";
import { Rate } from 'antd';
import { feedback } from '../../api/index';
import { selectPlanPraise } from '../../api/index';

/** 工具组件报 */
import storageUtils from "../../utils/storageUtils";
import { API_DOMAIN } from "../../utils/constants";
import { getPlan } from "../../api/index";

/** 样式 */
import "./index.less";

class Plan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: {
        token: "",
        domain: "",
      },
      current_plan: 1,
      trial_day: 0,
      is_finish: 1,
      date_status: 1,
      is_free_plan_active: false,
      star_v: 5,
      f_show: false,
      f_email_text: '',
      f_email: '',
      
      is_notify: false,
      notify_content: ''
    };
  }

  // 内存泄漏标志
  _isMounted = true;
  UNSAFE_componentWillMount() {
    // 监测本地是否存在用户核心参数，否则跳转 404
    var userData = storageUtils.get("users");

    if (userData === null) {
      this.props.history.push("/notfound");
      return "";
    }

    let userInfo = this.state.users;
    userInfo.domain = userData.shop_domain;
    userInfo.token = userData.user_token;
    if (this._isMounted) {
      this.setState({ userInfo });
    }

    // 初始化数据
    this.initData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * 初始化数据
   */
  initData = async () => {
    this.setState({ is_finish: 0 });
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };

    // 获取试用期
    let getPlanRes = await getPlan(headers);
    if (getPlanRes.code === 200) {
      if (this._isMounted) {
        this.setState({
          current_plan: getPlanRes.data.plan,
          trial_day: getPlanRes.data.trial_day,
          is_finish: 1,
        });
      }
    }
  };

  selectPlan = (plan) => {
    let redirectUrl;
    redirectUrl =
      API_DOMAIN +
      "/select_plan/" +
      plan +
      "---" +
      this.state.users.domain +
      "---" +
      this.state.users.token;
    window.open(redirectUrl, "_self");
    return;
  };

  selectPlanPraiseChange = async (plan) => {
    // if (this._isMounted) {
    //   this.setState({ is_free_plan_active: true });
    // }
    const headers = {
      'token': this.state.users.token,
      'domain': this.state.users.domain
    }
    const param = {
      'plan':plan
    }
    let selectPlanPraiseReu = await selectPlanPraise(headers, param);
    if (selectPlanPraiseReu.code === 200) {
      if (this._isMounted) {
        this.setState({ is_free_plan_active: true, current_plan: plan });
      }
    }
  }

  /**
        * 星星评价
        * @param {*} v 
        */
   changeStar = async (v) => {
    // const headers = {
    //   'token': this.state.users.token,
    //   'domain': this.state.users.domain
    // }
    // const param = {
    //   'plan':5
    // }
    // let selectPlanPraiseReu = await selectPlanPraise(headers, param);
    // if (selectPlanPraiseReu.code === 200) {
    //   if (this._isMounted) {
    //     this.setState({ current_plan: 5 });
    //   }
      if (v === 0) v = this.state.star_v;
      if (this._isMounted) {
          this.setState({ star_v: v });
      }
      if (v < 5) {
          if (this._isMounted) {
              this.setState({ f_show: true, is_free_plan_active:false });
          }
      } else {
        if (this._isMounted) {
          this.setState({ is_free_plan_active:false });
        }
          window.open("https://apps.shopify.com/estimated-delivery-time-pro?#modal-show=ReviewListingModal", "_blank")
      }
    }  

  handleEmailTextChange = (v) => {
    if (this._isMounted) {
        this.setState({ f_email_text: v });
    }
  }

  handleEmailChange = (v) => {
    if (this._isMounted) {
        this.setState({ f_email: v });
    }
  }

  sendFeedbackEmail = async () => {
    if ((this.state.f_email.length === 0) || (this.state.f_email_text === 0)) {
        this.setState({
            is_notify: true,
            notify_content: 'Incorrect message format'
        });
        return;
    }

    if ((this.state.f_email.indexOf("@") === -1) || (this.state.f_email.indexOf(".") === -1)) {
        this.setState({
            is_notify: true,
            notify_content: 'Incorrect message format'
        });
        return;
    }
    const headers = {
        'token': this.state.users.token,
        'domain': this.state.users.domain
    }
    const param = {
        'from_email': this.state.f_email,
        'content': this.state.f_email_text,
        'star_num': 0
    }

    // feedback 结果
    let feedbackRes = await feedback(headers, param);
    if (feedbackRes.code === 200) {
        this.setState({
            is_notify: true,
            notify_content: 'Mail sent successfully.',
            f_show: false,
            is_free_plan_active:false
        });
    }
    else if (feedbackRes.status === 6101) {
        this.setState({
            is_notify: true,
            notify_content: feedbackRes.msg
        });
    }
    else {
        this.setState({
            is_notify: true,
            notify_content: 'Failed to send mail.'
        });
    }
  }

  closeFeedbackChange = () => {
    if (this._isMounted) {
        this.setState({ f_show: false });
    }
  }

  closePlansChange = () => {
    if (this._isMounted) {
        this.setState({ is_free_plan_active: false });
    }
  }

   /**
     * 弹窗是否活跃
     */
  toggleActive = () => {
      if (this._isMounted) {
          this.setState({ is_notify: !this.state.is_notify });
      }
  }

  render() {
    const toastMarkup = this.state.is_notify ? (
      <Toast content={this.state.notify_content} onDismiss={this.toggleActive} />
    ) : null;
    return (
      <div className="et-plan">
        <Page>
          <Banner title="Tips">
            <div>
              1. Customers who ordered our app before January 28, 2020, can
              continue to use the Standard Plan. We will still display ETA
              information for all previous products for your stores. But if you
              need to add more than 1,000 new products, you need to choose the
              Pro Plan.
            </div>
            <div>
              2. You will not be able to downgrade after upgrading your plan,
              please choose the plan carefully.
            </div>
            <div>
              3. More products consume more server resources, which is why we
              have added this new plan to ensure that we can provide more stable
              services.
            </div>
          </Banner>
          <div className="plan-switch">
            <Stack.Item fill>
              <Heading>Choose your subscription plan</Heading>
            </Stack.Item>
            {/* <Stack.Item>
              <span>Switch to annual plan</span>
            </Stack.Item>
            <ButtonGroup segmented>
              <Button
                pressed={this.state.date_status == 1 ? true : false}
                onClick={() => {
                  this.setState({ date_status: 1 });
                }}
              >
                Monthly
              </Button>
              <Button
                pressed={this.state.date_status == 2 ? true : false}
                onClick={() => {
                  this.setState({ date_status: 2 });
                }}
              >
                Yearly
              </Button>
            </ButtonGroup> */}
          </div>
          {this.state.date_status == 1 ? (
            <Layout>
              <Layout.Section oneThird>
                {this.state.is_finish === 0 ? (
                  <div>
                    <Card sectioned>
                      <SkeletonBodyText lines={16} />
                    </Card>
                    <Frame>
                      <Loading />
                    </Frame>
                  </div>
                ) : (
                  <Card>
                    <Card.Section>
                      <Stack alignment="center">
                        <Stack.Item fill>
                          <DisplayText size="small" element="h1">
                           <label className='plans-package-title'> FREE </label>
                          </DisplayText>
                        </Stack.Item>
                        <Stack.Item>
                          <DisplayText size="large"><label className="plans-free">$0.00/m</label></DisplayText>
                        </Stack.Item>
                      </Stack>
                    </Card.Section>
                    <Card.Section title="Features">
                      <List type="bullet">
                        <List.Item>
                          <TextStyle variation="">
                            Unlimited times of view
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">
                            Manage 100 products
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">
                            Experience basic features
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">
                            Includes future updates
                          </TextStyle>
                        </List.Item>
                      </List>
                      <br />

                      {this.state.current_plan === 0 ? (
                        <div className="plan-button-margin" >
                        <Button disabled fullWidth>
                          Current plan
                        </Button>
                        </div>
                      ) : (
                        <div className="plan-button-margin" >
                        <Button
                          primary
                          fullWidth
                          onClick={this.selectPlan.bind(this, 0)}
                        >
                          Select
                        </Button>
                        </div>
                      )}
                    </Card.Section>
                  </Card>
                )}
              </Layout.Section>
              <Layout.Section oneThird>
                {this.state.is_finish === 0 ? (
                  <div>
                    <Card sectioned>
                      <SkeletonBodyText lines={16} />
                    </Card>
                    <Frame>
                      <Loading />
                    </Frame>
                  </div>
                ) : (
                  <div className="plan-free-tu">
                  <Card>
                    <Card.Section>
                      <img className="plan-sanjiao" src="/image/sanjiao.png"></img> 
                      <Stack alignment="center">
                        <Stack.Item fill>
                          <DisplayText size="small" element="h1">
                           <label className='plans-package-title'> Standard</label>
                          </DisplayText>
                        </Stack.Item>
                        <Stack.Item>
                          <DisplayText size="large">$0.00/m</DisplayText>
                          <DisplayText size="large"><label className="plans-discount-price">$2.99/m</label></DisplayText>
                        </Stack.Item>
                      </Stack>
                    </Card.Section>
                    <Card.Section title="Features">
                      <List.Item>
                        <TextStyle variation="">
                          Unlimited times of view
                        </TextStyle>
                      </List.Item>
                      <List type="bullet">
                        <List.Item>
                          <TextStyle variation="">
                            Manage 150 products
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">
                            On Cart/Check out page
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">
                            On order confirmation email
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">
                            Includes future updates
                          </TextStyle>
                        </List.Item>
                      </List>
                      <br />

                      {this.state.current_plan === 5 ? (
                        <Button disabled fullWidth>
                          Current plan
                        </Button>
                      ) : (
                        <Button
                          primary
                          fullWidth
                          onClick={this.selectPlanPraiseChange.bind(this, 5)}
                        >
                          $0.00 upgrade to paid version
                        </Button>
                      )}
                    </Card.Section>
                  </Card>
                  </div>
                )}
              </Layout.Section>
              <Layout.Section oneThird>
                {this.state.is_finish === 0 ? (
                  <div>
                    <Card sectioned>
                      <SkeletonBodyText lines={16} />
                    </Card>
                    <Frame>
                      <Loading />
                    </Frame>
                  </div>
                ) : (
                  <Card>
                    <Card.Section>
                    <Stack alignment="center">
                        <Stack.Item fill>
                          <DisplayText size="small" element="h1">
                           <label className='plans-package-title'> Premium </label>
                          </DisplayText>
                        </Stack.Item>
                        <Stack.Item>
                          <DisplayText size="large"><label className="plans-free">$3.99/m</label></DisplayText>
                        </Stack.Item>
                      </Stack>
                    </Card.Section>
                    <Card.Section title="Features">
                      <List type="bullet">
                        <List.Item>
                          <TextStyle variation="">
                            Unlimited times of view
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">
                            Manage 1000 products
                          </TextStyle>
                        </List.Item>
                        <div className="plan-maring"> 
                        <List.Item>
                          <TextStyle variation="">
                            Access all features
                          </TextStyle>
                        </List.Item>
                        </div>
                      </List>
                      <br />
                      {this.state.current_plan === 1 ? (
                        <div className="plan-button-margin" >
                        <Button disabled fullWidth>
                          Current plan
                        </Button>
                        </div>
                      ) : (
                        <div className="plan-button-margin" >
                        <Button
                        primary
                        fullWidth
                        onClick={this.selectPlan.bind(this, 1)}
                      >
                        Select
                        </Button>
                        </div>
                      )}
                    </Card.Section>
                  </Card>
                )}
              </Layout.Section>
              <Layout.Section oneThird>
                {this.state.is_finish === 0 ? (
                  <div>
                    <Card sectioned>
                      <SkeletonBodyText lines={16} />
                    </Card>
                    <Frame>
                      <Loading />
                    </Frame>
                  </div>
                ) : (
                  <Card>
                    <Card.Section>
                    <Stack alignment="center">
                        <Stack.Item fill>
                          <DisplayText size="small" element="h1">
                           <label className='plans-package-title'> Pro </label>
                          </DisplayText>
                        </Stack.Item>
                        <Stack.Item>
                          <DisplayText size="large"><label className="plans-free">$7.99/m</label></DisplayText>
                        </Stack.Item>
                      </Stack>
                    </Card.Section>
                    <Card.Section title="Features">
                      <List type="bullet">
                        <List.Item>
                          <TextStyle variation="">
                            Unlimited times of view
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">Unlimited products</TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">
                            Access all features
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">Priority support</TextStyle>
                        </List.Item>
                      </List>
                      <br />
                      {this.state.current_plan === 2 ? (
                        <div className="plan-button-margin" >
                        <Button disabled fullWidth>
                          Current plan
                        </Button>
                        </div>
                      ) : (
                        <div className="plan-button-margin" >
                        <Button
                          primary
                          fullWidth
                          onClick={this.selectPlan.bind(this, 2)}
                        >
                          Select
                        </Button>
                        </div>
                      )}
                    </Card.Section>
                  </Card>
                )}
              </Layout.Section>
            </Layout>
          ) : (
            <Layout>
              <Layout.Section oneThird>
                {this.state.is_finish === 0 ? (
                  <div>
                    <Card sectioned>
                      <SkeletonBodyText lines={16} />
                    </Card>
                    <Frame>
                      <Loading />
                    </Frame>
                  </div>
                ) : (
                  <Card>
                    <Card.Section>
                      <Stack alignment="center">
                        <Stack.Item fill>
                          <DisplayText size="small" element="h1">
                          <label className='plans-package-title'>Premium</label>
                          </DisplayText>
                        </Stack.Item>
                        <Stack.Item>
                          <DisplayText size="large">$39.9/y</DisplayText>
                        </Stack.Item>
                      </Stack>
                    </Card.Section>
                    <Card.Section title="Features">
                      <List type="bullet">
                        <List.Item>
                          <TextStyle variation="">
                            Unlimited times of view
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">
                            Manage 1000 products
                          </TextStyle>
                        </List.Item>
                        <div className="plan-maring"> 
                        <List.Item>
                          <TextStyle variation="">
                            Access all features
                          </TextStyle>
                        </List.Item>
                        </div>
                      </List>
                      <br />

                      {this.state.current_plan === 11 ? (
                        <Button disabled fullWidth>
                          Current plan
                        </Button>
                      ) : (
                        <Button
                          primary
                          fullWidth
                          onClick={this.selectPlan.bind(this, 11)}
                        >
                          Select
                        </Button>
                      )}
                    </Card.Section>
                  </Card>
                )}
              </Layout.Section>
              <Layout.Section oneThird>
                {this.state.is_finish === 0 ? (
                  <div>
                    <Card sectioned>
                      <SkeletonBodyText lines={16} />
                    </Card>
                    <Frame>
                      <Loading />
                    </Frame>
                  </div>
                ) : (
                  <Card>
                    <Card.Section>
                      <Stack alignment="center">
                        <Stack.Item fill>
                          <DisplayText size="small"><label className='plans-package-title'>Pro</label></DisplayText>
                        </Stack.Item>
                        <Stack.Item>
                          <DisplayText size="large">$79.9/y</DisplayText>
                        </Stack.Item>
                      </Stack>
                    </Card.Section>
                    <Card.Section title="Features">
                      <List type="bullet">
                      <List.Item>
                        <TextStyle variation="">
                          Unlimited times of view
                        </TextStyle>
                      </List.Item>
                        <List.Item>
                          <TextStyle variation="">Unlimited products</TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">
                            Access all features
                          </TextStyle>
                        </List.Item>
                        <List.Item>
                          <TextStyle variation="">Priority support</TextStyle>
                        </List.Item>
                      </List>
                      <br />
                      {this.state.current_plan === 12 ? (
                        <Button disabled fullWidth>
                          Current plan
                        </Button>
                      ) : (
                        <Button
                          primary
                          fullWidth
                          onClick={this.selectPlan.bind(this, 12)}
                        >
                          Select
                        </Button>
                      )}
                    </Card.Section>
                  </Card>
                )}
              </Layout.Section>
            </Layout>
          )}
          <Frame>{toastMarkup}</Frame>
        </Page>
        <Modal
            open={this.state.is_free_plan_active}
            onClose={this.closePlansChange}
            title="Update Completed. Congratulations!"
            secondaryActions={[
              {
                  content: 'Close',
                  onAction: this.closePlansChange,
              },
          ]}
        >
          <div className="plan-tan-k">
            <Modal.Section>
                <div className="plans-free-div-content">
                    <div className="plans-free-img">
                      <img src="https://cdn.shopify.com/s/files/applications/92b99ce245731caa0a4c2ce3ca7055d5_200x200.png?1617850771" alt="ETA ‑ Estimated Delivery Date Logo"></img>  
                    </div>   
                    <div className="plans-free-title">
                      Would you mind letting us know what you think about this app?
                    </div>  
                    <div className="plans-free-xx">              
                    <Rate value={this.state.star_v} onChange={this.changeStar} />
                    </div>
                </div>
            </Modal.Section>
            </div>
        </Modal>

        <Modal
            // activator={activator}
            open={this.state.f_show}
            onClose={this.closeFeedbackChange}
            title="Send your feedback"
            primaryAction={{
                content: 'Send',
                onAction: this.sendFeedbackEmail,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: this.closeFeedbackChange,
                },
            ]}
        >
            <Modal.Section>
                <TextContainer>
                    <div>
                        <div>Please tell us more, we will try the best to get better</div>
                        <TextField value={this.state.f_email_text} onChange={this.handleEmailTextChange} multiline={3} placeholder="Edit your message here..." />
                    </div>
                    <div>
                        <div>Your contact email</div>
                        <TextField value={this.state.f_email} onChange={this.handleEmailChange} placeholder="Edit your message here..." />
                    </div>
                </TextContainer>
            </Modal.Section>
        </Modal>
        </div>
    );
  }
}

export default Plan;
