/*
 * 进行local数据存储管理的工具模块
 */
const obj = {}
export default {
    /*
     * 设置值
     */
    set (key,val) {
        try{
        // localStorage.setItem(key, JSON.stringify(val))
        obj[key] = val
        }catch(e){
            return ''
        }
    },

    /*
     * 读取值
     */
    get (key) {
        try{
            // return JSON.parse(localStorage.getItem(key))
            return obj[key]
        }catch(e){
            return ''
        }
    },

    /*
     * 删除值
     */
    remove (key) {
        try{
            // localStorage.removeItem(key)
            obj.splice(key, 1)
        }catch(e){
            return ''
        }
    }
    
}