/** 主包 */
import React, { Component } from 'react';
import {
    Card, TextStyle, Tooltip, Layout, SkeletonBodyText, Icon, EmptyState, DataTable
    , Thumbnail, Page, Select, Button, Pagination, Frame, Loading, Toast, TextField, Modal, TextContainer, Checkbox
} from "@shopify/polaris";
import { SearchMinor, ChevronDownMinor, ChevronUpMinor, CancelSmallMinor, HorizontalDotsMinor, CircleCancelMinor } from '@shopify/polaris-icons';

/** 工具组件包 */
import storageUtils from '../../../../utils/storageUtils';
import { countryDeliveryTimeList } from '../../../../api/index';
import { deleteCountry } from '../../../../api/index';
import { createCountry } from '../../../../api/index';
import { getSeletCountry } from '../../../../api/index';

/** 样式 */
import './delivery-zones.less';
import { Stack } from 'immutable';
import { Alert } from 'antd';


class DeliveryZones extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: {
                token: '',
                domain: ''
            },
            country: [],
            country_lists: [],
            country_delivery_time_lists: [],
            s_country_delivery_time_lists: [],
            country_delivery_time_lists_default: [],
            add_country_list_search_value: "",
            del_add_country_list_search_value_active: false,
            del_country_list_search_value_active: false,
            provinces_show_list: [],
            provinces_list: [],
            select_country: [],
            select_country_provinces: [],
            select_country_provinces_count: [],
            select_country_code_status: [],
            pagination: [],
            is_show_country: props.deliveryZonesData.is_show_country,
            is_country_info_status: props.deliveryZonesData.is_country_info_status,
            is_country_info: props.deliveryZonesData.is_country_info,
            modal: {
                isActive: false,
                cId: 0,
                cDeliveryTimeMin: 0,
                cDeliveryTimeMax: 0,
                type: 'country'
            },
            country_modal: {
                isActive: false,
                value: '',
                checked: []
            },
            value: '',
            bbb: '',
            _isRequestFinal: false,

            is_notify: false,
            notify_content: ''
        }
    }

    // 内存泄漏标志
    _isMounted = true;


    UNSAFE_componentWillMount() {
        // 监测本地是否存在用户核心参数，否则跳转 404
        var userData = storageUtils.get('users');

        if (userData === null) {
            // this.props.history.push('/notfound');
            window.location.href = "/notfound";
            return '';
        }

        let userInfo = this.state.users;
        userInfo.domain = userData.shop_domain;
        userInfo.token = userData.user_token;
        if (this._isMounted) {
            this.setState({ userInfo });
        }

        // 初始化数据
        this.initData(1);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isFlushData === true) {
            let page = 1;
            if (this.state.pagination.next == false) {
                page = this.state.pagination.next_id;
            } else {
                page = this.state.pagination.next_id - 1;
            }
            this.initData(page);
        }
        if (this._isMounted) {
            this.setState({ country: nextProps.country, country_lists: nextProps.country, 
                is_show_country: nextProps.deliveryZonesData.is_show_country,
                is_country_info_status: nextProps.deliveryZonesData.is_country_info_status,
                is_country_info: nextProps.deliveryZonesData.is_country_info
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * 初始化数据
     */
    initData = async (currentPage) => {
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const param = {
            'page': currentPage
        }
        // 国家预投递列表
        let countryListD = await countryDeliveryTimeList(headers, param);
        if (countryListD.code === 200) {
             if(countryListD.data.country_data.length <= 0 && currentPage != 1){
                this.initData(1);
             }else{
                if (this._isMounted) {
                    this.setState({
                        country_delivery_time_lists: countryListD.data.country_data,
                        country_delivery_time_lists_default: countryListD.data.country_data,
                        s_country_delivery_time_lists: countryListD.data.s_country_data,
                        pagination: countryListD.data.page_data
                    });
                }
            }
        }
        else {
            // console.log(456);
        }

        if (this._isMounted) {
            this.setState({ _isRequestFinal: true });
        }
    }


    /**
     * 编辑国家预投递时间
     * 
     * @param {*} cId  国家ID
     * @param {*} cDeliveryTimeMin  小值
     * @param {*} cDeliveryTimeMax  大值
     */
    editDateChange = (cId, cDeliveryTimeMin, cDeliveryTimeMax) => {
        let isModalData = this.state.modal;
        isModalData.isActive = true;
        isModalData.cId = cId;
        isModalData.cDeliveryTimeMin = cDeliveryTimeMin;
        isModalData.cDeliveryTimeMax = cDeliveryTimeMax;
        if (this._isMounted) {
            this.setState({ isModalData });
        }

        this.props.updateComponentByDeliveryZones(this.state.modal);
    }

    /**
     * 删除国家
     * 
     * @param {*} cId 
     */
    deleteCountryChange = async (cId) => {
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const params = {
            'cId': cId
        }
        let deleteData = await deleteCountry(headers, params);
        if (deleteData.code === 200) {
            this.setState({ is_notify: true, notify_content: 'Successfully deleted.' });
            let page = 1;
            if (this.state.pagination.next == false) {
                page = this.state.pagination.next_id;
            } else {
                page = this.state.pagination.next_id - 1;
            }
            this.initData(page);
        }
        else {
            this.setState({ is_notify: false, notify_content: 'Failed deleted.' });
            // console.log(456);
        }
    }
    /**
     * 点击展开省份
     * @param {*} value
     */
    blockProvinces = (value) => {
        // let a = this.state.provinces_show_list.splice(value);
        if (this.state.provinces_show_list.indexOf(value) >= 0) {
            let key = this.state.provinces_show_list.indexOf(value);
            this.state.provinces_show_list.splice(key, 1);
        } else {
            this.state.provinces_show_list.push(value);
        }
        this.setState({
            provinces_show_list: this.state.provinces_show_list
        });
    }
    /**
     * 省份勾选选择
     * @param {*} country_code 
     * @param {*} value 
     */
    checkboxProvincesChange = (provinces, i, country_code, value) => {
        let countryModalData = this.state.country_modal;
        let provincesList = this.state.provinces_list;
        let selectCountryProvincesCount = this.state.select_country_provinces_count;

        if (typeof selectCountryProvincesCount[country_code] == "undefined") {
            selectCountryProvincesCount[country_code] = 0;
        }
        if (this.state.provinces_list.indexOf(value) >= 0) {
            let key = provincesList.indexOf(value);
            provincesList.splice(key, 1);
            let status = false;
            provinces.map((v, i) => {
                if (provincesList.indexOf(v.provinces_code) >= 0) {
                    status = true;
                }
            })
            if (status == false) {
                countryModalData.checked[i] = { checked: false, code: country_code };
            }
            selectCountryProvincesCount[country_code] = parseInt(selectCountryProvincesCount[country_code]) - 1;
        } else {
            provincesList.push(value);
            countryModalData.checked[i] = { checked: true, code: country_code };
            selectCountryProvincesCount[country_code] = parseInt(selectCountryProvincesCount[country_code]) + 1;

        }
        this.setState({
            provincesList
        });
    }
    /**
     * 国家勾选选择
     * 
     * @param {*} cId 
     * @param {*} i 
     * @param {*} checked 
     * @param {*} mark 
     */
    checkboxCountryChange = (provinces, code, i, checked, mark) => {
        let countryModalData = this.state.country_modal;
        let provincesList = this.state.provinces_list
        let selectCountryProvinces = this.state.select_country_provinces
        let selectCountryProvincesCount = this.state.select_country_provinces_count;

        let count = 0;
        if (checked == true) {
            provinces.map((v, i) => {
                if (provincesList.indexOf(v.provinces_code) < 0) {
                    if (selectCountryProvinces.indexOf(v.provinces_name) < 0) {
                        provincesList.push(v.provinces_code);
                        count = count + 1;
                    }
                }
            });
            selectCountryProvincesCount[code] = count;
        } else {
            provinces.map((v, i) => {
                if (provincesList.indexOf(v.provinces_code) >= 0) {
                    let key = provincesList.indexOf(v.provinces_code);
                    provincesList.splice(key, 1);
                }
            });
            selectCountryProvincesCount[code] = count;
        }
        countryModalData.checked[i] = { checked: checked, code: (checked === true ? code : 0) };
        if (this._isMounted) {
            this.setState({ countryModalData, provincesList });
        }
    }

    /**
     * 添加预投递Country
     */
    addCountry = async () => {
        let cCodes = [];
        let provincesList = this.state.provinces_list;

        this.state.country_modal.checked.map((v, i) => {
            let code = v.code;
            if (code !== 0) {
                cCodes[i] = code;
            }
            return '';
        });

        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const params = {
            'country_code': cCodes,
            'provinces_list': provincesList
        }

        //预投递Country列表
        let addDataRes = await createCountry(headers, params);

        if (addDataRes.code === 200) {
            let countryModalData = this.state.country_modal;
            countryModalData.isActive = false;
            if (this._isMounted) {
                this.setState({ countryModalData, is_notify: true, notify_content: 'Successfully added' });
            }
            this.initData(1);
        }
        else {
            this.setState({ is_notify: true, notify_content: 'Failed added' });
            // console.log(456);
        }
    }

    /**
     * 搜索列表country
     * 
     * @param {*} newValue 
     */
    searchListCountryChange = (newValue) => {
        if (this._isMounted) {
            this.setState({ add_country_list_search_value: newValue });
        }

        const filterRegex = new RegExp(newValue, 'i');
        const resultOptions = this.state.country_delivery_time_lists.filter((option) =>
            option.country_name.match(filterRegex),
        );

        if (this._isMounted) {
            this.setState({
                country_delivery_time_lists: resultOptions,
            });
        }

        if (newValue.length === 0) {
            // this.initData(1);
            this.setState({
                country_delivery_time_lists: this.state.country_delivery_time_lists_default,
                del_add_country_list_search_value_active: false
            });
        }

        if (newValue.length !== 0) {
            const filterRegex = new RegExp(newValue, 'i');
            const resultOptions = this.state.s_country_delivery_time_lists.filter((option) =>
                option.country_name.match(filterRegex),
            );
            if (this._isMounted) {
                this.setState({
                    country_delivery_time_lists: resultOptions,
                    del_add_country_list_search_value_active: true
                });
            }
        }
    }

    /**
     * 删除搜索 已添加Country输入框文本
     */
    deleteSearchAddCountryListText = () => {
        if (this._isMounted) {
            this.setState({
                add_country_list_search_value: '',
                country_delivery_time_lists: this.state.country_delivery_time_lists_default,
                del_add_country_list_search_value_active: false
            });
        }
    }

    /**
     * 搜索country
     * 
     * @param {*} newValue 
     */
    searchCountryChange = (newValue) => {
        console.log(this.state.country);
        let countryModelData = this.state.country_modal;
        countryModelData.value = newValue
        if (this._isMounted) {
            this.setState({ countryModelData });
        }

        const filterRegex = new RegExp(newValue, 'i');
        const resultOptions = this.state.country.filter((option) =>
            option.title.match(filterRegex),
        );

        if (this._isMounted) {
            this.setState({
                country: resultOptions,
            });
        }

        if (newValue.length === 0) {
            // this.initData(1);
            this.setState({
                country: this.state.country_lists,
                del_country_list_search_value_active: false
            });
        }

        if (newValue.length !== 0) {
            const filterRegex = new RegExp(newValue, 'i');
            const resultOptions = this.state.country_lists.filter((option) =>
                option.title.match(filterRegex),
            );

            if (this._isMounted) {
                this.setState({
                    country: resultOptions,
                    del_country_list_search_value_active: true
                });
            }
        }
    }

    /**
     * 删除搜索Country输入框文本
     */
    deleteSearchCountryListText = () => {
        let countryModelData = this.state.country_modal;
        countryModelData.value = '';
        if (this._isMounted) {
            this.setState({
                countryModelData,
                country: this.state.country_lists,
                del_country_list_search_value_active: false
            });
        }
    }


    updateCountryDelvieryList = async (code) => {
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const params = {
            'country_code': code[0]
        }
        let addDataRes = await createCountry(headers, params);

        if (addDataRes.code === 200) {
            this.setState({ is_notify: true, notify_content: 'Successfully added' });
            this.initData(1);
        }
        else {
            this.setState({ is_notify: true, notify_content: 'Failed added' });
            // console.log(456);
        }
    }

    /**
     * 开启country列表弹窗
     */
    activeCountryModal = async () => {
        let countryModalData = this.state.country_modal;

        countryModalData.isActive = true;
        countryModalData.checked = [];
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const params = {
        }

        let getSelectCounrtyRes = await getSeletCountry(headers, params);
        if (getSelectCounrtyRes.code === 200) {
            this.setState({ countryModalData, select_country_provinces_count: [], provinces_list: [], select_country_code_status: getSelectCounrtyRes.data.country_code_status, select_country: getSelectCounrtyRes.data.country, select_country_provinces: getSelectCounrtyRes.data.provinces });
        }
        else {
            this.setState({ is_notify: true, notify_content: 'Failed added' });
        }
    }

    /**
     * 关闭或取消country列表弹窗
     */
    closeOrCancelCountryModal = () => {
        let countryModalData = this.state.country_modal;
        countryModalData.isActive = false;
        if (this._isMounted) {
            this.setState({ countryModalData });
        }

        this.initData(1);
    }

    /**
     * 弹窗是否活跃
     */
    toggleActive = () => {
        if (this._isMounted) {
            this.setState({ is_notify: !this.state.is_notify });
        }
    }


    /*
    是否只显示设置的国家
    */
    onCheckCountryShow = ()=>{
        if (this._isMounted) {
            this.setState({ is_show_country: !this.state.is_show_country });
        }
        this.props.updateSaveOrDiscardComponent("is-show-country", !this.state.is_show_country);
    }

    /**
     * 是否显示文本
     * @returns 
     */
     onSelectCountryList = (v) =>{
        if (this._isMounted) {
            this.setState({ is_country_info_status: v });
        }
        this.props.updateSaveOrDiscardComponent("is-country-info-status",parseInt(v));
     }

     /**
     * 国家信息文本输入
     * @returns 
     */
     onCountryInfoText = (v) =>{
        if (this._isMounted) {
            this.setState({ is_country_info: v });
        }
        this.props.updateSaveOrDiscardComponent("is-country-info", v);
     } 
    render() {
        const toastMarkup = this.state.is_notify ? (
            <Toast content={this.state.notify_content} onDismiss={this.toggleActive} />
        ) : null;
        let cdData = [];  // 国家预投递时间数据
        let item = [];
        this.state.country_delivery_time_lists.map((v, i) => {
            item[0] = <div className="delete-country"><Button plain onClick={this.deleteCountryChange.bind(this, v.cId)}><Icon source={CancelSmallMinor} /></Button></div>;


            item[1] = <Thumbnail source={v.cImgSrc} size="small" alt="" />;
            item[2] = <div className="country-provinces">
                <span>{v.country_name}</span>
                {
                    v.country_provinces_count != 0
                        ?
                        v.country_provinces_count == 1
                            ?
                            <Tooltip active={false} content={v.country_provinces}>
                                <TextStyle variation="strong">({v.country_provinces})</TextStyle>
                            </Tooltip>
                            :
                            <Tooltip active={false} content={v.country_provinces}>
                                <TextStyle variation="strong">({v.country_provinces_count} of {v.country_provinces_sum} regions)</TextStyle>
                            </Tooltip>
                        :
                        ""
                }
            </div>;
            item[3] = v.cDeliveryTimeMin + '-' + v.cDeliveryTimeMax + ' days';
            item[4] = <Page
                actionGroups={[
                    {
                        title: <Icon source={HorizontalDotsMinor} />,
                        actions: [
                            {
                                content: 'Edit date',
                                onAction: this.editDateChange.bind(this, v.cId, v.cDeliveryTimeMin, v.cDeliveryTimeMax),
                            },
                            {
                                content: <span style={{ 'color': '#C0323E' }}>Delete</span>,
                                onAction: this.deleteCountryChange.bind(this, v.cId),
                            }
                        ],
                    },
                ]}
            >
            </Page>;

            cdData[i] = item;
            item = [];
            return '';
        });

        const countryListComponent = this.state.country.map((v, i) => {
            return (
                <div className="cuntry-group" key={i}>
                    <Modal.Section key={i}>
                        <TextContainer>
                            <div className="delivery-cuntry">
                                <div className="cuntry-name-group">
                                    <Checkbox
                                        label={
                                            <div className="show-product-icon-description">
                                                <Thumbnail
                                                    source={v.cImgSrc}
                                                    size="small"
                                                />
                                                <span>
                                                    {v.title}
                                                </span>
                                            </div>
                                        }
                                        disabled={
                                            typeof this.state.select_country_code_status[v.value] == "undefined"
                                                ?
                                                false
                                                :
                                                true
                                        }
                                        // checked={(this.state.product_modal.checked.length !== 0) ? this.state.product_modal.checked[i].checked : false}
                                        checked={(typeof this.state.country_modal.checked[i] == "undefined") ? false : this.state.country_modal.checked[i].checked}
                                        onChange={this.checkboxCountryChange.bind(this, v.provinces, v.value, i)}
                                        key={v.value}
                                    />
                                </div>
                                <div className="provinces-num-group">
                                    {
                                        v.provinces.length > 0
                                            ?
                                            <div className="provinces-num" onClick={this.blockProvinces.bind(this, v.value)}>
                                                <span>
                                                    {typeof this.state.select_country_provinces_count[v.value] != "undefined" ? this.state.select_country_provinces_count[v.value] : 0} of {v.provinces.length} regions
                                                </span>
                                                {
                                                    this.state.provinces_show_list.indexOf(v.value) < 0
                                                        ?
                                                        <Icon color="subdued"
                                                            source={ChevronDownMinor}
                                                            color="base" />
                                                        :
                                                        <Icon color="subdued"
                                                            source={ChevronUpMinor}
                                                            color="base" />
                                                }
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </TextContainer>
                    </Modal.Section>
                    {
                        v.provinces.map((j, k) => {
                            return (
                                <div key={k} style={{ "display": this.state.provinces_show_list.indexOf(v.value) < 0 ? "none" : "block" }}>
                                    <Modal.Section key={k}>
                                        <div className="provinces-text" >
                                            <TextContainer>
                                                <Checkbox
                                                    label={
                                                        this.state.select_country_provinces.indexOf(j.provinces_name) < 0
                                                            ?
                                                            <div className="show-provinces-name">
                                                                <span>
                                                                    {j.provinces_name}
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="show-provinces-name">
                                                                <span>
                                                                    {j.provinces_name}
                                                                </span>
                                                                <span className="select-pro-text">
                                                                    In another zone
                                                                </span>
                                                            </div>
                                                    }
                                                    disabled={
                                                        this.state.select_country_provinces.indexOf(j.provinces_name) < 0
                                                            ?
                                                            false
                                                            :
                                                            true
                                                    }
                                                    // checked={(this.state.product_modal.checked.length !== 0) ? this.state.product_modal.checked[i].checked : false}
                                                    checked={this.state.provinces_list.indexOf(j.provinces_code) < 0 ? false : true}
                                                    onChange={this.checkboxProvincesChange.bind(this, v.provinces, i, v.value, j.provinces_code, k)}
                                                    key={v.value}
                                                />
                                            </TextContainer>
                                        </div>
                                    </Modal.Section>
                                </div>
                            )
                        })
                    }

                </div>

            )
        })

        const deleteAddCountryListSearch = this.state.del_add_country_list_search_value_active ? <Icon source={CircleCancelMinor} /> : null;
        const deleteCountryListSearch = this.state.del_country_list_search_value_active ? <Icon source={CircleCancelMinor} /> : null;
        const options = [
            {label: 'Custom', value: 1},
            {label: 'None', value: 2},
          ];
        return (
            <div className='et-delivery-zones'>
                <Layout>
                    <Layout.Section>
                        <Card title={
                             <div className='country-content'>
                               <div className='country-title'>
                                   Delivery zones
                               </div>
                               <div className='country-cart'>
                                   <Checkbox label="Only apply ETA to listed countries" checked={this.state.is_show_country} onChange={this.onCheckCountryShow} />
                               </div>
                               {
                                   this.state.is_show_country === true
                                   ?
                                    <div>
                                        <div className='country-select'>
                                            <div className='country-select-label'>
                                                <label>Outside the delivery zones customers can see:</label>
                                            </div>
                                            <div className='country-select-text'>
                                                <Select
                                                    options={options}
                                                    onChange={this.onSelectCountryList}
                                                    value={parseInt(this.state.is_country_info_status)}
                                                    />
                                            </div>
                                        </div>
                                        {
                                            this.state.is_country_info_status == 1
                                            ?
                                            <div className='country-custom'>
                                            <TextField
                                                    value={this.state.is_country_info}
                                                    onChange={this.onCountryInfoText}
                                                    autoComplete="off"
                                                    />
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                :
                                ""
                               }
                            </div>} sectioned actions={[{ content: '', onAction: this.activeCountryModal }]}>   
                           
                            <div className="search-country">
                                <div className='search-country-text'>
                                <TextField value={this.state.add_country_list_search_value} onChange={this.searchListCountryChange} autoComplete={false} prefix={<Icon source={SearchMinor} />} suffix={<div className="delete-search-add-country-list-text" onClick={this.deleteSearchAddCountryListText}>{deleteAddCountryListSearch}</div>} placeholder="Search country" />
                                </div>
                                <div className='search-country-add'>
                                <Button plain onClick={this.activeCountryModal}>Add countries</Button>
                                </div>    
                            </div>
                            <div className="et-delivery-zones-list">
                                {
                                    this.state._isRequestFinal === false
                                        ?
                                        <div>
                                            <Card sectioned><SkeletonBodyText lines={15} /></Card>
                                            <Frame>
                                                <Loading />
                                            </Frame>
                                        </div>

                                        :
                                        (
                                            cdData.length !== 0
                                                ?
                                                <div>
                                                    <DataTable
                                                        columnContentTypes={[
                                                            'text',
                                                            'numeric',
                                                            'numeric',
                                                            'numeric',
                                                            'numeric',
                                                        ]}
                                                        headings={[]}
                                                        rows={cdData}
                                                    />
                                                    {
                                                        this.state.del_add_country_list_search_value_active ?
                                                            null
                                                            :
                                                            <div className="pagination">
                                                                <Pagination
                                                                    onPrevious={this.initData.bind(this, this.state.pagination.prev_id)}
                                                                    onNext={this.initData.bind(this, this.state.pagination.next_id)}
                                                                    hasPrevious={this.state.pagination.prev}
                                                                    hasNext={this.state.pagination.next}
                                                                />
                                                            </div>
                                                    }
                                                </div>
                                                :
                                                <EmptyState heading="No zones">
                                                    <p>Please click the button on the top right to add a country first.</p>
                                                </EmptyState>
                                        )
                                }
                            </div>
                        </Card>
                    </Layout.Section>

                    <Modal
                        open={this.state.country_modal.isActive}
                        onClose={this.closeOrCancelCountryModal}
                        title="Add countries"
                        primaryAction={{
                            content: 'Done',
                            onAction: this.addCountry,
                        }}
                        secondaryActions={[
                            {
                                content: 'Cancel',
                                onAction: this.closeOrCancelCountryModal,
                            },
                        ]}
                    >
                        <div className="search-products"><TextField value={this.state.country_modal.value} onChange={this.searchCountryChange} autoComplete={false} prefix={<Icon source={SearchMinor} />} suffix={<div className="delete-search-country-list-text" onClick={this.deleteSearchCountryListText}>{deleteCountryListSearch}</div>} placeholder="Search country" /></div>
                        <div className="select-products">
                            {countryListComponent}
                        </div>
                    </Modal>
                </Layout>
                <Frame>{toastMarkup}</Frame>
            </div>
        )
    }
}


export default DeliveryZones;