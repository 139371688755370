/** 主包 */
import React, { Component } from 'react';
import {
    Layout, Card, Page, DataTable, Icon, Thumbnail, EmptyState,
    SkeletonBodyText, Button, Pagination, Modal, TextContainer, Checkbox, TextField, Frame, Loading, Toast, Badge
} from "@shopify/polaris";
import { HorizontalDotsMinor, ViewMinor, SearchMinor, CircleCancelMinor } from '@shopify/polaris-icons';

/** 公共组件 */
import EditDeliverytimeModal from '../../../../components/edit-deliverytime-modal';

/** 工具组件包 */
import storageUtils from '../../../../utils/storageUtils';
import { addCollectionList } from '../../../../api/index';
import { addCollection } from '../../../../api/index';
import { collectionList } from '../../../../api/index';
import { removeCollection } from '../../../../api/index';

/** 样式 */
import './collection_list.less';


class CollectionList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: {
                token: '',
                domain: ''
            },
            add_collection_lists: [],
            s_add_collection_lists: [],
            add_collection_lists_default: [],
            add_collection_list_search_value: "",
            del_add_collection_list_search_value_active: false,
            del_collection_list_search_value_active: false,
            collection_lists: [],
            collection_lists_default: [],
            pagination: [],
            modal: {
                isActive: false,
                cId: 0,
                cDeliveryTimeMin: 0,
                cDeliveryTimeMax: 0,
                type: 'collection',
                isHideEdt: false
            },
            collection_modal: {
                isActive: false,
                value: '',
                checked: []
            },
            _isRequestFinal: false,

            is_notify: false,
            notify_content: ''
        }
    }

    // 内存泄漏标志
    _isMounted = true;


    UNSAFE_componentWillMount() {
        // 监测本地是否存在用户核心参数，否则跳转 404
        var userData = storageUtils.get('users');

        if (userData === null) {
            // this.props.history.push('/notfound');
            window.location.href = "/notfound";
            return '';
        }

        let userInfo = this.state.users;
        userInfo.domain = userData.shop_domain;
        userInfo.token = userData.user_token;
        if (this._isMounted) {
            this.setState({ userInfo });
        }

        // 初始化数据
        this.initData(1);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this._isMounted) {
            if (nextProps.isShowCollection === true && nextProps.isSyncCollection !== true) {
                let collectionModalData = this.state.collection_modal;
                collectionModalData.isActive = true;
                if (this._isMounted) {
                    this.setState({ collectionModalData });
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * 初始化数据
     */
    initData = async (currentPage) => {
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const param = {
            'page': currentPage
        }
        // 预投递Collection列表
        let addCollectionListD = await addCollectionList(headers, param);
        if (addCollectionListD.code === 200) {
            if (this._isMounted) {
                this.setState({
                    add_collection_lists: addCollectionListD.data.col_data,
                    add_collection_lists_default: addCollectionListD.data.col_data,
                    s_add_collection_lists: addCollectionListD.data.s_col_data,
                    pagination: addCollectionListD.data.page_data
                });
            }
        }
        else {
            // console.log(456);
        }

        if (this._isMounted) {
            this.setState({ _isRequestFinal: true });
        }

        // Collection列表
        let collectionListD = await collectionList(headers);
        if (collectionListD.code === 200) {
            if (this._isMounted) {
                this.setState({
                    collection_lists: collectionListD.data,
                    collection_lists_default: collectionListD.data
                });
            }
            if (collectionListD.data.length !== 0) {
                let collectionModalData = this.state.collection_modal;
                collectionListD.data.map((v, i) => {
                    collectionModalData.checked[i] = { checked: false, pId: 0 }
                    return '';
                });
                if (this._isMounted) {
                    this.setState({
                        collectionModalData
                    });
                }
            }
        }
        else {
            // console.log(456);
        }
    }

    /**
     * 编辑Collection预投递时间
     * 
     * @param {*} cId  CollectionID
     * @param {*} cDeliveryTimeMin  小值
     * @param {*} cDeliveryTimeMax  大值
     */
    editDateChange = (cId, cDeliveryTimeMin, cDeliveryTimeMax, isHideEdt) => {
        let isModalData = this.state.modal;
        isModalData.isActive = true;
        isModalData.cId = cId;
        isModalData.cDeliveryTimeMin = cDeliveryTimeMin;
        isModalData.cDeliveryTimeMax = cDeliveryTimeMax;
        isModalData.isHideEdt = isHideEdt;
        if (this._isMounted) {
            this.setState({ isModalData });
        }
    }

    /**
     * 移除collection
     * 
     * @param {*} cId 
     */
    removeCollectionChange = async (cId) => {
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const params = {
            'cId': cId
        }
        let removeData = await removeCollection(headers, params);
        if (removeData.code === 200) {
            this.setState({ is_notify: true, notify_content: 'Successfully deleted.' });
            this.initData(1);
        }
        else {
            this.setState({ is_notify: false, notify_content: 'Failed deleted.' });
            // console.log(456);
        }
    }

    /**
     * 更新组件
     */
    updateComponent = () => {
        let isModalData = this.state.modal;
        isModalData.isActive = false;
        if (this._isMounted) {
            this.setState({ isModalData });
        }
        let page = 1;
        if (this.state.pagination.next == false) {
            page = this.state.pagination.next_id;
        } else {
            page = this.state.pagination.next_id - 1;
        }
        this.initData(page);
    }

    /**
     * 清除子组件弹窗 props 影响
     */
    clearModal = () => {
        let isModalData = this.state.modal;
        isModalData.isActive = false;
        if (this._isMounted) {
            this.setState({ isModalData });
        }
    }

    /**
     * 开启collection列表弹窗
     */
    activeCollectionModal = () => {
        let collectionModalData = this.state.collection_modal;
        collectionModalData.isActive = true;
        if (this._isMounted) {
            this.setState({ collectionModalData });
        }
    }

    /**
     * 关闭或取消collection列表弹窗
     */
    closeOrCancelCollectionModal = () => {
        let collectionModalData = this.state.collection_modal;
        collectionModalData.isActive = false;
        if (this._isMounted) {
            this.setState({ collectionModalData });
        }
        this.props.updateSaveOrDiscardComponent("close-collection-list", false);
        this.initData(1);
    }

    handleChange = () => {

    }

    /**
     * 搜索列表collection
     * 
     * @param {*} newValue 
     */
    searchListCollectionChange = (newValue) => {
        if (this._isMounted) {
            this.setState({ add_collection_list_search_value: newValue });
        }

        const filterRegex = new RegExp(newValue, 'i');
        const resultOptions = this.state.add_collection_lists.filter((option) =>
            option.cTitle.match(filterRegex),
        );

        if (this._isMounted) {
            this.setState({
                add_collection_lists: resultOptions,
            });
        }

        if (newValue.length === 0) {
            // this.initData(1);
            this.setState({
                add_collection_lists: this.state.add_collection_lists_default,
                del_add_collection_list_search_value_active: false
            });
        }

        if (newValue.length !== 0) {
            const filterRegex = new RegExp(newValue, 'i');
            const resultOptions = this.state.s_add_collection_lists.filter((option) =>
                option.cTitle.match(filterRegex),
            );

            if (this._isMounted) {
                this.setState({
                    add_collection_lists: resultOptions,
                    del_add_collection_list_search_value_active: true
                });
            }
        }
    }

    /**
     * 删除搜索添加collection输入框文本
     */
    deleteSearchAddCollectionListText = () => {
        if (this._isMounted) {
            this.setState({
                add_collection_list_search_value: '',
                add_collection_lists: this.state.add_collection_lists_default,
                del_add_collection_list_search_value_active: false
            });
        }
    }

    /**
     * 搜索Collection
     * 
     * @param {*} newValue 
     */
    searchCollectionChange = (newValue) => {
        let collectionModalData = this.state.collection_modal;
        collectionModalData.value = newValue;
        if (this._isMounted) {
            this.setState({ collectionModalData });
        }

        const filterRegex = new RegExp(newValue, 'i');
        const resultOptions = this.state.collection_lists.filter((option) =>
            option.title.match(filterRegex),
        );

        if (this._isMounted) {
            this.setState({
                collection_lists: resultOptions,
            });
        }

        if (newValue.length === 0) {
            // this.initData(1);
            this.setState({
                collection_lists: this.state.collection_lists_default,
                del_collection_list_search_value_active: false
            });
        }

        if (newValue.length !== 0) {
            const filterRegex = new RegExp(newValue, 'i');
            const resultOptions = this.state.collection_lists_default.filter((option) =>
                option.title.match(filterRegex),
            );

            if (this._isMounted) {
                this.setState({
                    collection_lists: resultOptions,
                    del_collection_list_search_value_active: true
                });
            }
        }
    }

    /**
     * 删除搜索collection输入框文本
     */
    deleteSearchCollectionListText = () => {
        let collectionModalData = this.state.collection_modal;
        collectionModalData.value = '';
        if (this._isMounted) {
            this.setState({
                collectionModalData,
                collection_lists: this.state.collection_lists_default,
                del_collection_list_search_value_active: false
            });
        }
    }

    /**
     * collection勾选选择
     * 
     * @param {*} pId 
     * @param {*} i 
     * @param {*} checked 
     * @param {*} mark 
     */
    checkboxCollectionChange = (cId, i, checked, mark) => {
        let collectionModalData = this.state.collection_modal;
        collectionModalData.checked[i] = { checked: checked, cId: (checked === true ? cId : 0) };
        if (this._isMounted) {
            this.setState({ collectionModalData });
        }
    }


    /**
     * 添加预投递Collection
     */
    addCollection = async () => {
        let cIds = [];
        this.state.collection_modal.checked.map((v, i) => {
            let cId = v.cId;
            if (cId !== 0) {
                cIds[i] = cId;
            }
            else {
                cIds[i] = 0;
            }
            return '';
        });
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        const param = {
            'cIds': cIds
        }
        // 预投递Collection列表
        let addCollectionRes = await addCollection(headers, param);
        if (addCollectionRes.code === 200) {
            if (this._isMounted) {
                this.setState({ is_notify: true, notify_content: 'Successfully added' });
                this.closeOrCancelCollectionModal()
            }
        }
        else {
            this.setState({ is_notify: true, notify_content: 'Failed added' });
            // console.log(456);
        }
    }

    /**
     * 弹窗是否活跃
     */
    toggleActive = () => {
        if (this._isMounted) {
            this.setState({ is_notify: !this.state.is_notify });
        }
    }

    viewCollection = (pHandle) => {
        const urlObj = window.open('about:blank');
        urlObj.location.href = "https://" + this.state.users.domain + "/collections/" + pHandle;
    }

    /**
     * 添加collection
     */
    addCollectionList= () => {
        this.props.updateSaveOrDiscardComponent("add-collection-list", 0);
    }
    render() {
        const toastMarkup = this.state.is_notify ? (
            <Toast content={this.state.notify_content} onDismiss={this.toggleActive} />
        ) : null;
        let cdData = [];  // collection预投递时间数据
        let item = [];
        this.state.add_collection_lists.map((v, i) => {
            item[0] = <Thumbnail source={v.cImgSrc} size="small" alt="" />;
            item[1] = <div>{v.cTitle}<div className="view-collection" onClick={() => this.viewCollection(v.cHandle)}><Icon color="inkLightest" source={ViewMinor} /></div></div>;
            item[2] = v.cDeliveryTimeMin + '-' + v.cDeliveryTimeMax + ' days';
            item[3] =  v.isHideEdt == 1 ? <Badge>Hide</Badge> : '';
            item[4] = <Page
                actionGroups={[
                    {
                        title: <Icon source={HorizontalDotsMinor} />,
                        actions: [
                            {
                                content: 'Edit date',
                                onAction: this.editDateChange.bind(this, v.cId, v.cDeliveryTimeMin, v.cDeliveryTimeMax, v.isHideEdt),
                            },
                            {
                                content: <span style={{ 'color': '#C0323E' }}>Delete</span>,
                                onAction: this.removeCollectionChange.bind(this, v.cId),
                            }
                        ],
                    },
                ]}
            >
            </Page>;

            cdData[i] = item;
            item = [];
            return '';
        });

        const collectionListComponent = this.state.collection_lists.map((v, i) => {
            return (
                <Modal.Section key={i}>
                    <TextContainer>
                        <Checkbox
                            label={<div className="show-product-icon-description">
                                <Thumbnail
                                    source={v.cImgSrc}
                                    size="small"
                                />
                                <span>
                                    {v.cTitle}
                                </span>
                            </div>
                            }
                            checked={(typeof this.state.collection_modal.checked[i] == "undefined") ? false : this.state.collection_modal.checked[i].checked}
                            onChange={this.checkboxCollectionChange.bind(this, v.cId, i)}
                            key={v.pId}
                        />

                    </TextContainer>
                </Modal.Section>
            )
        })

        const deleteAddCollectionListSearch = this.state.del_add_collection_list_search_value_active ? <Icon source={CircleCancelMinor} /> : null;
        const deleteCollectionListSearch = this.state.del_collection_list_search_value_active ? <Icon source={CircleCancelMinor} /> : null;
        return (
            <div className='et-collection-list'>
                <Layout>
                    <Layout.Section oneHalf>
                        <Card>
                            <div className="search-collections"><div className="search-collections-text"><TextField value={this.state.add_collection_list_search_value} onChange={this.searchListCollectionChange} autoComplete={false} prefix={<Icon source={SearchMinor} />} suffix={<div className="delete-search-add-collection-list-text" onClick={this.deleteSearchAddCollectionListText}>{deleteAddCollectionListSearch}</div>} placeholder="Search collections" /></div>
                            <div>
                                <Button plain onClick={this.addCollectionList}>Add collections</Button>
                            </div>
                            </div>
                           
                            {
                                this.state._isRequestFinal === false
                                    ?
                                    <div>
                                        <Card sectioned><SkeletonBodyText lines={15} /></Card>
                                        <Frame>
                                            <Loading />
                                        </Frame>
                                    </div>
                                    :
                                    (
                                        cdData.length !== 0
                                            ?
                                            <div>
                                                <DataTable
                                                    columnContentTypes={[
                                                        'text',
                                                        'numeric',
                                                        'numeric',
                                                        'numeric',
                                                        'numeric',
                                                    ]}
                                                    headings={[]}
                                                    rows={cdData}
                                                />
                                                {
                                                    this.state.del_add_collection_list_search_value_active ?
                                                        null
                                                        :
                                                        <div className="pagination">
                                                            <Pagination
                                                                onPrevious={this.initData.bind(this, this.state.pagination.prev_id)}
                                                                onNext={this.initData.bind(this, this.state.pagination.next_id)}
                                                                hasPrevious={this.state.pagination.prev}
                                                                hasNext={this.state.pagination.next}
                                                            />
                                                        </div>
                                                }
                                            </div>
                                            :
                                            <EmptyState heading="No collections">
                                                <p>Please click the button on the top right to add a collection first.</p>
                                            </EmptyState>
                                    )
                            }
                        </Card>
                    </Layout.Section>

                    <EditDeliverytimeModal clearModal={this.clearModal} updateComponent={this.updateComponent} modalData={this.state.modal} />

                    <Modal
                        open={this.state.collection_modal.isActive}
                        onClose={this.closeOrCancelCollectionModal}
                        title="Add collections"
                        primaryAction={{
                            content: 'Done',
                            onAction: this.addCollection,
                        }}
                        secondaryActions={[
                            {
                                content: 'Cancel',
                                onAction: this.closeOrCancelCollectionModal,
                            },
                        ]}
                    >
                        <div className="search-products"><TextField value={this.state.collection_modal.value} onChange={this.searchCollectionChange} autoComplete={false} prefix={<Icon source={SearchMinor} />} suffix={<div className="delete-search-collection-list-text" onClick={this.deleteSearchCollectionListText}>{deleteCollectionListSearch}</div>} placeholder="Search collections" /></div>
                        <div className="select-products">
                            {collectionListComponent}
                        </div>
                    </Modal>
                </Layout>

                <Frame>{toastMarkup}</Frame>
            </div>
        )
    }
}


export default CollectionList;