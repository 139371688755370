/** 主包 */
import React, { Component } from 'react';
import { Modal, TextField, Frame, Toast, Checkbox } from "@shopify/polaris";

/** 工具组件包 */
import storageUtils from '../../utils/storageUtils';
import { editProduct } from '../../api/index';
import { editCollection } from '../../api/index';
import { editCountry } from '../../api/index';

/** 样式包 */
import './index.less';

class EditDeliverytimeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: {
                token: '',
                domain: ''
            },
            active: this.props.modalData.isActive,
            vMin: '',
            vMax: '',
            id: 0,
            type: '',
            hide_edt_checked: false,
            is_disable_input: false,

            is_notify: false,
            notify_content: ''
        }
    }

    
    // 内存泄漏标志
    _isMounted = true;

    UNSAFE_componentWillMount() {
        // 监测本地是否存在用户核心参数，否则跳转 404
        var userData = storageUtils.get('users');

        if (userData === null) {
            this.props.history.push('/notfound');
            return '';
        }

        let userInfo = this.state.users;
        userInfo.domain = userData.shop_domain;
        userInfo.token = userData.user_token;
        if (this._isMounted) {
            this.setState({ userInfo });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this._isMounted) {
            switch(nextProps.modalData.type) {
                case 'product':
                    if (this._isMounted) {
                    this.setState({
                        active: nextProps.modalData.isActive,
                        vMin: nextProps.modalData.pDeliveryTimeMin,
                        vMax: nextProps.modalData.pDeliveryTimeMax,
                        id: nextProps.modalData.pId,
                        type: nextProps.modalData.type,
                        hide_edt_checked: (nextProps.modalData.isHideEdt === 1) ? true : false,
                        is_disable_input: (nextProps.modalData.isHideEdt === 1) ? true : false
                    });};break;
                case 'collection':
                    if (this._isMounted) {
                    this.setState({
                        active: nextProps.modalData.isActive,
                        vMin: nextProps.modalData.cDeliveryTimeMin,
                        vMax: nextProps.modalData.cDeliveryTimeMax,
                        id: nextProps.modalData.cId,
                        type: nextProps.modalData.type,
                        hide_edt_checked: (nextProps.modalData.isHideEdt === 1) ? true : false,
                        is_disable_input: (nextProps.modalData.isHideEdt === 1) ? true : false
                    });};break;
                case 'country':
                    if (this._isMounted) {
                    this.setState({
                        active: nextProps.modalData.isActive,
                        vMin: nextProps.modalData.cDeliveryTimeMin,
                        vMax: nextProps.modalData.cDeliveryTimeMax,
                        id: nextProps.modalData.cId,
                        type: nextProps.modalData.type
                    });};break;
                default: ;
                        break;
            }
        }
    }

    /**
     * 弹窗是否活跃
     */
    toggleActive = () => {
        if(this._isMounted) {
            this.setState({is_notify: !this.state.is_notify});
        }
    }

    /**
     * min 值更改
     * 
     * @param {*} v 
     * @param {*} e 
     */
    handleChangeMin = (v, e) => {
        if(this._isMounted) {
            this.setState({vMin: v});
        }
    }

    /**
     * max 值更改
     * 
     * @param {*} v 
     * @param {*} e 
     */
    handleChangeMax = (v, e) => {
        if(this._isMounted) {
            this.setState({vMax: v});
        }
    }

    /**
     * 关闭取消弹窗
     */
    closeOrCancelEditDeliveryTimeModel = () => {
        if(this._isMounted) {
            this.setState({active: false});
        }

        this.props.clearModal()
    }

    /**
     * 提交数据
     * 
     * @param {*} id  id
     * @param {*} vMin  min
     * @param {*} vMax  max
     * @param {*} type  类型：product/country
     */
    submitData = (id, vMin, vMax, type, hideStatus) => {
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }

        const param = {
            'delivery_time_min': vMin,
            'delivery_time_max': vMax
        }

        switch(type) {
            case 'product':
                param['pId'] = id;
                param['isHideEdt'] = (hideStatus === true) ? 1 : 0;
                this.editProductRequest(headers, param);break;
            case 'collection':
                param['cId'] = id;
                param['isHideEdt'] = (hideStatus === true) ? 1 : 0;
                this.editCollectionRequest(headers, param);break;
            case 'country':
                param['cId'] = id;
                this.editCountryRequest(headers, param);break;
            default: ;
                    break;
        }
    }

    /**
     * 编辑产品预投递时间
     * 
     * @param {*} headers 
     * @param {*} param 
     */
    editProductRequest = async(headers, param) => {
        // 编辑产品
        let editP = await editProduct(headers, param);
        if(editP.code === 200) {
            this.closeOrCancelEditDeliveryTimeModel();
            this.props.updateComponent();
            if(this._isMounted) {
                this.setState({is_notify: true, notify_content: 'Successfully saved.'});
            }
        }
        else {
            if(this._isMounted) {
                this.setState({is_notify: true, notify_content: 'Save failed.'});
            }
        }
    }

    /**
     * 编辑Collection预投递时间
     * 
     * @param {*} headers 
     * @param {*} param 
     */
    editCollectionRequest = async(headers, param) => {
        // 编辑Collection
        let editC = await editCollection(headers, param);
        if(editC.code === 200) {
            this.closeOrCancelEditDeliveryTimeModel();
            this.props.updateComponent();
            if(this._isMounted) {
                this.setState({is_notify: true, notify_content: 'Successfully saved.'});
            }
        }
        else {
            if(this._isMounted) {
                this.setState({is_notify: true, notify_content: editC.msg});
            }
        }
    }

    /**
     * 编辑国家预投递时间
     * 
     * @param {*} headers 
     * @param {*} param 
     */
    editCountryRequest = async(headers, param) => {
        // 编辑国家
        let editP = await editCountry(headers, param);
        if(editP.code === 200) {
            this.closeOrCancelEditDeliveryTimeModel();
            this.props.updateComponent();
            if(this._isMounted) {
                this.setState({is_notify: true, notify_content: 'Successfully saved.'});
            }
        }
        else {
            if(this._isMounted) {
                this.setState({is_notify: true, notify_content: 'Save failed.'});
            }
        }
    }

    showEdtHandleChange = (v) => {
        if(this._isMounted) {
            this.setState({hide_edt_checked: v, is_disable_input: (v === true) ? true : false});
        }
    }


    render() {
        const toastMarkup = this.state.is_notify ? (
            <Toast content={this.state.notify_content} onDismiss={this.toggleActive} />
        ) : null;

        return (
            <div>
                <Modal
                    open={this.state.active}
                    onClose={this.closeOrCancelEditDeliveryTimeModel}
                    title="Edit delivery time"
                    primaryAction={{
                        content: 'Done',
                        onAction: this.submitData.bind(
                            this, this.state.id, this.state.vMin, this.state.vMax, this.state.type, this.state.hide_edt_checked
                        ),
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: this.closeOrCancelEditDeliveryTimeModel,
                        },
                    ]}
                >
                    <Modal.Section>
                        <div className="delivery-time-left"><TextField label="Minimum delivery date" value={this.state.vMin} onChange={this.handleChangeMin} disabled={this.state.is_disable_input} suffix="days" /></div>
                        <div className="delivery-time-right"><TextField label="Maximum delivery date" value={this.state.vMax} onChange={this.handleChangeMax} disabled={this.state.is_disable_input} suffix="days" /></div>
                        {
                            this.state.type !== "country"
                            ?
                                <div className="hide-edt-checkout">
                                    <Checkbox
                                    label={"Hide ETA message for this "+this.state.type}
                                    checked={this.state.hide_edt_checked}
                                    onChange={this.showEdtHandleChange}
                                    />
                                
                                </div>

                            :
                            null
                        }
                    </Modal.Section>
                </Modal>

                <Frame>{toastMarkup}</Frame>
            </div>
        );
    }
}


export default EditDeliverytimeModal;