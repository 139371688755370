import {
    isImmutable
} from "immutable";

export default {
    /**
     * 解析 URI 字符串为对象
     *
     * @param url
     */
    parseQueryString(url) {
        var match = url.split('?')[1].split('#')[0];
        var matches = match.split('&');
        var obj = {};

        for (var i = 0; i < matches.length; i++) {
            var key = matches[i].split('=')[0];
            var value = matches[i].split('=')[1];
            obj[key] = value;
        }

        return obj;
    },

    in_array(needle, haystack, argStrict) {
        var key = '';
        var strict = !!argStrict;
        if (strict) {
            for (key in haystack) {
                if (haystack[key] === needle) {
                    return true;
                }
            }
        } else {
            for (key in haystack) {
                if (haystack[key] === needle) {
                    return true;
                }
            }
        }

        return false;
    },

    getP(n, url = window.location.href) {
        n = n.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + n + '(=([^&#]*)|&|#|$)'),
            res = regex.exec(url);
        if (!res) return null;
        if (!res[2]) return '';
        return decodeURIComponent(res[2].replace(/\+/g, ' '));
    }
}

/**
 * @return {boolean}
 */
export var isEquals = function (value1, value2) {
    value1 = isImmutable(value1) ? JSON.stringify(value1.toJS()) : JSON.stringify(value1)
    value2 = isImmutable(value2) ? JSON.stringify(value2.toJS()) : JSON.stringify(value2)
    if (value1 === value2) {
        return true
    } else {
        return false
    }
}

/**
 * 字符串转换为实体
 * @param {String} text 
 * @return {String}
 */
export var stringToEntity = function (text) {
    text = text.replace(/&/g, '&amp;');
    text = text.replace(/</g, '&lt;');
    text = text.replace(/>/g, '&gt;');
    text = text.replace(/"/g, '&quot;');
    text = text.replace(/'/g, '&#039;');
    return text;
}

/**
 * 实体转换为字符串
 * @param {String} text 
 * @return {String}
 */
export var stringToDecode = function (text) {
    text = text.replace(/&amp;/g, '&');
    text = text.replace(/&lt;/g, '<');
    text = text.replace(/&gt;/g, '>');
    text = text.replace(/&quot;/g, '"');
    text = text.replace(/&#039;/g, "'");
    text = text.replace(/&amp;#x27;/g, "'");
    return text;
}

/**
 * 过滤HTML标签
 * @param {String} msg 
 * @returns {String} msg
 * 
 */
export var filterHTMLTag = function (msg) {
    if (msg) {
        msg = msg.replace(/<\/?[^>]*>/g, ''); //去除HTML Tag
        msg = msg.replace(/[|]*\n/, '') //去除行尾空格
        msg = msg.replace(/&npsp;/ig, ''); //去掉npsp
    }
    return msg;
}

/**
 * 查找数组查找并返回索引值
 * @return {int}
 */
export var findArray = function (array, value) {
    let index;
    if (array && array[0]) {
        array.map((v, k) => {
            if (v === value) {
                index = k
            }
            return ''
        })
    }
    return index
}




/**
 * 
 * @param {*} d Dec 31, 2018
 */
function dateConversionM1(d, dateLang, calendar) {
    let displayDate;
    if (dateLang != 'en') {
        displayDate = dateConversionLang("1", d, dateLang, calendar);
    } else {
        let date = new Date(d.replace(/-/g, '/'));
        let chinaDate = date.toDateString();
        let chinaDateArray = chinaDate.split(' ');
        let month = parseInt(chinaDateArray[2]);
        displayDate = `${chinaDateArray[1]} ${month}, ${chinaDateArray[3]}`;
    }
    return displayDate;
}

/**
 * 
 * @param {*} d Dec 31
 */
function dateConversionM2(d, dateLang, calendar) {
    let displayDate;
    if (dateLang != 'en') {
        displayDate = dateConversionLang("2", d, dateLang, calendar);
    } else {
        let date = new Date(d.replace(/-/g, '/'));
        let chinaDate = date.toDateString();
        let chinaDateArray = chinaDate.split(' ');
        let month = parseInt(chinaDateArray[2]);
        displayDate = `${chinaDateArray[1]} ${month}`;
    }
    return displayDate;
}

/**
 * 
 * @param {*} d Dec 31st 2018
 */
function dateConversionM3(d, dateLang, calendar) {
    let displayDate;
    if (dateLang != 'en') {
        displayDate = dateConversionLang("3", d, dateLang, calendar);
    } else {
        let w = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let date = new Date(d.replace(/-/g, '/'));
        let chinaDate = date.toDateString();
        let chinaDateArray = chinaDate.split(' ');
        let month = parseInt(chinaDateArray[2]);
        displayDate = `${w[date.getDay()]}, ${chinaDateArray[1]} ${month}`;
    }
    return displayDate;
}

/**
 * 
 * @param {*} d Mon, Dec 31st 2018
 */
function dateConversionM6(d, dateLang, calendar) {
    let displayDate;
    if (dateLang != 'en') {
        displayDate = dateConversionLang("6", d, dateLang, calendar);
    } else {
        let w = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        let date = new Date(d.replace(/-/g, '/'));
        let chinaDate = date.toDateString();
        let chinaDateArray = chinaDate.split(' ');
        let month = parseInt(chinaDateArray[2]);
        displayDate = `${w[date.getDay()]}, ${chinaDateArray[1]} ${month}`;
    }
    return displayDate;
}

function nth(d) {
    if (d > 3 && d < 21) return 'th'; // thanks kennebec
    switch (d % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

/**
 * 12/31/2018 (m/d/yyyy)
 * @param {*} date 
 * @param {*} days 
 * @param {*} seperator 
 */
function dateConversionM4(date, days, seperator = '-') {
    let oDate = new Date(date).valueOf();
    let nDate = oDate + days * 24 * 3600 * 1000;
    nDate = new Date(nDate);
    let y = nDate.getFullYear().toString().padStart(2, 0);
    let m = (nDate.getMonth() + 1).toString().padStart(2, 0);
    let d = nDate.getDate().toString().padStart(2, 0);
    return `${m}${seperator}${d}${seperator}${y}`
}

/**
 * 31/12/2018 (d/m/yyyy)
 * @param {*} date 
 * @param {*} days 
 * @param {*} seperator 
 */
function dateConversionM5(date, days, seperator = '-') {
    let oDate = new Date(date).valueOf();
    let nDate = oDate + days * 24 * 3600 * 1000;
    nDate = new Date(nDate);
    let y = nDate.getFullYear().toString().padStart(2, 0);
    let m = (nDate.getMonth() + 1).toString().padStart(2, 0);
    let d = nDate.getDate().toString().padStart(2, 0);
    return `${d}${seperator}${m}${seperator}${y}`
}

/**
 * 31/12/18 (d/m/yy)
 * @param {*} date 
 * @param {*} days 
 * @param {*} seperator 
 */
function dateConversionM7(date, days, seperator = '-') {
    let oDate = new Date(date).valueOf();
    let nDate = oDate + days * 24 * 3600 * 1000;
    nDate = new Date(nDate);
    let y = nDate.getFullYear().toString().padStart(2, 0).substr(2, 2);
    let m = (nDate.getMonth() + 1).toString().padStart(2, 0);
    let d = nDate.getDate().toString().padStart(2, 0);
    return `${d}${seperator}${m}${seperator}${y}`
}

/**
 * 31.12.2018 (d.m.yyyy)
 * @param {*} date 
 * @param {*} days 
 * @param {*} seperator 
 */
function dateConversionM8(date, days, seperator = '-') {
    let oDate = new Date(date).valueOf();
    let nDate = oDate + days * 24 * 3600 * 1000;
    nDate = new Date(nDate);
    let y = nDate.getFullYear().toString().padStart(2, 0);
    let m = (nDate.getMonth() + 1).toString().padStart(2, 0);
    let d = nDate.getDate().toString().padStart(2, 0);
    return `${d}${seperator}${m}${seperator}${y}`
}

/**
 * 31.12.18 (d.m.yy)
 * @param {*} date 
 * @param {*} days 
 * @param {*} seperator 
 */
function dateConversionM9(date, days, seperator = '-') {
    let oDate = new Date(date).valueOf();
    let nDate = oDate + days * 24 * 3600 * 1000;
    nDate = new Date(nDate);
    let y = nDate.getFullYear().toString().padStart(2, 0).substr(2, 2);
    let m = (nDate.getMonth() + 1).toString().padStart(2, 0);
    let d = nDate.getDate().toString().padStart(2, 0);
    return `${d}${seperator}${m}${seperator}${y}`
}

/**
 * 日期转换
 * @param {*} dateMin  2018-12-31
 * @param {*} dateMax  2020-12-31
 * @param {*} $mode  模式
 */
export var dateConversion = function (dateMin, dateMax, $mode = '1', dateLang = 'en', calendar = []) {
    let dMin = dateConversionM1(dateMin, dateLang, calendar);
    let dMax = dateConversionM1(dateMax, dateLang, calendar);
    switch ($mode) {
        case '1': // Dec 31, 2018
            dMin = dateConversionM1(dateMin, dateLang, calendar);
            dMax = dateConversionM1(dateMax, dateLang, calendar);
            break;
        case '2': // Dec 31
            dMin = dateConversionM2(dateMin, dateLang, calendar);
            dMax = dateConversionM2(dateMax, dateLang, calendar);
            break;
        case '3': // Dec 31st 2018
            dMin = dateConversionM3(dateMin, dateLang, calendar);
            dMax = dateConversionM3(dateMax, dateLang, calendar);
            break;
        case '4': // 12/31/2018 (m/d/yyyy)
            dMin = dateConversionM4(dateMin, 0, '/');
            dMax = dateConversionM4(dateMax, 0, '/');
            break;
        case '5': // 31/12/2018 (d/m/yyyy)
            dMin = dateConversionM5(dateMin, 0, '/');
            dMax = dateConversionM5(dateMax, 0, '/');
            break;
        case '6': // Mon, Dec 31st 2018
            dMin = dateConversionM6(dateMin, dateLang, calendar);
            dMax = dateConversionM6(dateMax, dateLang, calendar);
            break;
        case '7': // 31/12/18 (d/m/yy)
            dMin = dateConversionM7(dateMin, 0, '/');
            dMax = dateConversionM7(dateMax, 0, '/');
            break;
        case '8': // 31.12.2018 (d.m.yyyy)
            dMin = dateConversionM8(dateMin, 0, '.');
            dMax = dateConversionM8(dateMax, 0, '.');
            break;
        case '9': // 31.12.18 (d.m.yy)
            dMin = dateConversionM9(dateMin, 0, '.');
            dMax = dateConversionM9(dateMax, 0, '.');
            break;
        default:
            dMin = dateConversionM1(dateMin);
            dMax = dateConversionM1(dateMax);
            break;
    }

    return {
        'd_min': dMin,
        'd_max': dMax
    };
}


/**
 * 翻译日期转换
 * @param {*} dateMin  2018-12-31
 * @param {*} dateMax  2020-12-31
 * @param {*} $mode  模式
 */
export var dateConversionLang = function (type = "1", d, dateLang = 'en', calendar = []) {
    let displayDate;
    let date = new Date(d.replace(/-/g, '/'));
    let month = parseInt((date.getMonth() + 1).toString().padStart(2, 0));
    let chinaDate = date.toDateString();
    let chinaDateArray = chinaDate.split(' ');
    let day = parseInt(chinaDateArray[2]);
    let m = month - 1;
    let c = date.getDay() == 0 ? 6 : date.getDay() - 1;
    switch (dateLang) {
        case 'fr': // Dec 31, 2018
            switch (type) {
                case "1":
                    displayDate = `${day} ${calendar.fr.m_abbreviation[m]} ${chinaDateArray[3]}`;
                    break;
                case "2":
                    displayDate = `${day} ${calendar.fr.m_abbreviation[m]}`;
                    break;
                case "3":
                    displayDate = `${calendar.fr.c_full_text[c]}, ${day} ${calendar.fr.m_abbreviation[m]}`;
                    break;
                case "6":
                    displayDate = `${calendar.fr.c_abbreviation[c]}, ${day} ${calendar.fr.m_abbreviation[m]}`;
                    break;
                default:
            }
            break;
        case 'de': // Dec 31
            switch (type) {
                case "1":
                    displayDate = `${day}. ${calendar.de.m_abbreviation[m]} ${chinaDateArray[3]}`;
                    break;
                case "2":
                    displayDate = `${day}. ${calendar.de.m_abbreviation[m]}`;
                    break;
                case "3":
                    displayDate = `${calendar.de.c_full_text[c]}, ${day}. ${calendar.de.m_abbreviation[m]}`;
                    break;
                case "6":
                    displayDate = `${calendar.de.c_abbreviation[c]}, ${day}. ${calendar.de.m_abbreviation[m]}`;
                    break;
                default:
            }
            break;
        case 'es':
            switch (type) {
                case "1":
                    displayDate = `${day} de ${calendar.es.m_abbreviation[m]} de ${chinaDateArray[3]}`;
                    break;
                case "2":
                    displayDate = `${day} de ${calendar.es.m_abbreviation[m]}`;
                    break;
                case "3":
                    displayDate = `${calendar.es.c_full_text[c]}, ${day} de ${calendar.es.m_abbreviation[m]}`;
                    break;
                case "6":
                    displayDate = `${calendar.es.c_full_text[c]}, ${day} de ${calendar.es.m_abbreviation[m]}`;
                    break;
                default:
            }
            break;
        case 'it':
            switch (type) {
                case "1":
                    displayDate = `${day} ${calendar.it.m_abbreviation[m]} ${chinaDateArray[3]}`;
                    break;
                case "2":
                    displayDate = `${day} ${calendar.it.m_abbreviation[m]}`;
                    break;
                case "3":
                    displayDate = `${calendar.it.c_full_text[c]}, ${day} ${calendar.it.m_abbreviation[m]}`;
                    break;
                case "6":
                    displayDate = `${calendar.it.c_abbreviation[c]}, ${day} ${calendar.it.m_abbreviation[m]}`;
                    break;
                default:
            }
            break;
        case 'du':
            switch (type) {
                case "1":
                    displayDate = `${day}. ${calendar.du.m_abbreviation[m]} ${chinaDateArray[3]}`;
                    break;
                case "2":
                    displayDate = `${day}. ${calendar.du.m_abbreviation[m]}`;
                    break;
                case "3":
                    displayDate = `${calendar.du.c_full_text[c]}, ${day}. ${calendar.du.m_abbreviation[m]}`;
                    break;
                case "6":
                    displayDate = `${calendar.du.c_abbreviation[c]}, ${day}. ${calendar.du.m_abbreviation[m]}`;
                    break;
                default:
            }
            break;
        case 'zh_cn':
            switch (type) {
                case "1":
                    displayDate = `${chinaDateArray[3]}年${calendar.zh_cn.m_abbreviation[m]}月${day}日`;
                    break;
                case "2":
                    displayDate = `${calendar.zh_cn.m_abbreviation[m]}月${day}日`;
                    break;
                case "3":
                    displayDate = `${calendar.zh_cn.m_abbreviation[m]}月${day}日, ${calendar.zh_cn.c_full_text[c]}`;
                    break;
                case "6":
                    displayDate = `${calendar.zh_cn.c_abbreviation[c]}, ${calendar.zh_cn.m_abbreviation[m]}月${day}日`;
                    break;
                default:
            }
            break;
        case 'zh_tw':
            switch (type) {
                case "1":
                    displayDate = `${chinaDateArray[3]}年${calendar.zh_tw.m_abbreviation[m]}月${day}日`;
                    break;
                case "2":
                    displayDate = `${calendar.zh_tw.m_abbreviation[m]}月${day}日`;
                    break;
                case "3":
                    displayDate = `${calendar.zh_tw.m_abbreviation[m]}月${day}日, ${calendar.zh_tw.c_full_text[c]}`;
                    break;
                case "6":
                    displayDate = `${calendar.zh_tw.c_abbreviation[c]}, ${calendar.zh_tw.m_abbreviation[m]}月${day}日`;
                    break;
                default:
            }
            break;
        case 'bu':
            switch (type) {
                case "1":
                    displayDate = `${day} ${calendar.bu.m_abbreviation[m]} ${chinaDateArray[3]}`;
                    break;
                case "2":
                    displayDate = `${day} ${calendar.bu.m_abbreviation[m]}`;
                    break;
                case "3":
                    displayDate = `${calendar.bu.c_full_text[c]}, ${day} ${calendar.bu.m_abbreviation[m]}`;
                    break;
                case "6":
                    displayDate = `${calendar.bu.c_abbreviation[c]}, ${day} ${calendar.bu.m_abbreviation[m]}`;
                    break;
                default:
            }
            break;
        case 'pt':
        switch (type) {
            case "1":
                    displayDate = `${day} de ${calendar.pt.m_abbreviation[m]} de ${chinaDateArray[3]}`;
                    break;
                case "2":
                    displayDate = `${day} de ${calendar.pt.m_abbreviation[m]}`;
                    break;
                case "3":
                    displayDate = `${calendar.pt.c_full_text[c]}, ${day} de ${calendar.pt.m_abbreviation[m]}`;
                    break;
                case "6":
                    displayDate = `${calendar.pt.c_full_text[c]}, ${day} de ${calendar.pt.m_abbreviation[m]}`;
                    break;
                default:
        }
        break;
        case 'sh':
            switch (type) {
                case "1":
                    displayDate = `${calendar.sh.c_abbreviation[c]}, ${day} ${calendar.sh.m_abbreviation[m]}  ${chinaDateArray[3]}`;
                    break;
                case "2":
                    displayDate = `${day} ${calendar.sh.m_abbreviation[c]} ${chinaDateArray[3]}`;
                    break;
                case "3":
                    displayDate = `${day} ${calendar.sh.m_full_text[c]} ${chinaDateArray[3]}`;
                    break;
                case "6":
                    displayDate = `${chinaDateArray[3]}, ${calendar.sh.m_abbreviation[c]} ${day}`;
                    break;
                default:
            }
            break;
        case 'dh':
            switch (type) {
                case "1":
                    displayDate = `${calendar.dh.c_full_text[c]} den ${day} ${calendar.dh.m_full_text[m]}  ${chinaDateArray[3]}`;
                    break;
                case "2":
                    displayDate = `${day} ${calendar.dh.c_abbreviation[c]} ${chinaDateArray[3]}`;
                    break;
                case "3":
                    displayDate = `${day} ${calendar.dh.c_full_text[c]} ${chinaDateArray[3]}`;
                    break;
                case "6":
                    displayDate = `${chinaDateArray[3]} ${calendar.dh.c_abbreviation[c]} ${day}`;
                    break;
                default:
            }
            break;
        default:

    }
    return displayDate;
}

/**
 * 
 * @param {*} i 时区
 */
export let getLocalTime = function (i = "+08") {
    //参数i为时区值数字，比如北京为东八区则输进8,西5输入-5
    i = parseFloat(i);
    if (typeof i !== 'number') return;
    let d = new Date();
    //得到1970年一月一日到现在的秒数
    let len = d.getTime();
    //本地时间与GMT时间的时间偏移差
    let offset = d.getTimezoneOffset() * 60000;
    //得到现在的格林尼治时间
    let utcTime = len + offset;
    return new Date(utcTime + 3600000 * i);
}


/**
 * 不足位补0
 * 
 * @param {*} num  需要补零的数值
 * @param {*} len  补零后的总位数
 */
export var formatZero = function (num, len) {
    if (String(num).length > len) return num;
    return (Array(len).join(0) + num).slice(-len);
}

export var testJs = function (id_name,tr_id_name, status) {
    if(id_name != tr_id_name){
        if(status != true){
            if(tr_id_name != 'null' && document.getElementById(tr_id_name) != null){
               
                var tr_name = document.getElementById(tr_id_name).parentNode;
                // if(hasClass(tr_name.parentNode,'tr-background')){
                    tr_name.parentNode.className = 'Polaris-DataTable__TableRow';
                    var listPid = document.getElementById(id_name).parentNode;
                    listPid.parentNode.className = 'tr-background';
                // }
            }else{
                var listPid = document.getElementById(id_name).parentNode;
                listPid.parentNode.className = 'tr-background';
            }
        }else{
            var tr = document.getElementsByClassName("tr-background");
            if(typeof tr[0] != 'undefined'){
                tr[0].className = 'Polaris-DataTable__TableRow';
            }
        }
    }
}

export function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}

export var hasClass = function (ele, cls) {
    cls = cls || '';
    if (cls.replace(/\s/g, '').length == 0) return false;
    return new RegExp(' ' + cls + ' ').test(' ' + ele.className + ' ');
}


