/** 主包 */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

/** 组件包 */
// 公共组件
import TabBtn from '../../components/tab-btn/index';

// 一级组件包
import Dash from '../dash/index';
import DeliveryZone from '../delivery-zone/index';
import Product from '../product/index';
import Plan from '../plan/index';
import Faq from '../faq/index';
import NotFound from '../not-found/not-found';
import CookiesNotFound from '../cookies-not-found/cookies-not-found';

/** 样式 */
import './admin.less';
import fun from '../../utils/fun';

class Admin extends Component {
    constructor(props) {
        super(props);
        
        // 接收用户授权安装传递的核心参数
        // if (this.props.location.search) {
        //     var paramsUrl = this.props.location.search
            // console.info(paramsUrl)
        // }
    }

    supportLocalStorage() {
       
        /*alert(navigator.userAgent);*/
        if (navigator.userAgent.indexOf('UCBrowser') > -1) {
            return false;
        }
        var uaFlag = 0;
        var uaArr = new Array('Chrome', 'MQQBrowser', 'QQ', 'TBS', 'wxwork', 'MicroMessenger', 'T7', 'baiduboxapp', 'baidubrowser', 'MiuiBrowser', 'NetType', 'OPR');
        for (var i = 0; i < uaArr.length; i++) {
            if (navigator.userAgent.indexOf(uaArr[i]) > -1) {
                uaFlag = 1;
            }
        }
        if (uaFlag != 1) {
            if (navigator.userAgent.indexOf('HUAWEIEVA') > -1 || navigator.userAgent.indexOf('HUAWEIVTR') > -1) {
                return false;
            }
        }
        return true;
        // var testKey = 'test';
        // try {
        //     window.localStorage.setItem(testKey, 'testValue');
        //     window.localStorage.removeItem(testKey);
        //     return true;
        // } catch (e) {
        //     return false;
        // }
    }

    render() {

        return (
            <div className="et-admin">
                {
                    this.supportLocalStorage() == true
                        ?
                        <div className="et-tab-tabbtn">
                            <TabBtn />
                        </div>
                        :
                        ''
                }
                <Switch>
                    <Redirect from='/' exact to='/dash' />

                    {/* Dashboard 模块 */}
                    <Route path='/dash' component={Dash} />
                    {/* Delivery Zones 模块 */}
                    <Route path='/delivery_zone' component={DeliveryZone} />
                    {/* Products 模块 */}
                    <Route path='/product' component={Product} />
                    {/* Plan 模块 */}
                    <Route path='/plan' component={Plan} />
                    {/* Faq 模块 */}
                    <Route path='/faq' component={Faq} />

                    <Route path='/notfound' component={NotFound} />

                    <Route path='/cookiesnotfound' component={CookiesNotFound} />
                    <Route component={NotFound} />
                </Switch>
            </div>
        )
    }
}


export default Admin;