import React, { Component } from "react";
import { Tag, DatePicker, Select } from "antd";
import moment from "moment";
import * as R from "ramda";
import "./index.css";

class MultipleDatePicker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected_date: this.props.dateData,
      selectProps: {},
      format: "YYYY-MM-DD",
      datePickerProps: {},
      date_open: false
    }

  }
  // 内存泄漏标志
  _isMounted = true;


  getTimestamp(value) {
    return value.startOf("day").valueOf();
  }
  onValueChange = (date) => {
    let selectedDate = this.state.selected_date;
    const t = this.getTimestamp(date);
    const index = selectedDate.indexOf(t);
    const clone = R.clone(selectedDate);
    if (index > -1) {
      clone.splice(index, 1);
    } else {
      clone.push(t);
    }
    if (this._isMounted) {
      this.setState({ selected_date: clone });
    }
    this.props.dateUpdateFilter(clone);
  };

  dateRender = (currentDate) => {
    let selectedDate = this.state.selected_date;
    const isSelected = selectedDate.indexOf(this.getTimestamp(currentDate)) > -1;
    return (
      <div
        className={"ant-picker-cell-inner"}
        style={
          isSelected
            ? {
              position: "relative",
              zIndex: 2,
              display: "inlineBlock",
              width: "24px",
              height: "22px",
              lineHeight: "22px",
              backgroundColor: "#1890ff",
              color: "#fff",
              margin: "auto",
              borderRadius: "2px",
              transition: "background 0.3s, border 0.3s"
            }
            : {
              backgroundColor: "#FFF",
              color: "rgba(0, 0, 0, 0.85)",
            }
        }
      >
        {currentDate.date()}
      </div>
    );
  };

  renderTag = ({ value, onClose }) => {
    let selectedDate = this.state.selected_date;
    const handleClose = () => {
      onClose();
      const index = selectedDate.indexOf(value);
      const clone = R.clone(selectedDate);
      if (index > -1) {
        clone.splice(index, 1);
      }
      this.setState({ selected_date: clone });
      this.props.dateUpdateFilter(clone);
    };
    return (
      <Tag onClose={handleClose} closable>
        {moment(value).format(this.state.format)}
      </Tag>
    );
  };
  render() {
    return (
      <Select
        allowClear
        placeholder={"YYYY-MM-DD"}
        {...this.state.selectProps}
        mode="multiple"
        value={this.state.selected_date}
        onClear={() => { this.setState({ selected_date: [] }); this.props.dateUpdateFilter([]); }}
        tagRender={this.renderTag}
        open={this.state.date_open}
        onFocus={() => { this.setState({ date_open: true }); }}
        onBlur={() => { this.setState({ date_open: false }); }}
        dropdownMatchSelectWidth={false}
        dropdownClassName={"multipleDropdownClassName"}
        dropdownStyle={{ height: "270px", width: "280px", minWidth: "0" }}
        dropdownRender={() => {
          return (
            <DatePicker
              {...this.state.datePickerProps}
              format={(value) => ""}
              onChange={this.onValueChange}
              value={""}
              showToday={false}
              open
              dateRender={this.dateRender}
              style={{ ...this.state.datePickerProps.style, visibility: "hidden" }}
              getPopupContainer={() =>
                document.getElementsByClassName("multipleDropdownClassName")[0]
              }
            />
          );
        }}
      />
    );
  }
}


export default MultipleDatePicker;