/** 主包 */
import React, { Component } from "react";
import {
  Layout,
  Card,
  Modal,
  TextContainer,
  TextField,
  Button,
  Select,
  RadioButton,
  Tooltip,
  Icon
} from "@shopify/polaris";

import { Editor, fontSize } from "react-draft-wysiwyg";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  italic,
  bold,
  underline,
  strikethrough,
  color,
  link,
  unlink,
  undo,
  redo,
  emoji,
  Modifier,
  left,
  center,
  right,
  justify,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { QuestionMarkMinor } from "@shopify/polaris-icons";

/** 工具组件包 */
import { formatZero } from "../../../../utils/fun";

/** 样式 */
import "./edit-setting.less";

class EditSettings extends Component {
  constructor(props) {
    super(props);
    const blocksFromHtml = htmlToDraft(props.content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);

    this.state = {
      editorStateV: editorState,
      content_default: "",
      postions: [],
      postions_default: [],
      initDateV: "1",
      initTimeH: props.initTimeH || "0",
      initTimeM: props.initTimeM || "0",
      initTimeF: props.initTimeF || "0",
      initOutOfStockMode: props.initOutOfStockMode || "0",
      initOutOfStockContent: props.initOutOfStockContent || "",
      initOutOfStockCustomContent: props.initOutOfStockCustomContent || "",
      dateLangList: props.dateLangList || [],
      timeFormAts: props.timeFormAts || [],
      dateLang: props.dateLang,
      is_template_active: false,
      templateId: props.determineTemplateId,
      OutOfStockStatus: props.OutOfStockStatus || 1,
      determineTemplateId: props.determineTemplateId,
    };
  }

  // 内存泄漏标志
  _isMounted = true;

  UNSAFE_componentWillReceiveProps(nextProps) {
    let labels = ["Top", "Bottom", "Left", "Right"];
    let positionArr = [];
    nextProps.positions.map((v, i) => {
      positionArr[i] = { label: labels[i], value: v };
      return "";
    });

    if (this._isMounted) {
      this.setState({
        postions: positionArr,
        initDateV: nextProps.mode,
        initTimeH: nextProps.initTimeH,
        initTimeM: nextProps.initTimeM,
        initTimeF: nextProps.initTimeF,
        initOutOfStockMode: nextProps.initOutOfStockMode,
        initOutOfStockContent: nextProps.initOutOfStockContent,
        initOutOfStockCustomContent: nextProps.initOutOfStockCustomContent,
        OutOfStockStatus: nextProps.OutOfStockStatus,
        dateLang: nextProps.dateLang,
        templateId: nextProps.determineTemplateId,
        determineTemplateId: nextProps.determineTemplateId,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onEditorStateChange = (editorState) => {
    if (this._isMounted) {
      this.setState({ editorStateV: editorState });
    }
    let str = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let content = str.replace("font-family: Font;", "");
    this.props.updateSaveOrDiscardComponent("edit-setting", content);
  };

  /**
   * 处理位置改变
   *
   * @param {*} i
   * @param {*} newValue
   * @param {*} mark
   */
  handlePostionChange = (i, newValue, mark) => {
    let postion = this.state.postions;
    postion[i].value = newValue;
    if (this._isMounted) {
      this.setState({ postion });
    }

    let postionArr = [];
    this.state.postions.map((v, i) => {
      postionArr[i] = v.value;
      return "";
    });

    this.props.updateSaveOrDiscardComponent("postion", postionArr);
  };

  /** 光标后增加内容 start */
  setEditor = (editor) => {
    this.editor = editor;
  };

  focusEditor = () => {
    if (this.editor) {
      this.editor.focusEditor();
    }
  };

  sendTextToEditor = (text) => {
    if (this._isMounted) {
      this.setState({
        editorStateV: this.insertText(text, this.state.editorStateV),
      });
    }
    this.onEditorStateChange(this.insertText(text, this.state.editorStateV));
    this.focusEditor();
  };

  insertText = (text, editorState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };
  /** 光标后增加内容 end */

  handleSelectChange = (v) => {
    if (this._isMounted) {
      this.setState({ initDateV: v });
    }

    this.props.updateSaveOrDiscardComponent("mode", [v]);
  };

  handleDateSelectChange = (v) => {
    if (this._isMounted) {
      this.setState({ initDateV: v });
    }

    this.props.updateSaveOrDiscardComponent("dateLang", [v]);
  };

  handleSelectTimeHourChange = (v) => {
    if (this._isMounted) {
      this.setState({ initTimeH: v });
    }

    this.props.updateSaveOrDiscardComponent("initTimeH", [v]);
  };

  handleSelectTimeMinuteChange = (v) => {
    if (this._isMounted) {
      this.setState({ initTimeM: v });
    }

    this.props.updateSaveOrDiscardComponent("initTimeM", [v]);
  };

  handleSelectTimeFormatChange = (v) => {
    if (this._isMounted) {
      this.setState({ initTimeF: v });
    }

    this.props.updateSaveOrDiscardComponent("initTimeF", [v]);
  };

  handleOutOfStockSelectChange = (v) => {
    if (this._isMounted) {
      this.setState({ initOutOfStockMode: v });
    }

    this.props.updateSaveOrDiscardComponent("initOutOfStockMode", [v]);
  };

  handleOutOfStockCustomContentChange = (v) => {
    if (this._isMounted) {
      this.setState({ initOutOfStockCustomContent: v });
    }

    this.props.updateSaveOrDiscardComponent("initOutOfStockCustomContent", [v]);
  };

  handleOutOfStockStatusChange = (v) => {
    if (this._isMounted) {
      this.setState({ outOfStockStatus: v });
    }

    this.props.updateSaveOrDiscardComponent("OutOfStockStatus", [v]);
  };

  /**
   * 激活模板选择弹窗
   */
  activeTemplateModal = () => {
    if (this._isMounted) {
      this.setState({ is_template_active: !this.state.is_template_active });
    }
  };
  /**
   * 关闭模板选择弹窗
   */
  closeTemplateModel = () => {
    if (this._isMounted) {
      this.setState({ is_template_active: !this.state.is_template_active });
    }
  };
  /**
   * 选择信息模板
   */
  changeTemplateModel = (v) => {
    if (this._isMounted) {
      this.setState({ templateId: v });
    }
  };
  /*
   *  确定信息模板
   */
  determine_template = (v) => {
    if (this._isMounted) {
      this.setState({ is_template_active: !this.state.is_template_active });
    }
    this.props.updateSaveOrDiscardComponent("determineTemplateId", [
      this.state.templateId,
    ]);
  };

  render() {
    const postion = this.state.postions.map((v, i) => {
      return (
        <div key={i}>
          <span>{v.label}</span>
          <TextField
            value={v.value}
            onChange={this.handlePostionChange.bind(this, i)}
            autoComplete="off"
            aria-invalid="false"
            align="center"
            type="number"
          />
        </div>
      );
    });
    const dateLangSign = ["en", "fr", "de", "es", "it", "du", "zh_cn", "zh_tw", 'bu', 'pt', 'sh','dh'];
    const dateTimeList = [];
    const dateLangList = this.state.dateLangList;
    const timeFormAtsApi = this.state.timeFormAts;

    // this.state.dateLangList.map((v, i) => {
    //     console.info(i);
    // })
    const dateLang = this.state.dateLang;
    let date_lang_text_1 = "Dec 31, 2018";
    let date_lang_text_2 = "Dec 31";
    let date_lang_text_3 = "Monday, Dec 31";
    let date_lang_text_6 = "Mon, Dec 31";
    if (dateLang == "fr") {
      date_lang_text_1 = "31 déc 2018";
      date_lang_text_2 = "31 déc";
      date_lang_text_3 = "lundi, 31 déc";
      date_lang_text_6 = "lun, 31 déc";
    } else if (dateLang == "de") {
      date_lang_text_1 = "31. Dez 2018";
      date_lang_text_2 = "31. Dez";
      date_lang_text_3 = "Montag, 31. Dez";
      date_lang_text_6 = "Mo, 31. Dez";
    } else if (dateLang == "es") {
      date_lang_text_1 = "31 de dic de 2018";
      date_lang_text_2 = "31 de dic";
      date_lang_text_3 = "lunes, 31 de dic";
      date_lang_text_6 = "lunes, 31 de dic";
    } else if (dateLang == "it") {
      date_lang_text_1 = "31 Dicembre 2018";
      date_lang_text_2 = "31 Dicembre";
      date_lang_text_3 = "Lunedì 31 dic";
      date_lang_text_6 = "lun 31 dic";
    } else if (dateLang == "du") {
      date_lang_text_1 = "31 december 2018";
      date_lang_text_2 = "31 december";
      date_lang_text_3 = "maandag 31 dec";
      date_lang_text_6 = "ma 31 dec";
    } else if (dateLang == "zh_cn") {
      date_lang_text_1 = "2018年12月31日";
      date_lang_text_2 = "12月31日";
      date_lang_text_3 = "12月31日，星期一";
      date_lang_text_6 = "周一，12月31日";
    } else if (dateLang == "zh_tw") {
      date_lang_text_1 = "2018年12月31日";
      date_lang_text_2 = "12月31日";
      date_lang_text_3 = "12月31日，星期一";
      date_lang_text_6 = "週一，12月31日";
    } else if (dateLang == "bu") {
      date_lang_text_1 = "31 декември 2018 г";
      date_lang_text_2 = "31 декември";
      date_lang_text_3 = "понеделник, 31 декември";
      date_lang_text_6 = "31 декември, понеделник";
    } else if (dateLang == "pt") {
      date_lang_text_1 = "31 de janeiro de 2018";
      date_lang_text_2 = "31 de Janeiro";
      date_lang_text_3 = "1 de Janeiro";
      date_lang_text_6 = "Janeiro de 1";
    } else if (dateLang == "sh") {
      date_lang_text_1 = "mån, 21 feb 2022";
      date_lang_text_2 = "21 feb 2022";
      date_lang_text_3 = "21 februari 2022";
      date_lang_text_6 = "2022, feb 21";
    } else if (dateLang == "dh") {
      date_lang_text_1 = "Mandag den 29. Februar 2022";
      date_lang_text_2 = "21 Sep 2022";
      date_lang_text_3 = "21 September 2022";
      date_lang_text_6 = "2022 Sep 21";
    } 

    const options = [
      { label: date_lang_text_1, value: "1" },
      { label: date_lang_text_2, value: "2" },
      { label: date_lang_text_3, value: "3" },
      { label: date_lang_text_6, value: "6" },
      { label: "12/31/2018 (m/d/yyyy)", value: "4" },
      { label: "31/12/2018 (d/m/yyyy)", value: "5" },
      { label: "31/12/18 (d/m/yy)", value: "7" },
      { label: "31.12.2018 (d.m.yyyy)", value: "8" },
      { label: "31.12.18 (d.m.yy)", value: "9" },
    ];

    for (let i = 0; i <= 11; i++) {
      let sign = dateLangSign[i];
      let item = { label: dateLangList[sign].name, value: dateLangSign[i] };
      dateTimeList.push(item);
    }
    // 小时
    let timeHours = [];
    for (let i = 0; i <= 23; i++) {
      let item = {};
      item.label = formatZero(i, 2);
      item.value = String(i);
      timeHours.push(item);
    }
    // 分钟
    const timeMinutes = [];
    for (let i = 0; i <= 59; i++) {
      let item = {};
      item.label = formatZero(i, 2);
      item.value = String(i);
      timeMinutes.push(item);
    }
    let timeformats = [];
    if (typeof timeFormAtsApi[dateLang] != "undefined") {
      timeformats = [
        {
          label:
            timeFormAtsApi[dateLang][0] +
            ", " +
            timeFormAtsApi[dateLang][1] +
            ", " +
            timeFormAtsApi[dateLang][2],
          value: "0",
        },
        {
          label:
            timeFormAtsApi[dateLang][0] + ", " + timeFormAtsApi[dateLang][1],
          value: "1",
        },
        {
          label:
            timeFormAtsApi[dateLang][3] +
            ", " +
            timeFormAtsApi[dateLang][4] +
            ", " +
            timeFormAtsApi[dateLang][5],
          value: "2",
        },
        {
          label:
            timeFormAtsApi[dateLang][3] + ", " + timeFormAtsApi[dateLang][4],
          value: "3",
        },
        {
          label:
            timeFormAtsApi[dateLang][6] +
            ", " +
            timeFormAtsApi[dateLang][7] +
            ", " +
            timeFormAtsApi[dateLang][8],
          value: "4",
        },
        {
          label:
            timeFormAtsApi[dateLang][9] +
            ", " +
            timeFormAtsApi[dateLang][10] +
            ", " +
            timeFormAtsApi[dateLang][11],
          value: "5",
        },
      ];
    } else {
      // 时间格式
      timeformats = [
        { label: "Hrs, Mins, Secs", value: "0" },
        { label: "Hrs, Mins", value: "1" },
        { label: "Hours, Minutes, Seconds", value: "2" },
        { label: "Hours, Minutes", value: "3" },
        { label: "hours, minutes, seconds", value: "4" },
        { label: "H, M, S", value: "5" },
      ];
    }
    // stock 文本格式
    const outOfStockformats = [
      { label: "None", value: "0" },
      {
        label:
          "Order now, and we will ship your order as soon as it's in stock!",
        value: "1",
      },
      { label: "Expected back into stock within 48 hours", value: "2" },
      { label: "Custom", value: "3" },
    ];

    return (
      <div className="et-dash-edit-setting">
        <Layout>
          <Layout.Section>
            <Card
              title="Widget settings"
              actions={[
                { content: "Theme", onAction: this.activeTemplateModal },
              ]}
              sectioned
            >
              <div className="editor">
                <Editor
                  ref={this.setEditor}
                  // editorState={editorState}
                  editorState={this.state.editorStateV}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{
                    options: [
                      "fontFamily",
                      "fontSize",
                      "inline",
                      "colorPicker",
                      "link",
                      "emoji",
                      "textAlign",
                      "history",
                    ],
                    inline: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ["bold", "italic", "underline", "strikethrough"],
                      bold: { icon: bold, className: undefined },
                      italic: { icon: italic, className: undefined },
                      underline: { icon: underline, className: undefined },
                      strikethrough: {
                        icon: strikethrough,
                        className: undefined,
                      },
                    },
                    fontSize: {
                      icon: fontSize,
                      options: [
                        8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72,
                        96,
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                    fontFamily: {
                      options: [
                        "Font",
                        "Arial",
                        "Georgia",
                        "Impact",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                    colorPicker: {
                      icon: color,
                      className: undefined,
                      component: undefined,
                      popupClassName: undefined,
                      colors: [
                        "rgb(97,189,109)",
                        "rgb(26,188,156)",
                        "rgb(84,172,210)",
                        "rgb(44,130,201)",
                        "rgb(147,101,184)",
                        "rgb(71,85,119)",
                        "rgb(204,204,204)",
                        "rgb(65,168,95)",
                        "rgb(0,168,133)",
                        "rgb(61,142,185)",
                        "rgb(41,105,176)",
                        "rgb(85,57,130)",
                        "rgb(40,50,78)",
                        "rgb(0,0,0)",
                        "rgb(247,218,100)",
                        "rgb(251,160,38)",
                        "rgb(235,107,86)",
                        "rgb(226,80,65)",
                        "rgb(163,143,132)",
                        "rgb(239,239,239)",
                        "rgb(255,255,255)",
                        "rgb(250,197,28)",
                        "rgb(243,121,52)",
                        "rgb(209,72,65)",
                        "rgb(184,49,47)",
                        "rgb(124,112,107)",
                        "rgb(209,213,216)",
                      ],
                    },
                    link: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      popupClassName: undefined,
                      dropdownClassName: undefined,
                      showOpenOptionOnHover: true,
                      defaultTargetOption: "_self",
                      options: ["link", "unlink"],
                      link: { icon: link, className: undefined },
                      unlink: { icon: unlink, className: undefined },
                      linkCallback: undefined,
                    },
                    emoji: {
                      icon: emoji,
                      className: undefined,
                      component: undefined,
                      popupClassName: undefined,
                      emojis: [
                        "😀",
                        "😁",
                        "😂",
                        "😃",
                        "😉",
                        "😋",
                        "😎",
                        "😍",
                        "😗",
                        "🤗",
                        "🤔",
                        "😣",
                        "😫",
                        "😴",
                        "😌",
                        "🤓",
                        "😛",
                        "😜",
                        "😠",
                        "😇",
                        "😷",
                        "😈",
                        "👻",
                        "😺",
                        "😸",
                        "😹",
                        "😻",
                        "😼",
                        "😽",
                        "🙀",
                        "🙈",
                        "🙉",
                        "🙊",
                        "👼",
                        "👮",
                        "🕵",
                        "💂",
                        "👳",
                        "🎅",
                        "👸",
                        "👰",
                        "👲",
                        "🙍",
                        "🙇",
                        "🚶",
                        "🏃",
                        "💃",
                        "⛷",
                        "🏂",
                        "🏌",
                        "🏄",
                        "🚣",
                        "🏊",
                        "⛹",
                        "🏋",
                        "🚴",
                        "👫",
                        "💪",
                        "👈",
                        "👉",
                        "👉",
                        "👆",
                        "🖕",
                        "👇",
                        "🖖",
                        "🤘",
                        "🖐",
                        "👌",
                        "👍",
                        "👎",
                        "✊",
                        "👊",
                        "👏",
                        "🙌",
                        "🙏",
                        "🐵",
                        "🐶",
                        "🐇",
                        "🐥",
                        "🐸",
                        "🐌",
                        "🐛",
                        "🐜",
                        "🐝",
                        "🍉",
                        "🍄",
                        "🍔",
                        "🍤",
                        "🍨",
                        "🍪",
                        "🎂",
                        "🍰",
                        "🍾",
                        "🍷",
                        "🍸",
                        "🍺",
                        "🌍",
                        "🚑",
                        "⏰",
                        "🌙",
                        "🌝",
                        "🌞",
                        "⭐",
                        "🌟",
                        "🌠",
                        "🌨",
                        "🌩",
                        "⛄",
                        "🔥",
                        "🎄",
                        "🎈",
                        "🎉",
                        "🎊",
                        "🎁",
                        "🎗",
                        "🏀",
                        "🏈",
                        "🎲",
                        "🔇",
                        "🔈",
                        "📣",
                        "🔔",
                        "🎵",
                        "🎷",
                        "💰",
                        "🖊",
                        "📅",
                        "✅",
                        "❎",
                        "💯",
                        "💸",
                        "♻",
                        "🤝",
                        "📱",
                        "🚚"
                      ],
                    },
                    textAlign: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ["left", "center", "right", "justify"],
                      left: { icon: left, className: undefined },
                      center: { icon: center, className: undefined },
                      right: { icon: right, className: undefined },
                      justify: { icon: justify, className: undefined },
                    },
                    history: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ["undo", "redo"],
                      undo: { icon: undo, className: undefined },
                      redo: { icon: redo, className: undefined },
                    },
                  }}
                />
              </div>

              <div className="variable-btn">
                <Button onClick={this.sendTextToEditor.bind(this, "{flag}")}>
                  Add flag
                </Button>
                <Button onClick={this.sendTextToEditor.bind(this, "{country}")}>
                  Add country
                </Button>
                <Button onClick={this.sendTextToEditor.bind(this, "{region}")}>
                  Add region
                </Button>
                <Button
                  onClick={this.sendTextToEditor.bind(
                    this,
                    "{delivery_date_min}"
                  )}
                >
                  Add delivery date min
                </Button>
                <Button
                  onClick={this.sendTextToEditor.bind(
                    this,
                    "{delivery_date_max}"
                  )}
                >
                  Add delivery date max
                </Button>
                <Button onClick={this.sendTextToEditor.bind(this, "{timer}")}>
                  Add timer
                </Button>
              </div>

              <div className="position">
                <div>
                  <h2>Padding</h2>
                </div>
                <div className="math">{postion}</div>
              </div>

              <div className="datetime">
                <div className="datetime-format">
                  <div>
                    <h2>Date format</h2>
                  </div>
                  <div className="selected">
                    <Select
                      options={options}
                      onChange={this.handleSelectChange}
                      value={this.state.initDateV}
                    />
                  </div>
                </div>
                <div className="datetime-lang">
                  <div>
                    <h2>Date language</h2>
                  </div>
                  <div className="selected">
                    <Select
                      options={dateTimeList}
                      onChange={this.handleDateSelectChange}
                      value={this.state.dateLang}
                    />
                  </div>
                </div>
              </div>
              <div className="countdown">
                <div className="countdown-time">
                  <div>
                    <h2>Select cutoff time</h2>
                  </div>
                  <div className="countdown-time-select">
                    <div className="selected-left">
                      <Select
                        options={timeHours}
                        onChange={this.handleSelectTimeHourChange}
                        value={this.state.initTimeH}
                      />
                    </div>
                    <div className="time-colon">:</div>
                    <div className="selected-right">
                      <Select
                        options={timeMinutes}
                        onChange={this.handleSelectTimeMinuteChange}
                        value={this.state.initTimeM}
                      />
                    </div>
                  </div>
                </div>
                <div className="countdown-time-format">
                  <div>
                    <h2>Time format</h2>
                  </div>
                  <div className="selected">
                    <Select
                      options={timeformats}
                      onChange={this.handleSelectTimeFormatChange}
                      value={this.state.initTimeF}
                    />
                  </div>
                </div>
              </div>

              <div className="out-of-stock">
                <div>
                  <h2>Out of stock message</h2>
                </div>
                <div className="edit-setting-radio">
                  <div className="edit-stting-doubt">
                    <RadioButton
                      label="Default"
                      checked={this.state.OutOfStockStatus === 1}
                      id="outOfStockStatus1"
                      name="accounts"
                      onChange={this.handleOutOfStockStatusChange.bind(this, 1)}
                    />
                    <Tooltip  preferredPosition="above"  content="Show normal delivery date when 
                                      the 'Continue selling when out of stock' is checked, 
                                      otherwise, show Out of stock message">
                      <Icon
                      source={QuestionMarkMinor}
                       />
                    </Tooltip>
                  </div>
                  <div>
                    <RadioButton
                      label="Always message"
                      checked={this.state.OutOfStockStatus === 2}
                      id="outOfStockStatus2"
                      name="accounts"
                      onChange={this.handleOutOfStockStatusChange.bind(this, 2)}
                    />
                  </div>
                  <div>
                    <RadioButton
                      label="Always delivery date"
                      checked={this.state.OutOfStockStatus === 3}
                      id="outOfStockStatus3"
                      name="accounts"
                      onChange={this.handleOutOfStockStatusChange.bind(this, 3)}
                    />
                  </div>
                </div>
                {this.state.OutOfStockStatus === 3 ? (
                  ""
                ) : (
                  <div className="out-of-stock-select">
                    <Select
                      options={outOfStockformats}
                      onChange={this.handleOutOfStockSelectChange}
                      value={this.state.initOutOfStockMode}
                    />
                  </div>
                )}
                {this.state.initOutOfStockMode === "3" &&
                this.state.OutOfStockStatus !== 3 ? (
                  <div className="out-of-stock-message">
                    <TextField
                      value={this.state.initOutOfStockCustomContent}
                      onChange={this.handleOutOfStockCustomContentChange}
                      multiline={1}
                      placeholder="Edit your custom message here..."
                    />
                  </div>
                ) : null}
              </div>
            </Card>
          </Layout.Section>
        </Layout>

        <Modal
          open={this.state.is_template_active}
          onClose={this.closeTemplateModel}
          title="Theme"
          primaryAction={{
            content: "Save",
            onAction: this.determine_template,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: this.closeTemplateModel,
            },
          ]}
        >
          <div className="info-template-population">
            <Modal.Section>
              <TextContainer>
                <div
                  className="info-template"
                  style={{
                    backgroundColor:
                      this.state.templateId == 1 ? "#F6F6F7" : "",
                  }}
                  onClick={this.changeTemplateModel.bind(this, 1)}
                >
                  <div className="info-template-sum">
                    <img src="/image/5.png"></img>
                  </div>
                </div>
              </TextContainer>
            </Modal.Section>
            <Modal.Section>
              <TextContainer>
                <div
                  className="info-template"
                  style={{
                    backgroundColor:
                      this.state.templateId == 2 ? "#F6F6F7" : "",
                  }}
                  onClick={this.changeTemplateModel.bind(this, 2)}
                >
                  <div className="info-template-sum">
                    <img src="/image/1.png"></img>
                  </div>
                </div>
              </TextContainer>
            </Modal.Section>
            <Modal.Section>
              <TextContainer>
                <div
                  className="info-template"
                  style={{
                    backgroundColor:
                      this.state.templateId == 3 ? "#F6F6F7" : "",
                  }}
                  onClick={this.changeTemplateModel.bind(this, 3)}
                >
                  <div className="info-template-sum">
                    <img src="/image/4.png"></img>
                  </div>
                </div>
              </TextContainer>
            </Modal.Section>
            <Modal.Section>
              <TextContainer>
                <div
                  className="info-template"
                  style={{
                    backgroundColor:
                      this.state.templateId == 4 ? "#F6F6F7" : "",
                  }}
                  onClick={this.changeTemplateModel.bind(this, 4)}
                >
                  <div className="info-template-sum">
                    <img src="/image/2.png"></img>
                  </div>
                </div>
              </TextContainer>
            </Modal.Section>
            <Modal.Section>
              <TextContainer>
                <div
                  className="info-template"
                  style={{
                    backgroundColor:
                      this.state.templateId == 5 ? "#F6F6F7" : "",
                  }}
                  onClick={this.changeTemplateModel.bind(this, 5)}
                >
                  <div className="info-template-sum">
                    <img src="/image/3.png"></img>
                  </div>
                </div>
              </TextContainer>
            </Modal.Section>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EditSettings;
