/** 主包 */
import React, { Component } from "react";
import {
  Card,
  Tabs,
  AppProvider,
  Frame,
  ContextualSaveBar,
  Toast,
  Button,
  Popover,
  FormLayout,
  Select
} from "@shopify/polaris";

/** 组件包 */
import ProductList from "./components/products/product_list";
import ProductEdit from "./components/products-edit/products_edit";
import CollectionList from "./components/collections/collection_list";
import storageUtils from "../../utils/storageUtils";
import { editProductInfo, syncProductALLData, syncCollectionALLData } from "../../api/index";

/** 样式 */
import "./index.less";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      is_show_product: false,
      is_show_collection: false,
      product_data: [],
      is_show_changes: false,
      save_is_load: false,
      is_editor: false,
      isFlushData: false,
      is_pId: [],
      is_sync_product: false,
      is_sync_collection: false,
      second: 0,
      c_second: 0,
      product_change_data: {
        pId: 0,
        index: 0,
        pTitle: '',
        isHideEdt: 0,
        productContent: "",
        outOfStockMode: "0",
        outOfStockContent: "",
        pDeliveryTimeMin: 0,
        pDeliveryTimeMax: 1,
      },
      product_change_data_defalt: {
        pId: 0,
        index: 0,
        pTitle: '',
        isHideEdt: 0,
        productContent: "",
        outOfStockMode: "0",
        outOfStockContent: "",
        pDeliveryTimeMin: 0,
        pDeliveryTimeMax: 1,
      },

      is_notify: false,
      notify_content: "",
    };
  }
  // 内存泄漏标志
  _isMounted = true;
  UNSAFE_componentWillMount() {
    let time = Date.now();
    if (time < storageUtils.get('edtSecond')) {
      let now_time = parseInt((storageUtils.get('edtSecond') - time) / 1000);
      this.setState({
        second: now_time
      })
      this.id = setInterval(this.startSecond, 1000)
    }

    if (time < storageUtils.get('c_edtSecond')) {
      let c_now_time = parseInt((storageUtils.get('c_edtSecond') - time) / 1000);
      this.setState({
        c_second: c_now_time
      })
      this.c_id = setInterval(this.CstartSecond, 1000)
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleTabChange = (selectedTabIndex) => {
    if (this._isMounted) {
      this.setState({
        selected: selectedTabIndex,
        is_show_product: false,
        is_show_collection: false,
      });
    }
  };

  changeCollectionOrProduct = (selected) => {
    if (this._isMounted) {
      this.setState({
        is_sync_product: selected === 0 ? this.state.is_sync_product === false ? true : false : false,
        is_sync_collection: selected === 1 ? this.state.is_sync_collection === false ? true : false : false,
      });
    }
  };
  updateSaveOrDiscardComponent = (type = "", v = []) => {
    let productChangeData = this.state.product_change_data;
    switch (type) {
      case "product-data":
        let productData = v;
        if (this._isMounted) {
          this.setState({ isFlushData: false, product_data: productData });
        }
        break;
      case "product-change-data":
        productChangeData.pId = v.pId;
        productChangeData.index = v.index;
        productChangeData.pTitle = v.pTitle;
        productChangeData.isHideEdt = v.isHideEdt;
        productChangeData.outOfStockMode = v.outOfStockMode;
        productChangeData.outOfStockContent = v.outOfStockContent;
        productChangeData.productContent = v.productContent;
        productChangeData.pDeliveryTimeMax = v.pDeliveryTimeMax;
        productChangeData.pDeliveryTimeMin = v.pDeliveryTimeMin;
        if (this._isMounted) {
          this.setState({
            isFlushData: false,
            is_editor: false,
            product_change_data: productChangeData,
          });
        }
        break;
      case "product-change-data-defalt":
        let productChangeDataDefalt = this.state.product_change_data_defalt;
        productChangeDataDefalt.pId = v.pId;
        productChangeDataDefalt.index = v.index;
        productChangeDataDefalt.pTitle = v.pTitle;
        productChangeDataDefalt.isHideEdt = v.isHideEdt;
        productChangeDataDefalt.outOfStockMode = v.outOfStockMode;
        productChangeDataDefalt.outOfStockContent = v.outOfStockContent;
        productChangeDataDefalt.productContent = v.productContent;
        productChangeDataDefalt.pDeliveryTimeMax = v.pDeliveryTimeMax;
        productChangeDataDefalt.pDeliveryTimeMin = v.pDeliveryTimeMin;
        if (this._isMounted) {
          this.setState({
            isFlushData: false,
            is_editor: false,
            product_change_data_defalt: productChangeDataDefalt,
          });
        }
        break;
      case "out-of-stock-mode":
        if (this._isMounted) {
          productChangeData.outOfStockMode = parseInt(v);
          this.setState({ isFlushData: false, productChangeData });
        }
        break;
      case "out-of-stock-content":
        if (this._isMounted) {
          productChangeData.outOfStockContent = v;
          this.setState({ isFlushData: false, productChangeData });
        }
        break;
      case "is-show-product":
        let is_show_product = v;
        if (this._isMounted) {
          this.setState({ isFlushData: false, is_show_product: is_show_product });
        }
        break;
      case "is-hide-edt":
        if (this._isMounted) {
          productChangeData.isHideEdt = v == true ? 1 : 0;
          this.setState({ isFlushData: false, productChangeData });
        }
        break;
      case "cancel-product-data":
        productChangeData.pId = v.pId;
        productChangeData.pTitle = v.pTitle;
        productChangeData.isHideEdt = v.isHideEdt;
        productChangeData.outOfStockMode = v.outOfStockMode;
        productChangeData.outOfStockContent = v.outOfStockContent;
        productChangeData.productContent = v.productContent;
        productChangeData.pDeliveryTimeMax = v.pDeliveryTimeMax;
        productChangeData.pDeliveryTimeMin = v.pDeliveryTimeMin;
        if (this._isMounted) {
          this.setState({ isFlushData: false, is_editor: false, productChangeData });
        }
        break;
      case "edit-setting":
        if (this._isMounted) {
          productChangeData.productContent = v;
          this.setState({ isFlushData: false, is_editor: true, productChangeData });
        }
        break;
      case "product-edit-range":
        if (this._isMounted) {
          productChangeData.pDeliveryTimeMin = v[0];
          productChangeData.pDeliveryTimeMax = v[1];
          this.setState({ isFlushData: false, productChangeData });
        }
        break;
      case "change-product":
        if (this._isMounted) {
          this.setState({ isFlushData: true });
        }
        break;
      case "add-product-list":
        if (this._isMounted) {
          this.setState({ isFlushData: false, is_show_product: true });
        }
        break;
      case "add-collection-list":
        if (this._isMounted) {
          this.setState({ isFlushData: false, is_show_collection: true });
        }
        break;
      case "close-collection-list":
        if (this._isMounted) {
          this.setState({ isFlushData: false, is_show_collection: false });
        }
        break;
      default:
        break;
    }

    if (
      JSON.stringify(this.state.product_change_data) !==
      JSON.stringify(this.state.product_change_data_defalt)
    ) {
      if (this._isMounted) {
        this.setState({ is_show_changes: true });
      }
    } else {
      if (this._isMounted) {
        this.setState({ is_show_changes: false });
      }
    }
  };

  cancelProductEditChange = () => {
    if (this._isMounted) {
      this.setState({ save_is_load: false });
    }
    this.updateSaveOrDiscardComponent(
      "cancel-product-data",
      this.state.product_change_data_defalt
    );
  };

  saveProductSetting = async () => {
    if (this._isMounted) {
      this.setState({ save_is_load: true });
    }
    var user = storageUtils.get("users");
    const headers = {
      token: user.user_token,
      domain: user.shop_domain,
    };

    const outOfStockformats = [
      "",
      "Order now, and we will ship your order as soon as it's in stock!",
      "Expected back into stock within 48 hours",
    ];
    let out_of_stock_content = '';
    if (typeof outOfStockformats[this.state.product_change_data.outOfStockMode] != "undefined") {
      out_of_stock_content = outOfStockformats[this.state.product_change_data.outOfStockMode];
    } else {
      out_of_stock_content = this.state.product_change_data.outOfStockContent;
    }
    const param = {
      pId: this.state.product_change_data.pId,
      is_hide_edt: this.state.product_change_data.isHideEdt,
      product_content: this.state.product_change_data.productContent,
      delivery_time_min: this.state.product_change_data.pDeliveryTimeMin,
      delivery_time_max: this.state.product_change_data.pDeliveryTimeMax,
      out_of_stock_mode: this.state.product_change_data.outOfStockMode,
      out_of_stock_content: out_of_stock_content
    };
    let edtiProductInfoE = await editProductInfo(headers, param);
    let productChangeData = this.state.product_change_data;
    if (edtiProductInfoE.code === 200) {
      productChangeData.pId = parseInt(edtiProductInfoE.data.pId);
      productChangeData.isHideEdt = parseInt(edtiProductInfoE.data.isHideEdt);
      productChangeData.outOfStockMode = parseInt(
        edtiProductInfoE.data.outOfStockMode
      );
      productChangeData.outOfStockContent = edtiProductInfoE.data.outOfStockContent
      productChangeData.productContent = edtiProductInfoE.data.productContent;
      productChangeData.pDeliveryTimeMax =
        edtiProductInfoE.data.pDeliveryTimeMax;
      productChangeData.pDeliveryTimeMin =
        edtiProductInfoE.data.pDeliveryTimeMin;
      let isPId = this.state.is_pId;
      isPId.push(parseInt(edtiProductInfoE.data.pId));
      if (this._isMounted) {
        this.setState({
          is_show_changes: false,
          product_change_data: productChangeData,
          is_pId: isPId,
          isShow: false,
          save_is_load: false,
          is_notify: true,
          notify_content: "Successfully saved.",
        });
      } else {
        if (this._isMounted) {
          this.setState({
            is_show_changes: false,
            product_change_data: productChangeData,
            is_pId: isPId,
            isShow: false,
            save_is_load: false,
            is_notify: true,
            notify_content: "Save failed.",
          });
        }
      }
    }
  };

  /**
   * 弹窗是否活跃
   */
  toggleActive = () => {
    if (this._isMounted) {
      this.setState({ is_notify: !this.state.is_notify });
    }
  };

  startSecond = () => {
    let time = Date.now();
    if (time < storageUtils.get('edtSecond')) {
      this.setState({
        second: this.state.second - 1
      })
    } else {
      clearInterval(this.id)
      this.setState({
        second: 0
      })
    }
  }

  syncProductDataAll = async () => {
    var user = storageUtils.get("users");

    const headers = {
      token: user.user_token,
      domain: user.shop_domain,
    };
    const param = {
    };
    let syncProductALLDataE = await syncProductALLData(headers, param);
    if (syncProductALLDataE.code === 200) {
      let time = Date.now() + 180 * 1000;
      let interShowTime = Date.now() + (180 * 1000) + (15 * 60 * 1000);
      if (this._isMounted) {
        storageUtils.set('edtSecond', time);
        storageUtils.set('edtinterSecond', interShowTime)
        this.setState({
          second: 180,
          is_notify: true,
          notify_content: "Successfully saved."
        });
      }
      this.id = setInterval(this.startSecond, 1000)
    } else {
      this.setState({
        is_notify: true,
        notify_content: "Save failed.",
      });
    }

  }

  CstartSecond = () => {
    let time = Date.now();
    if (time < storageUtils.get('c_edtSecond')) {
      this.setState({
        c_second: this.state.c_second - 1
      })
    } else {
      clearInterval(this.c_id)
      this.setState({
        c_second: 0
      })
    }
  }
  syncCollectionDataAll = async () => {
    var user = storageUtils.get("users");

    const headers = {
      token: user.user_token,
      domain: user.shop_domain,
    };
    const param = {
    };
    let syncCollectionALLDataE = await syncCollectionALLData(headers, param);
    if (syncCollectionALLDataE.code === 200) {
      let time = Date.now() + 180 * 1000;
      let interShowTime = Date.now() + (180 * 1000) + (15 * 60 * 1000);
      if (this._isMounted) {
        storageUtils.set('c_edtSecond', time);
        storageUtils.set('c_edtinterSecond', interShowTime)
        this.setState({
          c_second: 180,
          is_notify: true,
          notify_content: "Successfully saved."
        });
      }
      this.c_id = setInterval(this.CstartSecond, 1000)
    } else {
      this.setState({
        is_notify: true,
        notify_content: "Save failed.",
      });
    }

  }

  render() {
    const toastMarkup = this.state.is_notify ? (
      <Toast
        content={this.state.notify_content}
        onDismiss={this.toggleActive}
      />
    ) : null;
    const tabs = [
      {
        id: "products",
        content: "Products",
        panelID: "products",
      },
      {
        id: "collections",
        content: "Collections",
        panelID: "collections",
      },
    ];

    const activator = (
      <a className="edt-sync-product-slow">
        Slow updating?
      </a>
    );

    const activator_c = (
      <a className="edt-sync-product-slow">
        Slow updating?
      </a>
    );
    const date_time = Date.now();
    const addBtnText = [
      {
        text: <div style={{ height: '280px' }}>
          <Popover
            active={this.state.is_sync_product}
            activator={activator}
            onClose={() => { this.setState({ is_sync_product: false }); }}
            ariaHaspopup={false}
            sectioned
          >
            {
              date_time > storageUtils.get('edtSecond') && date_time < storageUtils.get('edtinterSecond')
                ?
                <FormLayout>
                  <div className="edt-product-sync-content">
                    <p>The sync request has been sent. If there is still a problem, please seek technical support through 24 / 7 customer service. Usually we will solve the problem within 24 hours from Monday to Saturday.</p>
                    <p>The sync button will be available in 15 minutes.</p>
                    {/* <Button onClick={() => { window.Intercom('showNewMessage', '') }}>Contact for support</Button> */}
                    <Button onClick={() => { window.Willdesk.show() }}>Contact for support</Button>
                  </div>
                </FormLayout>
                :
                <FormLayout>
                  <div className="edt-product-sync-content">
                    <label><b>If facing following issues over 5 minutes, the server is temporarily busy</b></label>
                    <ul>
                      <li>Updated delivery date but store shows the old one.</li>
                      <li>Could not find any product or a few of them in “Add products”</li>
                    </ul>
                    <Button disabled={this.state.second > 0 ? true : false} onClick={this.syncProductDataAll}>Sync Products{this.state.second > 0 ? " (" + this.state.second + 's)' : ''}</Button>
                  </div>
                </FormLayout>
            }
          </Popover>
        </div>,
      },
      {
        text: <div style={{ height: '280px' }}>
          <Popover
            active={this.state.is_sync_collection}
            activator={activator_c}
            onClose={() => { this.setState({ is_sync_collection: false }); }}
            ariaHaspopup={false}
            sectioned
          >
            {
              date_time > storageUtils.get('c_edtSecond') && date_time < storageUtils.get('c_edtinterSecond')
                ?
                <FormLayout>
                  <div className="edt-product-sync-content">
                    <p>The sync request has been sent. If there is still a problem, please seek technical support through 24 / 7 customer service. Usually we will solve the problem within 24 hours from Monday to Saturday.</p>
                    <p>The sync button will be available in 15 minutes.</p>
                    {/* <Button onClick={() => { window.Intercom('showNewMessage', '') }}>Contact for support</Button> */}
                    <Button onClick={() => { window.Willdesk.show() }}>Contact for support</Button>

                  </div>
                </FormLayout>
                :
                <FormLayout>
                  <div className="edt-product-sync-content">
                    <label><b>If facing following issues over 5 minutes, the server is temporarily busy</b></label>
                    <ul>
                      <li>Updated delivery date but store shows the old one.</li>
                      <li>Could not find any collection or a few of them in “Add collections”</li>
                      <li>New product added into a listed collection but doesn’t apply collection’s date rule.</li>
                    </ul>
                    <Button disabled={this.state.c_second > 0 ? true : false} onClick={this.syncCollectionDataAll}>Sync Collections{this.state.c_second > 0 ? " (" + this.state.c_second + 's)' : ''}</Button>
                  </div>
                </FormLayout>
            }
          </Popover>
        </div>,
      },
    ];
    const is_show_changes = this.state.is_show_changes;
    return (
      <div className="et-cp-content">
        {is_show_changes ? (
          <div className="saveBar">
            <AppProvider
              i18n={{
                Polaris: {
                  ContextualSaveBar: {
                    save: "Save",
                    discard: "Discard",
                  },
                },
              }}
            >
              <Frame>
                <ContextualSaveBar
                  message="Unsaved changes"
                  saveAction={{
                    onAction: this.saveProductSetting,
                    loading: this.state.save_is_load,
                    disabled: false,
                  }}
                  discardAction={{
                    onAction: this.cancelProductEditChange,
                  }}
                />
              </Frame>
            </AppProvider>
          </div>
        ) : null}
        <div
          className="et-collection-product"
          style={{ width: this.state.product_data.length == 0 || this.state.selected === 1 ? "100%" : "53%" }}

        >
          <Card
            actions={[
              {
                content: addBtnText[this.state.selected].text,
                onAction: this.changeCollectionOrProduct.bind(
                  this,
                  this.state.selected
                ),
              },
            ]}
          >
            <Tabs
              tabs={tabs}
              selected={this.state.selected}
              onSelect={this.handleTabChange}
            ></Tabs>
          </Card>
          {this.state.selected === 0 ? (
            <ProductList
              isShowProduct={this.state.is_show_product}
              productChangeData={this.state.product_change_data}
              isPId={this.state.is_pId}
              updateSaveOrDiscardComponent={this.updateSaveOrDiscardComponent}
            />
          ) : (
            <CollectionList updateSaveOrDiscardComponent={this.updateSaveOrDiscardComponent} isShowCollection={this.state.is_show_collection} isSyncCollection={this.state.is_sync_collection} />
          )}
        </div>
        {this.state.product_data.length > 0 && this.state.selected === 0 ? (
          <div className="et-edit-content-zong">
            <ProductEdit
              updateSaveOrDiscardComponent={this.updateSaveOrDiscardComponent}
              isFlushData={this.state.isFlushData}
              productData={this.state.product_data}
              isEditor={this.state.is_editor}
              productChangeData={this.state.product_change_data}
            ></ProductEdit>
          </div>
        ) : (
          ""
        )}

        <Frame>{toastMarkup}</Frame>
      </div>
    );
  }
}

export default Product;
