/** 主包 */
import React, { Component } from 'react';

/** 公共组件 */
import EditDeliverytimeModal from '../../components/edit-deliverytime-modal';

/** 组件包 */
import DeliveryZones from './components/delivery-zones/delivery-zones';
// import DeliveryTime from './components/delivery-time/delivery-time';

/** 工具组件包 */
import storageUtils from '../../utils/storageUtils';
import { countryList, getUserSetting, saveCountryInfoData } from '../../api/index';
import {
    AppProvider,
    Frame,
    ContextualSaveBar,
    Toast
} from "@shopify/polaris";
/** 样式 */
import './index.less';


class DeliveryZone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: {
                token: '',
                domain: ''
            },
            country: [],
            country_lists: [],
            is_show_changes: false,
            save_is_load: false,
            delivery_zones_data: {
                is_show_country: false,
                is_country_info_status: 1,
                is_country_info: 'Currently we do not ship to {country}, click to check our delivery zones'
            },
            delivery_zones_data_default: {
                is_show_country: false,
                is_country_info_status: 1,
                is_country_info: 'Currently we do not ship to {country}, click to check our delivery zones'
            },
            modal: {
                isActive: false,
                cId: 0,
                cDeliveryTimeMin: 0,
                cDeliveryTimeMax: 0,
                type: 'country'
            },
            isFlushData: false,
            _isRequestFinal: false,
            is_notify: false,
            notify_content: "",
        }
    }

    // 内存泄漏标志
    _isMounted = true;

    UNSAFE_componentWillMount() {
        // 监测本地是否存在用户核心参数，否则跳转 404
        var userData = storageUtils.get('users');

        if (userData === null) {
            // this.props.history.push('/notfound');
            window.location.href = "/notfound";
            return '';
        }

        let userInfo = this.state.users;
        userInfo.domain = userData.shop_domain;
        userInfo.token = userData.user_token;
        if (this._isMounted) {
            this.setState({ userInfo });
        }

        // 初始化数据
        this.initData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * 初始化数据
     */
    initData = async () => {
        const headers = {
            'token': this.state.users.token,
            'domain': this.state.users.domain
        }
        // 国家列表
        let countryListRes = await countryList(headers);
        if(countryListRes.code === 200) {
            if(this._isMounted) {
                this.setState({country: countryListRes.data})
            }
        }
        let getUserSettingD = await getUserSetting(headers);
        if(getUserSettingD.code === 200) {
            if(this._isMounted) {
               let deliveryZonesData = this.state.delivery_zones_data;
               deliveryZonesData.is_show_country  = getUserSettingD.data.is_show_country == 1 ? true :  false;
               deliveryZonesData.is_country_info_status  = getUserSettingD.data.is_country_info_status;
               deliveryZonesData.is_country_info  = getUserSettingD.data.is_country_info;
               let deliveryZonesDataDefault = this.state.delivery_zones_data_default;
               deliveryZonesDataDefault.is_show_country  = getUserSettingD.data.is_show_country == 1 ? true :  false;
               deliveryZonesDataDefault.is_country_info_status  = getUserSettingD.data.is_country_info_status;
               deliveryZonesDataDefault.is_country_info  = getUserSettingD.data.is_country_info;
               if(this._isMounted) {
                this.setState({deliveryZonesData, deliveryZonesDataDefault})
               }
            }
        }
    }

    /**
     * 更新组件
     * 
     * @param {*} modal 
     */
    updateComponentByDeliveryZones = (modal) => {
        let isModalData = this.state.modal;
        isModalData.isActive = true;
        isModalData.cId = modal.cId;
        isModalData.cDeliveryTimeMin = modal.cDeliveryTimeMin;
        isModalData.cDeliveryTimeMax = modal.cDeliveryTimeMax;
        if(this._isMounted) {
            this.setState({isModalData});
        }
    }

    /**
     * 更新组件
     */
    updateComponent = () => {
        let isModalData = this.state.modal;
        isModalData.isActive = false;
        if(this._isMounted) {
            this.setState({isModalData, isFlushData: true});
        }
    }
  
    /**
     * 清除子组件弹窗 props 影响
     */
    clearModal = () => {
        let isModalData = this.state.modal;
        isModalData.isActive = false;
        if(this._isMounted) {
            this.setState({isModalData});
        }
    }

    updateSaveOrDiscardComponent = (type = "", v = []) => {
        let deliveryZonesData = this.state.delivery_zones_data;
        switch (type) {
            case "is-show-country":
                deliveryZonesData.is_show_country = v;
                if (this._isMounted) {
                    this.setState({ isFlushData:false, deliveryZonesData });
                }
                break;
            case "is-country-info-status":
                deliveryZonesData.is_country_info_status = v;
                if (this._isMounted) {
                    this.setState({ isFlushData:false, deliveryZonesData });
                }
            break;
            case "is-country-info":
                deliveryZonesData.is_country_info = v;
                if (this._isMounted) {
                    this.setState({ isFlushData:false, deliveryZonesData });
                }
            break;
            case "cancel-country-data":
                deliveryZonesData.is_show_country = v.is_show_country;
                deliveryZonesData.is_country_info_status = v.is_country_info_status;
                deliveryZonesData.is_country_info = v.is_country_info;
                if (this._isMounted) {
                    this.setState({ isFlushData:false, deliveryZonesData });
                }
            break;
            default:
                break;
        }
        if (
            JSON.stringify(this.state.delivery_zones_data) !==
            JSON.stringify(this.state.delivery_zones_data_default)
          ) {
            if (this._isMounted) {
              this.setState({ is_show_changes: true });
            }
          } else {
            if (this._isMounted) {
              this.setState({ is_show_changes: false });
            }
          }
    };

    saveCountryInfo = async (data) => {
        if(data.is_show_country == true && data.is_country_info_status == 1){
                if(data.is_country_info == ''){
                    this.setState({
                        save_is_load: false,
                        is_notify: true,
                        notify_content: "Can't be saved as empty",
                    });
                    return;
                }
        }
       
        if (this._isMounted) {
          this.setState({ save_is_load: true });
        }
        var user = storageUtils.get("users");
        const headers = {
          token: user.user_token,
          domain: user.shop_domain,
        };
        const param = {
            is_show_country: data.is_show_country,
            is_country_info_status: data.is_country_info_status,
            is_country_info: data.is_country_info
        };
        
        let saveCountryInfoD = await saveCountryInfoData(headers, param);
        let deliveryZonesDataDefault = this.state.delivery_zones_data_default;

        if (saveCountryInfoD.code === 200) { 
            deliveryZonesDataDefault.is_show_country = saveCountryInfoD.data.is_show_country == 1 ? true : false;
            deliveryZonesDataDefault.is_country_info_status = parseInt(saveCountryInfoD.data.is_country_info_status);
            deliveryZonesDataDefault.is_country_info = saveCountryInfoD.data.is_country_info;
            if (this._isMounted) {
                this.setState({
                    deliveryZonesDataDefault,
                    is_show_changes: false,
                    save_is_load: false,
                    is_notify: true,
                    notify_content: "Successfully saved.",
                });
            }else {
                this.setState({
                    deliveryZonesDataDefault,
                    is_show_changes: false,
                    save_is_load: false,
                    is_notify: true,
                    notify_content: "Save failed.",
                });
            }
        }
        
      };
            /** 
         * 弹窗是否活跃
         */
        toggleActive = () => {
            if (this._isMounted) {
            this.setState({ is_notify: !this.state.is_notify });
            }
        };

        cancelCountryInfo = () => {
            if (this._isMounted) {
                this.setState({ save_is_load: false });
            }
            this.updateSaveOrDiscardComponent(
                "cancel-country-data",
                this.state.delivery_zones_data_default
            );
        };
     render() {
        const toastMarkup = this.state.is_notify ? (
            <Toast
              content={this.state.notify_content}
              onDismiss={this.toggleActive}
            />
          ) : null;
        const is_show_changes = this.state.is_show_changes;
        return (
            <div className='et-delivery-zone'>
                {is_show_changes ? (
                    <div className="saveBar">
                       <AppProvider
                        i18n={{
                            Polaris: {
                            ContextualSaveBar: {
                                save: "Save",
                                discard: "Discard",
                            },
                            },
                        }}
                        >
                        <Frame>
                            <ContextualSaveBar
                            message="Unsaved changes"
                            saveAction={{
                                onAction:()=>this.saveCountryInfo(this.state.delivery_zones_data),
                                loading: this.state.save_is_load,
                                disabled: false,
                            }}
                            discardAction={{
                                onAction: this.cancelCountryInfo,
                            }}
                            />
                        </Frame>
                        </AppProvider>
                    </div>
                ) : null}
                
                <DeliveryZones deliveryZonesData={this.state.delivery_zones_data} updateSaveOrDiscardComponent={this.updateSaveOrDiscardComponent} updateComponentByDeliveryZones={this.updateComponentByDeliveryZones} isFlushData={this.state.isFlushData} country={this.state.country} />
                {/* <DeliveryTime /> */}
                <EditDeliverytimeModal clearModal={this.clearModal} updateComponent={this.updateComponent} modalData={this.state.modal} />
                <Frame>{toastMarkup}</Frame>
            </div>
        )
    }
}


export default DeliveryZone;
