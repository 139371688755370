/** 主包 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Tabs } from "@shopify/polaris";
import { WEB_KEY } from '../../utils/constants';
import createApp from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
/** 样式包 */
import './index.less';
import fun from '../../utils/fun';
import storageUtils from '../../utils/storageUtils';


class TabBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0
        }
    }

    // 内存泄漏标志
    _isMounted = true;

    componentDidMount() {

        let pathParams = this.props.location.pathname.split('/').pop();
        switch (pathParams) {
            case "dash":
                if (this._isMounted) { this.setState({ selected: 0 }); }; break;
            case "delivery_zone":
                if (this._isMounted) { this.setState({ selected: 1 }); }; break;
            case "product":
                if (this._isMounted) { this.setState({ selected: 2 }); }; break;
            case "plan":
                if (this._isMounted) { this.setState({ selected: 3 }); }; break;
            case "faq":
                if (this._isMounted) { this.setState({ selected: 4 }); }; break;
            default:
                break;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let pathParams = nextProps.location.pathname.split('/').pop();
        switch (pathParams) {
            case "dash":
                if (this._isMounted) { this.setState({ selected: 0 }); }; break;
            case "delivery_zone":
                if (this._isMounted) { this.setState({ selected: 1 }); }; break;
            case "product":
                if (this._isMounted) { this.setState({ selected: 2 }); }; break;
            case "plan":
                if (this._isMounted) { this.setState({ selected: 3 }); }; break;
            case "faq":
                if (this._isMounted) { this.setState({ selected: 4 }); }; break;
            default:
                break;
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleModuleAction = (r) => {
        this.props.history.push(r);
    };

    handleTabChange = (selectedTabIndex) => {
        if (this._isMounted) {
            this.setState({ selected: selectedTabIndex })
        }

        switch (selectedTabIndex) {
            case 0:
                this.handleModuleAction('/dash'); break;
            case 1:
                this.handleModuleAction('/delivery_zone'); break;
            case 2:
                this.handleModuleAction('/product'); break;
            case 3:
                this.handleModuleAction('/plan'); break;
            case 4:
                this.handleModuleAction('/faq'); break;
            default:
                console.log("exception"); break;
        }
    }

    render() {

        // let shopifyAppObj = 'fallusin.myshopify.com';
        // let apiKey = '92b99ce245731caa0a4c2ce3ca7055d5';
        // let host = 'ZmFsbHVzaW4ubXlzaG9waWZ5LmNvbS9hZG1pbg';
        // const shopifyApp = createApp({ apiKey, shopifyAppObj, host });
        // shopifyApp.dispatch(
        //     Redirect.toRemote({
        //         url: 'https://fallusin.myshopify.com/oauth/authorize ',
        //     }),
        // );
        // console.info(123123123);
        // createApp({
        //     apiKey: '92b99ce245731caa0a4c2ce3ca7055d5',
        //     host: 'ZmFsbHVzaW4ubXlzaG9waWZ5LmNvbS9hZG1pbg',
        // });

        const tabs = [
            {
                id: 'dashboard',
                content: 'Dashboard',
                panelID: 'Dashboard',
            },
            {
                id: 'delivery-zones',
                content: 'Delivery zones',
                panelID: 'delivery-zones',
            },
            {
                id: 'products',
                content: 'Products',
                panelID: 'products',
            },
            {
                id: 'plan',
                content: 'Plan',
                panelID: 'plan',
            },
            {
                id: 'faq',
                content: 'FAQ',
                panelID: 'faq',
            },
        ];

        return (
            <Card>
                <Tabs tabs={tabs} selected={this.state.selected} onSelect={this.handleTabChange}></Tabs>
            </Card>
        );
    }
}

export default withRouter(TabBtn);