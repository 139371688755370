/** 主包 */
import React, { Component } from "react";
import {
  Layout,
  Card,
  Page,
  DataTable,
  Icon,
  Thumbnail,
  EmptyState,
  SkeletonBodyText,
  Pagination,
  Modal,
  TextContainer,
  Checkbox,
  TextStyle,
  TextField,
  ButtonGroup,
  Button,
  Frame,
  Loading,
  Toast,
  Spinner,
  Badge
} from "@shopify/polaris";
import {
  HorizontalDotsMinor,
  ViewMinor,
  SearchMinor,
  CircleCancelMinor,
} from "@shopify/polaris-icons";

/** 公共组件 */
import EditDeliverytimeModal from "../../../../components/edit-deliverytime-modal";
import EditAllDeliverytimeModal from "../../../../components/edit-all-deliverytime-modal";

/** 工具组件包 */
import storageUtils from "../../../../utils/storageUtils";
import { addProductList } from "../../../../api/index";
import { addProduct } from "../../../../api/index";
import { productList } from "../../../../api/index";
import { sProductList } from "../../../../api/index";
import { removeProduct } from "../../../../api/index";
import { removeAllProduct } from "../../../../api/index";
import { testJs } from "../../../../utils/fun";
/** 样式 */
import "./product_list.less";

class ProductList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: {
        token: "",
        domain: "",
      },
      tr_class_name:'null',
      add_product_lists: [],
      s_add_product_lists: [],
      add_product_lists_default: [],
      product_sum_count: 0,
      add_product_list_search_value: "",
      add_pagination: [],
      del_add_product_list_search_value_active: false,
      del_product_list_search_value_active: false,
      product_lists: [],
      product_lists_default: [],
      is_pagination_load: false,
      pagination: [],
      is_s_pagination_load: false,
      is_search_product_load: false,
      s_pagination: [],
      is_all_checked_product_list: false,
      is_all_button_product: false,
      product_checked_list_count: 0,
      is_del_checked_product: false,
      load_ing: false,
      modal: {
        isActive: false,
        pId: 0,
        pDeliveryTimeMin: 0,
        pDeliveryTimeMax: 0,
        type: "product",
        isHideEdt: false,
      },
      all_modal: {
        isActive: false,
        pIds: [],
        pDeliveryTimeMin: 0,
        pDeliveryTimeMax: 0,
        type: "product",
        isHideEdt: false,
      },
      product_modal: {
        isActive: false,
        value: "",
        checked: [],
      },
      product_checked_list: {
        isActive: false,
        value: "",
        checked: [],
      },
      _isRequestFinal: false,

      is_notify: false,
      notify_content: "",
    };
  }

  // 内存泄漏标志
  _isMounted = true;

  UNSAFE_componentWillMount() {
    // 监测本地是否存在用户核心参数，否则跳转 404
    var userData = storageUtils.get("users");

    if (userData === null) {
      // this.props.history.push('/notfound');
      window.location.href = "/notfound";
      return "";
    }

    let userInfo = this.state.users;
    userInfo.domain = userData.shop_domain;
    userInfo.token = userData.user_token;
    if (this._isMounted) {
      this.setState({ userInfo });
    }

    // 初始化数据
    this.initData(1);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this._isMounted) {
      if (
        typeof this.state.add_product_lists[
          nextProps.productChangeData.index
        ] != "undefined" && nextProps.isPId.indexOf(nextProps.productChangeData.pId) > -1
      ) {
        this.state.add_product_lists[
          nextProps.productChangeData.index
        ].pDeliveryTimeMax = nextProps.productChangeData.pDeliveryTimeMax;
        this.state.add_product_lists[
          nextProps.productChangeData.index
        ].isHideEdt = nextProps.productChangeData.isHideEdt;
        this.state.add_product_lists[
          nextProps.productChangeData.index
        ].pDeliveryTimeMin = nextProps.productChangeData.pDeliveryTimeMin;
        this.state.add_product_lists[
          nextProps.productChangeData.index
        ].outOfStockMode = nextProps.productChangeData.outOfStockMode;
        this.state.add_product_lists[
          nextProps.productChangeData.index
        ].productContent = nextProps.productChangeData.productContent;
      }
      if (nextProps.isShowProduct === true) {
        let productModalData = this.state.product_modal;
        productModalData.isActive = true;
        if (this._isMounted) {
          this.setState({ productModalData });
        }
        // this.setState({});
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * 初始化数据
   */
  initData = async (currentPage) => {
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    if (currentPage != 9999) {
      // 预投递产品列表
      this.setState({
        load_ing: true,
      });
    }
    if(currentPage == 9999){
      currentPage = 1;
    }
    const param = {
      page: currentPage,
    };
    
    let addProductListD = await addProductList(headers, param);
    if (addProductListD.code === 200) {
      let productCheckedList = this.state.product_checked_list;
      let is_all_checked_status = true;

      //   if (typeof addProductListD.data.pro_data[0] != "undefined") {

      //   }else{
      //     this.props.updateSaveOrDiscardComponent(
      //         "product-change-data",{}
      //     );
      //   }
      addProductListD.data.pro_data.map((v, i) => {
        if (typeof productCheckedList.checked[v.pId] == "undefined") {
          is_all_checked_status = false;
        } else {
          if (productCheckedList.checked[v.pId].checked == false) {
            is_all_checked_status = false;
          }
        }
      });
      if (this._isMounted) {
        this.setState({
          add_product_lists: addProductListD.data.pro_data,
          add_product_lists_default: addProductListD.data.pro_data,
          s_add_product_lists: addProductListD.data.s_pro_data,
          add_pagination: addProductListD.data.page_data,
          product_sum_count: addProductListD.data.product_sum_count,
          is_all_checked_product_list: is_all_checked_status,
          load_ing: false,
          tr_class_name: 'null'
        });
      }

      if (typeof addProductListD.data.pro_data[0] != "undefined") {
        testJs(addProductListD.data.pro_data[0].pId, 'null', true)
        addProductListD.data.pro_data[0].index = 0;
        this.props.updateSaveOrDiscardComponent(
          "product-change-data",
          addProductListD.data.pro_data[0]
        );
        this.props.updateSaveOrDiscardComponent(
          "product-change-data-defalt",
          addProductListD.data.pro_data[0]
        );
        this.props.updateSaveOrDiscardComponent("product-data", [
          addProductListD.data.pro_data,
        ]);
      } else {
        this.props.updateSaveOrDiscardComponent("product-data", []);
      }
    } else {
      // console.log(456);
    }

    if (this._isMounted) {
      this.setState({ _isRequestFinal: true });
    }

    // 产品列表
    let productListD = await productList(headers, param);
    if (productListD.code === 200) {
      if (this._isMounted) {
        this.setState({
          product_lists: productListD.data.pro_data,
          product_lists_default: productListD.data.pro_data,
          pagination: productListD.data.page_data,
        });
      }
      if (productListD.data.pro_data.length !== 0) {
        let productModalData = this.state.product_modal;
        productListD.data.pro_data.map((v, i) => {
          productModalData.checked[i] = { checked: false, pId: 0 };
          return "";
        });
        if (this._isMounted) {
          this.setState({
            productModalData,
          });
        }
      }
    } else {
      // console.log(456);
    }
  };

  /**
   * 编辑产品预投递时间
   *
   * @param {*} pId  产品ID
   * @param {*} pDeliveryTimeMin  小值
   * @param {*} pDeliveryTimeMax  大值
   */
  editDateChange = (pId, pDeliveryTimeMin, pDeliveryTimeMax, isHideEdt) => {
    let isModalData = this.state.modal;
    isModalData.isActive = true;
    isModalData.pId = pId;
    isModalData.pDeliveryTimeMin = pDeliveryTimeMin;
    isModalData.pDeliveryTimeMax = pDeliveryTimeMax;
    isModalData.isHideEdt = isHideEdt;
    if (this._isMounted) {
      this.setState({ isModalData });
    }
  };

  /**
   * 移除产品
   *
   * @param {*} pId
   */
  removeProductChange = async (pId) => {
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const params = {
      pId: pId,
    };
    let removeData = await removeProduct(headers, params);
    if (removeData.code === 200) {
      if (this._isMounted) {
        this.setState({
          is_notify: true,
          notify_content: "Successfully deleted.",
        });
      }
      this.initData(1);
    } else {
      if (this._isMounted) {
        this.setState({ is_notify: false, notify_content: "Failed deleted." });
      }
      // console.log(456);
    }
  };

  /**
   * 更新组件
   */
  updateComponent = () => {
    let isModalData = this.state.modal;
    isModalData.isActive = false;
    if (this._isMounted) {
      this.setState({ isModalData });
    }
    let page = 1;
    if (this.state.add_pagination.next == false) {
      page = this.state.add_pagination.next_id;
    } else {
      page = this.state.add_pagination.next_id - 1;
    }
    this.initData(page);
  };

  /**
   * 更新组件
   */
  updateAllComponent = () => {
    let isModalData = this.state.all_modal;
    isModalData.isActive = false;
    if (this._isMounted) {
      this.setState({
        isModalData,
        product_checked_list: {
          isActive: false,
          value: "",
          checked: [],
        },
        is_all_button_product: false,
      });
    }
    this.initData(1);
  };
  /**
   * 清除子组件弹窗 props 影响
   */
  clearModal = () => {
    let isModalData = this.state.modal;
    isModalData.isActive = false;
    if (this._isMounted) {
      this.setState({ isModalData });
    }
  };

  /**
   * 清除全部子组件弹窗 props 影响
   */
  clearAllModal = () => {
    let isModalData = this.state.all_modal;
    isModalData.isActive = false;
    if (this._isMounted) {
      this.setState({ isModalData });
    }
  };

  /**
   * 开启产品列表弹窗
   */
  activeProductModal = () => {
    let productModalData = this.state.product_modal;
    productModalData.isActive = true;
    if (this._isMounted) {
      this.setState({ productModalData });
    }
  };

  /**
   * 关闭或取消产品列表弹窗
   */
  closeOrCancelProductModal = () => {
    let productModalData = this.state.product_modal;
    productModalData.isActive = false;
    if (this._isMounted) {
      this.setState({ productModalData });
    }
    this.props.updateSaveOrDiscardComponent("is-show-product", false);
    this.initData(9999);
  };

  handleChange = () => {};

  /**
   * 搜索列表产品
   *
   * @param {*} newValue
   */
  searchListProductChange = (newValue) => {
    testJs(0,'null',true);
    if (this._isMounted) {
      this.setState({ add_product_list_search_value: newValue });
    }
    const filterRegex = new RegExp(newValue, "i");
    const resultOptions = this.state.add_product_lists.filter((option) =>
      option.pTitle.match(filterRegex)
    );
    if (this._isMounted) {
      this.setState({
        add_product_lists: resultOptions,
      });
    }

    if (newValue.length === 0) {
      // this.initData(1);
      if (this._isMounted) {
        this.setState({
          add_product_lists: this.state.add_product_lists_default,
          del_add_product_list_search_value_active: false,
        });
      }
    }

    if (newValue.length !== 0) {
      const filterRegex = new RegExp(newValue, "i");
      const resultOptions = this.state.s_add_product_lists.filter((option) =>
        option.pTitle.match(filterRegex)
      );
      if (this._isMounted) {
        this.setState({
          add_product_lists: resultOptions,
          del_add_product_list_search_value_active: true,
        });
      }
    }
  };

  /**
   * 删除搜索添加产品输入框文本
   */
  deleteSearchAddProductListText = () => {
    if (this._isMounted) {
      this.setState({
        add_product_list_search_value: "",
        add_product_lists: this.state.add_product_lists_default,
        del_add_product_list_search_value_active: false,
      });
    }
  };
  
  /**
   * 添加产品
   */
  addProductList= () => {
    this.props.updateSaveOrDiscardComponent("add-product-list", 0);
  }
  /**
   * 搜索产品
   *
   * @param {*} newValue
   */
  searchProductChange = async (newValue) => {
    let productModalData = this.state.product_modal;
    productModalData.value = newValue;
    if (this._isMounted) {
      this.setState({ productModalData, is_search_product_load: true });
    }

    if (newValue.length === 0) {
      if (this._isMounted) {
        this.setState({
          product_lists: this.state.product_lists_default,
          del_product_list_search_value_active: false,
        });
      }
    }

    if (newValue.length !== 0) {
      if (this._isMounted) {
        this.setState({
          del_product_list_search_value_active: true,
        });
      }
    }

    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const param = {
      s_keyword: newValue,
      page: 1,
    };

    // 产品列表
    let sProductListD = await sProductList(headers, param);
    if (sProductListD.code === 200) {
      if (this._isMounted) {
        this.setState({
          product_lists: sProductListD.data.s_pro_data,
          s_pagination: sProductListD.data.page_data,
          is_search_product_load: false,
        });
      }
    } else {
      // console.log(456);
    }
  };

  /**
   * 删除搜索产品输入框文本
   */
  deleteSearchProductListText = () => {
    let productModalData = this.state.product_modal;
    productModalData.value = "";
    if (this._isMounted) {
      this.setState({
        productModalData,
        product_lists: this.state.product_lists_default,
        del_product_list_search_value_active: false,
      });
    }
  };

  /**
   * 产品勾选选择
   *
   * @param {*} pId
   * @param {*} i
   * @param {*} checked
   * @param {*} mark
   */
  checkboxProductChange = (pId, i, checked, mark) => {
    let productModalData = this.state.product_modal;
    productModalData.checked[i] = {
      checked: checked,
      pId: checked === true ? pId : 0,
    };
    if (this._isMounted) {
      this.setState({ productModalData });
    }
  };

  /**
   * 添加预投递产品
   */
  addProduct = async () => {
    let pIds = [];
    this.state.product_modal.checked.map((v, i) => {
      let pId = v.pId;
      if (pId !== 0) {
        pIds[i] = pId;
      } else {
        pIds[i] = 0;
      }
      return "";
    });
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const param = {
      pIds: pIds,
    };
    // 预投递产品列表
    let addProductRes = await addProduct(headers, param);
    if (addProductRes.code === 200) {
      if (this._isMounted) {
        this.setState({
          is_notify: true,
          notify_content: "Successfully added",
        });
        this.closeOrCancelProductModal();
      }
    } else {
      if (this._isMounted) {
        this.setState({ is_notify: true, notify_content: "Failed added" });
      }
      // console.log(456);
    }
  };

  /**
   * 滚动底部重新载入新数据
   */
  scrollBottomChange = async () => {
    // 未搜索时下拉加载
    if (this.state.product_modal.value.length === 0) {
      if (this.state.pagination.next === false) return;
      if (this._isMounted) {
        this.setState({ is_pagination_load: true });
      }
      const headers = {
        token: this.state.users.token,
        domain: this.state.users.domain,
      };
      const param = {
        page:
          this.state.pagination.next === true
            ? this.state.pagination.next_id
            : 1,
      };

      // 产品列表
      let productListD = await productList(headers, param);
      if (productListD.code === 200) {
        if (productListD.data.pro_data !== 0) {
          productListD.data.pro_data.map((v1, i1) => {
            this.state.product_lists.push(v1);
            return "";
          });
        }
        if (this._isMounted) {
          this.setState({
            product_lists: this.state.product_lists,
            product_lists_default: this.state.product_lists,
            pagination: productListD.data.page_data,
            is_pagination_load: false,
          });
        }
        if (productListD.data.pro_data.length !== 0) {
          let productModalData = this.state.product_modal;
          productListD.data.pro_data.map((v, i) => {
            productModalData.checked[i] = { checked: false, pId: 0 };
            return "";
          });
          if (this._isMounted) {
            this.setState({
              productModalData,
            });
          }
        }
      } else {
        // console.log(456);
      }
    }
    // 搜索时下拉加载
    else {
      if (this.state.s_pagination.next === false) return;
      if (this._isMounted) {
        this.setState({ is_s_pagination_load: true });
      }

      const headers = {
        token: this.state.users.token,
        domain: this.state.users.domain,
      };
      const param = {
        s_keyword: this.state.product_modal.value,
        page:
          this.state.s_pagination.next === true
            ? this.state.s_pagination.next_id
            : 1,
      };

      // 产品列表
      let sProductListD = await sProductList(headers, param);
      if (sProductListD.code === 200) {
        if (sProductListD.data.s_pro_data !== 0) {
          sProductListD.data.s_pro_data.map((v1, i1) => {
            this.state.product_lists.push(v1);
            return "";
          });
        }

        if (this._isMounted) {
          this.setState({
            product_lists: this.state.product_lists,
            s_pagination: sProductListD.data.page_data,
            is_s_pagination_load: false,
          });
        }

        if (sProductListD.data.s_pro_data.length !== 0) {
          let productModalData = this.state.product_modal;
          sProductListD.data.s_pro_data.map((v, i) => {
            productModalData.checked[i] = { checked: false, pId: 0 };
            return "";
          });
          if (this._isMounted) {
            this.setState({
              productModalData,
            });
          }
        }
      } else {
        // console.log(456);
      }
    }
  };

  /**
   * 弹窗是否活跃
   */
  toggleActive = () => {
    if (this._isMounted) {
      this.setState({ is_notify: !this.state.is_notify });
    }
  };

  viewProduct = (pHandle) => {
    const urlObj = window.open("about:blank");
    urlObj.location.href =
      "https://" + this.state.users.domain + "/products/" + pHandle;
  };

  checkedProductList = (pId, i, checked, mark) => {
    let productCheckedData = this.state.product_checked_list;
    let productCheckedDataCount = 0;
    productCheckedData.checked[pId] = {
      checked: checked,
      pId: checked === true ? pId : 0,
    };
    let productCheckedList = this.state.product_checked_list;
    let addProductListD = this.state.add_product_lists;
    let isAllCheckedStatus = true;
    addProductListD.map((v, i) => {
      if (typeof productCheckedList.checked[v.pId] == "undefined") {
        isAllCheckedStatus = false;
      } else {
        if (productCheckedList.checked[v.pId].checked == false) {
          isAllCheckedStatus = false;
        }
      }
    });

    let is_button_product_status = false;
    productCheckedData.checked.map((v, i) => {
      if (v.checked == true) {
        is_button_product_status = true;
        productCheckedDataCount = productCheckedDataCount + 1;
      }
    });

    if (this._isMounted) {
      this.setState({
        productCheckedData,
        is_all_checked_product_list: isAllCheckedStatus,
        is_all_button_product: is_button_product_status,
        product_checked_list_count: productCheckedDataCount,
      });
    }
  };

  checkedAllPordctList = (value) => {
    let productLists = this.state.add_product_lists;
    let productCheckedData = this.state.product_checked_list;
    let productCheckedDataCount = 0;
    if (value == true) {
      productLists.map((v, i) => {
        productCheckedData.checked[v.pId] = { checked: true, pId: v.pId };
      });
    } else {
      productLists.map((v, i) => {
        productCheckedData.checked[v.pId] = { checked: false, pId: 0 };
      });
    }
    let is_button_product_status = false;
    productCheckedData.checked.map((v, i) => {
      if (v.checked == true) {
        is_button_product_status = true;
        productCheckedDataCount = productCheckedDataCount + 1;
      }
    });

    if (this._isMounted) {
      this.setState({
        is_all_checked_product_list: value,
        productCheckedData,
        is_all_button_product: is_button_product_status,
        product_checked_list_count: productCheckedDataCount,
      });
    }
  };
  //批量删除
  dellAllProduct = async () => {
    if (this._isMounted) {
      this.setState({ is_del_checked_product: true });
    }
  };

  dellAllProductButton = async () => {
    let productCheckedData = this.state.product_checked_list;
    let pIds = [];
    productCheckedData.checked.map((v, i) => {
      if (v.checked == true) {
        pIds.push(v.pId);
      }
    });
    const headers = {
      token: this.state.users.token,
      domain: this.state.users.domain,
    };
    const params = {
      pIds: pIds,
    };
    let removeData = await removeAllProduct(headers, params);
    if (removeData.code === 200) {
      if (this._isMounted) {
        this.setState({
          is_notify: true,
          notify_content: "Successfully deleted.",
          is_del_checked_product: false,
          product_checked_list: {
            isActive: false,
            value: "",
            checked: [],
          },
          product_checked_list_count: 0,
          is_all_button_product: false,
        });
      }
      this.initData(1);
    } else {
      if (this._isMounted) {
        this.setState({ is_notify: false, notify_content: "Failed deleted." });
      }
      // console.log(456);
    }
  };
  //批量删除关闭
  closeAllProduct = async () => {
    if (this._isMounted) {
      this.setState({ is_del_checked_product: false });
    }
  };
  /**
   * 批量编辑预投递时间
   *
   * @param {*} pId  产品ID
   * @param {*} pDeliveryTimeMin  小值
   * @param {*} pDeliveryTimeMax  大值
   */
  editAllDateChange = () => {
    let productCheckedData = this.state.product_checked_list;
    let pIds = [];
    productCheckedData.checked.map((v, i) => {
      if (v.checked == true) {
        pIds.push(v.pId);
      }
    });
    let isAllModalData = this.state.all_modal;
    isAllModalData.isActive = true;
    isAllModalData.pDeliveryTimeMin = "";
    isAllModalData.pDeliveryTimeMax = "";
    isAllModalData.isHideEdt = false;
    isAllModalData.pIds = pIds;
    if (this._isMounted) {
      this.setState({ isAllModalData });
    }
  };

  //选中产品传递数据
  changeProductData = (v, i) => {
    let class_name = 'product-list-'+v.pId;
    testJs(class_name,this.state.tr_class_name, false);
    v.index = i;
    this.props.updateSaveOrDiscardComponent("product-change-data", v);
    this.props.updateSaveOrDiscardComponent("product-change-data-defalt", v);
    this.props.updateSaveOrDiscardComponent("change-product", true);
    this.setState({ tr_class_name:class_name });

  };

  render() {
    const toastMarkup = this.state.is_notify ? (
      <Toast
        content={this.state.notify_content}
        onDismiss={this.toggleActive}
      />
    ) : null;
    let pdData = []; // 产品预投递时间数据
    let item = [];
    this.state.add_product_lists.map((v, i) => {
      item[0] = (
        <Checkbox
          checked={
            typeof this.state.product_checked_list.checked[v.pId] == "undefined"
              ? false
              : this.state.product_checked_list.checked[v.pId].checked
          }
          onChange={this.checkedProductList.bind(this, v.pId, i)}
        />
      );
      item[1] = <Thumbnail source={v.pImgSrc == '' ? '/image/image.png' : v.pImgSrc} size="small" alt="" />;
      item[2] = (
        <div
          className="product-list-title"
          id={"product-list-"+v.pId}
          onClick={() =>
            this.changeProductData(this.state.add_product_lists[i], i)
          }
        >
          {v.pTitle}
        </div>
      );
      item[3] = (
        <div className="product-list-icon">
          <div
            className="view-product"
            onClick={() => this.viewProduct(v.pHandle)}
          >
            <Icon color="inkLightest" source={ViewMinor} />
          </div>
        </div>
      );
      item[4] = v.pDeliveryTimeMin + "-" + v.pDeliveryTimeMax + " days";
      item[5] =  v.isHideEdt == 1 ? <Badge>Hide</Badge> : '';

      // item[5] = (
      //   <Page
      //     actionGroups={[
      //       {
      //         title: <Icon source={HorizontalDotsMinor} />,
      //         actions: [
      //           {
      //             content: "Edit date",
      //             onAction: this.editDateChange.bind(
      //               this,
      //               v.pId,
      //               v.pDeliveryTimeMin,
      //               v.pDeliveryTimeMax,
      //               v.isHideEdt
      //             ),
      //           },
      //           {
      //             content: <span style={{ color: "#C0323E" }}>Delete</span>,
      //             onAction: this.removeProductChange.bind(this, v.pId),
      //           },
      //         ],
      //       },
      //     ]}
      //   ></Page>
      // );

      pdData[i] = item;
      item = [];
      return "";
    });

    const productListComponent = this.state.product_lists.map((v, i) => {
      return (
        <Modal.Section key={i}>
          <TextContainer>
            <Checkbox
              label={
                <div className="show-product-icon-description">
                  <Thumbnail source={v.pImgSrc} size="small" />
                  <span>{v.pTitle}</span>
                </div>
              }
              // checked={(this.state.product_modal.checked.length !== 0) ? this.state.product_modal.checked[i].checked : false}
              checked={
                typeof this.state.product_modal.checked[i] == "undefined"
                  ? false
                  : this.state.product_modal.checked[i].checked
              }
              onChange={this.checkboxProductChange.bind(this, v.pId, i)}
              key={v.pId}
            />
          </TextContainer>
        </Modal.Section>
      );
    });

    const deleteAddProductListSearch = this.state
      .del_add_product_list_search_value_active ? (
      <Icon source={CircleCancelMinor} />
    ) : null;
    const deleteProductListSearch = this.state
      .del_product_list_search_value_active ? (
      <Icon source={CircleCancelMinor} />
    ) : null;

    return (
      <div className="et-product-list">
        <Layout>
          <Layout.Section oneHalf>
            <Card>
              <div className="search-products">
                <div className="search-products-text">
                <TextField
                  value={this.state.add_product_list_search_value}
                  onChange={this.searchListProductChange}
                  autoComplete={false}
                  prefix={<Icon source={SearchMinor} />}
                  suffix={
                    <div
                      className="delete-search-add-product-list-text"
                      onClick={this.deleteSearchAddProductListText}
                    >
                      {deleteAddProductListSearch}
                    </div>
                  }
                  placeholder="Search products"
                />
                </div>
                <div>
                <Button plain onClick={this.addProductList}>Add products</Button>
                </div>
              </div>
              {pdData.length !== 0 ? (
                <div className="all-edit-products">
                  <div className="all-checked-edit-product">
                    <div className="all-checked-product">
                      <Checkbox
                        checked={this.state.is_all_checked_product_list}
                        onChange={(value) => this.checkedAllPordctList(value)}
                      />
                    </div>
                    {this.state.is_all_button_product == true ? (
                      <div className="all-button-product">
                        <ButtonGroup segmented>
                          <Button onClick={() => this.editAllDateChange()}>
                            Edit date
                          </Button>
                          <Button onClick={() => this.dellAllProduct()}>
                            Delete
                          </Button>
                        </ButtonGroup>
                        <TextStyle variation="positive">
                          {this.state.product_checked_list_count} products
                          selected
                        </TextStyle>
                      </div>
                    ) : (
                      <div className="all-product-sum-count">
                        Showing {this.state.product_sum_count} products
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state._isRequestFinal === false ? (
                <div>
                  <Card sectioned>
                    <SkeletonBodyText lines={15} />
                  </Card>
                  <Frame>
                    <Loading />
                  </Frame>
                </div>
              ) : pdData.length !== 0 ? (
                <div>
                  <div className="product-list-loding">
                    <DataTable
                      columnContentTypes={[
                        "text",
                        "numeric",
                        "numeric",
                        "numeric",
                        "numeric",
                      ]}
                      headings={[]}                      
                      rows={pdData}
                    />
                    {this.state.load_ing == true ? (
                      <Spinner
                        accessibilityLabel="Spinner example"
                        size="large"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {this.state
                    .del_add_product_list_search_value_active ? null : (
                    <div className="pagination">
                      <Pagination
                        onPrevious={this.initData.bind(
                          this,
                          this.state.add_pagination.prev_id
                        )}
                        onNext={this.initData.bind(
                          this,
                          this.state.add_pagination.next_id
                        )}
                        hasPrevious={this.state.add_pagination.prev}
                        hasNext={this.state.add_pagination.next}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <EmptyState heading="No products">
                  <p>
                    Please click the button on the top right to add a product
                    first.
                  </p>
                </EmptyState>
              )}
            </Card>
          </Layout.Section>

          <EditDeliverytimeModal
            clearModal={this.clearModal}
            updateComponent={this.updateComponent}
            modalData={this.state.modal}
          />
          <EditAllDeliverytimeModal
            clearAllModal={this.clearAllModal}
            updateAllComponent={this.updateAllComponent}
            modalData={this.state.all_modal}
          />
          <Modal
            open={this.state.is_del_checked_product}
            onClose={this.closeAllProduct}
            title={
              "Remove  " + this.state.product_checked_list_count + "  product"
            }
            primaryAction={{
              destructive: true,
              content: "Delete",
              onAction: this.dellAllProductButton,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: this.closeAllProduct,
              },
            ]}
          >
            <Modal.Section>This can't be undone.</Modal.Section>
          </Modal>

          <Modal
            open={this.state.product_modal.isActive}
            onClose={this.closeOrCancelProductModal}
            title="Add products"
            primaryAction={{
              content: "Done",
              onAction: this.addProduct,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: this.closeOrCancelProductModal,
              },
            ]}
            onScrolledToBottom={this.scrollBottomChange}
          >
            <div className="search-products">
              <TextField
                value={this.state.product_modal.value}
                onChange={this.searchProductChange}
                autoComplete={false}
                prefix={<Icon source={SearchMinor} />}
                suffix={
                  <div
                    className="delete-search-product-list-text"
                    onClick={this.deleteSearchProductListText}
                  >
                    {deleteProductListSearch}
                  </div>
                }
                placeholder="Search products"
              />
            </div>
            <div className="select-products">
              {this.state.product_modal.value.length === 0 ? (
                this.state.product_lists.length !== 0 ? (
                  <div>
                    {productListComponent}
                    {this.state.is_pagination_load === true ? (
                      <div className="load-product">
                        <Spinner
                          accessibilityLabel="Spinner example"
                          size="large"
                          color="teal"
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="load-product">
                    <Spinner
                      accessibilityLabel="Spinner example"
                      size="large"
                      color="teal"
                    />
                  </div>
                )
              ) : this.state.product_lists.length !== 0 ? (
                //style={{position:"absolute",left:"47%",top:"38%"}}
                <div>
                  {productListComponent}
                  {this.state.is_s_pagination_load === true ? (
                    <div className="load-product">
                      <Spinner
                        accessibilityLabel="Spinner example"
                        size="large"
                        color="teal"
                      />
                    </div>
                  ) : null}

                  {this.state.is_search_product_load === true ? (
                    <div
                      style={{ position: "absolute", left: "47%", top: "38%" }}
                    >
                      <Spinner
                        accessibilityLabel="Spinner example"
                        size="large"
                        color="teal"
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="load-product">
                  <Spinner
                    accessibilityLabel="Spinner example"
                    size="large"
                    color="teal"
                  />
                </div>
              )}
            </div>
          </Modal>
        </Layout>

        <Frame>{toastMarkup}</Frame>
      </div>
    );
  }
}

export default ProductList;
